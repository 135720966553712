import { Component } from 'react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import * as microsoftTeams from "@microsoft/teams-js";


class TeamsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        var authenticatedFunc = (email, displayName) => {
            this.teamsReady(email, displayName);
            window.teamsAuthenticated = true;
            window.teamsEmail = email;
            window.teamsDisplayName = displayName;
        };

        if (window.debug) {
            $("#main-content").show();
            window.inTeams = true;
            localStorage.setItem("bearerToken", 'DEBUGBEARERTOKEN');
            authenticatedFunc(this.debugUsername, this.debugDisplayName);
        }
        else {
            microsoftTeams.initialize(() => { });
            microsoftTeams.authentication.getAuthToken({
                successCallback: (clientSideToken) => {
                    microsoftTeams.getContext((context) => {
                        var data = new FormData();
                        data.append('tid', context.tid);
                        data.append('token', clientSideToken);

                        fetch('https://alpha-api.patentmaker.eu/api/auth/token',
                            {
                                method: 'post',
                                body: data,
                                mode: 'cors',
                                cache: 'default'
                            }).then((response) => {
                                if (response.ok) {
                                    response.text().then(token => {
                                        localStorage.setItem("bearerToken", token);
                                        $.ajax({
                                            url: 'https://graph.microsoft.com/v1.0/me/',
                                            headers: {
                                                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                                            },
                                            success: function (result) {
                                                window.inTeams = true;
                                                $("#main-content").show();
                                                var attorneyName = result.displayName;
                                                var attorneyEmail = result.userPrincipalName;
                                                $("#display-name").text("Welcome, " + attorneyName);
                                                authenticatedFunc(attorneyEmail, attorneyName);
                                            },
                                            error: function (error) {
                                                $("#unauth-content").show();
                                            }
                                        });
                                    });
                                } else {
                                    $("#unauth-content").show();
                                    console.log(response.error);
                                }
                            });
                    });
                },
                failureCallback: function (error) {
                    $("#unauth-content").show();
                    console.log(JSON.stringify(error));
                }
            });
        }
    }
}

export default TeamsComponent;