import React, { Component } from 'react';
import './Header.css';
import './PatentMaker.css';

export class PMFooter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <div className="footer footer-patentmaker">
                    <div>
                        &copy; 2022 PatentMaker Software GmbH
                    </div>
                </div>
            </header>
        );
    }
}
