import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { Navigate, useLocation } from 'react-router-dom';
import PatentSurveillanceComponent from '../PatentSurveillanceComponent';

class ReportListEntry extends Component {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
            label: props.report.label,
            status: props.report.status,
            id: props.report.id
        };
        this.fileInputRef = React.createRef();
    }

    componentDidMount = () => {
        // super.componentDidMount();
    }

    initEventHandlers() {
    }

    chooseFile() {
        this.fileInputRef.current.click();
    }

    handleInputFileChange(event) {
        var fileInput = event.currentTarget;

        var fr = new FileReader();
        fr.readAsDataURL(fileInput.files[0]);
        fr.onload = (event) => {
            //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")

            var base64Input = event.target.result;
            if (base64Input.indexOf('base64,') !== -1)
                base64Input = base64Input.substring(base64Input.indexOf('base64,') + 'base64,'.length);

            this.setState((prevState) => {
                return ({
                    ...prevState,
                    id: undefined,
                    status: 'U'
                });
            });

            fetch('/api/patentsurveillance/reports',
                {
                    method: 'post',
                    body: JSON.stringify({
                        monthDateText: this.props.monthDateText,
                        categoryId: this.props.report.categoryId,
                        inputSheetBase64: base64Input
                    }),
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                    }
                }).then(response => {
                    if (response.ok) {
                        response.text().then(resp => {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    id: resp,
                                    status: 'P'
                                });
                            });
                        });
                    }
            });

            this.setState((prevState) => {
                return ({
                    ...prevState,
                    base64Input: base64Input
                });
            });
            // OR
            //textArea.value = fr.result;
        }
    }

    openReport() {
        var reportUrl = '/patentsurveillance/report/' + this.state.id;
        this.props.navigate(reportUrl);
    }

    deleteReport() {
        fetch('/api/patentsurveillance/report/' + this.state.id,
            {
                method: 'delete',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        id: undefined,
                        status: 'M'
                    });
                });
            }
        });
    }

    render() {
        this.initEventHandlers();

        var mainLabel = undefined;
        var addRemove = undefined;

        if (this.state.status === 'R') {
            mainLabel = <div style={{ cursor: 'pointer' }} onClick={() => this.openReport()}>{this.state.label}</div>;
            addRemove = <div onClick={() => this.deleteReport()} style={{ width:'1em', textAlign: 'center', fontSize: '1.5em', cursor: 'pointer', color: 'red', fontWeight: 'bold' }}>-</div>;
        } else if (this.state.status === 'P') {
            mainLabel = <div style={{ cursor: 'pointer' }} onClick={() => this.openReport()}>{this.state.label}</div>;
            addRemove = <div onClick={() => this.deleteReport()} style={{ width: '1em', textAlign: 'center', fontSize: '1.5em', cursor: 'pointer', color: 'red', fontWeight: 'bold' }}>-</div>;
        } else if (this.state.status === 'U') {
            mainLabel = <div style={{ cursor: 'pointer' }} onClick={() => this.openReport()}>{this.state.label}</div>;
            addRemove = <div style={{ verticalAlign: 'middle', width: '1em', fontSize: '1.5em' }}><div className="lds-miniring">
                <div></div><div></div><div></div><div></div>
            </div></div>;
        } else if (this.state.status === 'M') {
            mainLabel = <div style={{ color: 'darkgray' }}>{this.state.label}</div>;
            addRemove = <div onClick={() => this.chooseFile()} style={{ width: '1em', textAlign: 'center', fontSize: '1.5em', cursor: 'pointer', color: 'green', fontWeight: 'bold' }}>+</div>;
        } else{
            mainLabel = <div style={{ cursor: 'pointer' }} onClick={() => this.openReport()}>{this.state.label}</div>;
            addRemove = <div onClick={() => this.deleteReport()} style={{ width: '1em', textAlign: 'center', fontSize: '1.5em', cursor: 'pointer', color: 'red', fontWeight: 'bold' }}>-</div>;
        }

        if (!this.props.isSuperAdmin)
            addRemove = undefined;

        return (
            <div style={{display: 'flex', width: '100%'}}>
                {mainLabel}
                <div style={{ flexGrow: 1 }} />
                {addRemove}
                <div style={{ display: 'none' }}>
                    <input ref={this.fileInputRef} type="file" accept=".xlsx" onChange={(e) => { this.handleInputFileChange(e); }} />
                </div>
            </div>
        );
    }
}

export default withRouter(ReportListEntry);
