import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { BBUPCHeader } from './HeadersFooters/BoehmertUPCHeader';
import { ADACHeader } from './HeadersFooters/ADACHeader';
import PatentSurveillanceHeader from './HeadersFooters/PatentSurveillanceHeader';
import { PMHeader } from './HeadersFooters/PatentMakerHeader';
import { PMAdminHeader } from './HeadersFooters/PatentMakerAdminHeader';
import { PatDocTemplatesHeader } from './HeadersFooters/PatDocTemplatesHeader';
import { PMFooter } from './HeadersFooters/PatentMakerFooter';
import './Layout.css';

export class Layout extends Component {
  static displayName = Layout.name;

    getHeaderForUrl(url) {
        if (url.toLowerCase().includes('teams=y')) {
            return null;
        }

        if (url.toLowerCase().includes('upcoptout')) {
            return <BBUPCHeader />;
        }

        if (url.toLowerCase().includes('emailconfirm')) {
            return <PMHeader />;
        }

        if (url.toLowerCase().includes('/admin')) {
            return null; // <PMAdminHeader />;
        }

        if (url.toLowerCase().includes('/templates')) {
            return <PatDocTemplatesHeader />;
        }

        if (url.toLowerCase().includes('/fto/search')) {
            return null;
        }

        if (url.toLowerCase().includes('/trainingdata')) {
            return null;
        }

        if (url.toLowerCase().includes('/noveltyreport')) {
            return null;
        }

        if (url.toLowerCase().includes('/officeactionreport')) {
            return null;
        }

        if (url.toLowerCase().includes('/documentlibrary')) {
            return null;
        }

        if (url.toLowerCase().includes('adacdemo')) {
            return <ADACHeader />;
        }

        if (url.toLowerCase().includes('patentsurveillance')) {
            return <PatentSurveillanceHeader />;
        }

        return <PMHeader />;
    }

    getFooterForUrl(url) {
        if (url.toLowerCase().includes('emailconfirm')) {
            return <PMFooter />;
        }

        return null;
    }

    getTitleForUrl(url) {
        if (url.toLowerCase().includes('upcoptout')) {
            return 'Boehmert UPC Tool';
        }

        if (url.toLowerCase().includes('emailconfirm')) {
            return 'PatentMaker Registration';
        }

        if (url.toLowerCase().includes('admin')) {
            return 'PatentMaker Admin Tool';
        }

        if (url.toLowerCase().includes('adacdemo')) {
            return 'ADAC Licensing Demo';
        }

        return 'PatentMaker Software';
    }

    getOuterContainerClassForUrl(url) {
        if (url.toLowerCase().includes('adacdemo')) {
            return 'adac-outer-container';
        }

        if (url.toLowerCase().includes('patentsurveillance')) {
            return 'patentsurveillance-outer-container';
        }

        return 'outer-container';
    }

    render() {
        var header = this.getHeaderForUrl(window.location.href);
        var footer = this.getFooterForUrl(window.location.href);
        var title = this.getTitleForUrl(window.location.href);
        var outerContainerClass = this.getOuterContainerClassForUrl(window.location.href);

        return (
            <div className={outerContainerClass}>
                <title>{title}</title>
                {header}
                {this.props.children}
                {footer}
            </div>
        );
    }
}
