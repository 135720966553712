import React, { Component } from 'react';
import { Link } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class ExistingMandateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mandateTimestamps: [],
            gridLoaded: false,
            mandatesLoaded: false
        };
    }

    componentDidMount = () => {
        fetch('/api/upc/requestedmandates?clientId=' + this.props.clientId)
            .then(response => {
                return response.json();
            }).then(data => {
                var timestamps = [];
                data.forEach(mandateTimestamp => {
                    timestamps.push(mandateTimestamp);
                });

                this.setState((prevState) => {
                    console.log(prevState);
                    return ({
                        ...prevState,
                        mandateTimestamps: timestamps
                    });
                });

                this.mandatesLoaded();
            });
    }

    showSelection(timestamp) {
        fetch('/api/upc/mandateapplications?clientId=' + this.props.clientId + '&mandateId=' + timestamp.replace('T', ' '))
            .then(response => {
                return response.json();
            }).then(applicationNumbers => {
                this.props.setCheckboxesFromMandate(applicationNumbers);
            });
    }

    mainGridLoaded() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                gridLoaded: true
            });
        });

        if (this.state.mandatesLoaded) {
            this.setDefaultSelection();
        }
    }

    mandatesLoaded() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                mandatesLoaded: true
            });
        });

        if (this.state.gridLoaded) {
            this.setDefaultSelection();
        }
    }

    setDefaultSelection() {
        if (this.state.mandateTimestamps.length !== 0) {
            var lastTimestamp = this.state.mandateTimestamps[this.state.mandateTimestamps.length - 1].timestamp;
            console.log('Setting default selections to mandate from ' + lastTimestamp);
            this.showSelection(lastTimestamp);
        }
    }

    render() {
        var mandateList = [];

        this.state.mandateTimestamps.forEach(m => {
            var timestamp = m.timestamp;
            var formattedTimestamp = timestamp.substring(8, 10) + '.' +
                timestamp.substring(5, 7) +
                '.' +
                timestamp.substring(0, 4);
            var generatedBy = m.generatedBy;
            var openUrl = '/api/upc/openrequestedmandate?clientId=' + this.props.clientId + '&mandateId=' + m.timestamp.replace('T', ' ');
            mandateList.push(<div>Mandate generated on {formattedTimestamp} by {generatedBy}.<Link href={openUrl} style={{ marginLeft: '1em' }}>Open</Link><Link onClick={() => this.showSelection(m.timestamp)} style={{ marginLeft: '1em' }}>Show selection</Link></div>);
        });

        if (mandateList.length === 0)
            return <div />;

        return (
            <div style={{ marginBottom: '1em', fontSize: '14px' }}>
                {mandateList}
            </div>
        );
    }
}

export default ExistingMandateList;
