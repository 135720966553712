import React, { Component } from 'react';
import './Header.css';
import './PatentMaker.css';
import logo from './Images/PatentMaker.png';

export class PMHeader extends Component {
    static displayName = PMHeader.name;

    constructor(props) {
        super(props);
    }

    render() {
        var label = 'PatentMaker Software';
        if (this.props.header)
            label = this.props.header;

        return (
            <header>
                <div className="header header-patentmaker">
                    <div>{label}</div>
                    <img className="logo" src={logo} />
                </div>
            </header>
        );
    }
}
