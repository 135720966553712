import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import ClientSelector from "./components/UPC/ClientSelector";
import PartnerSelector from "./components/UPC/PartnerSelector";
import ClientSearch from './components/UPC/ClientSearch';
import ClientView from "./components/UPC/ClientView";
import EmailConfirm from "./components/PatentMaker/EmailConfirm";
import UsageView from './components/GmbHAdmin/GeneralUsage/UsageView';
import LicensingView from './components/GmbHAdmin/Licensing/LicensingView';
import FeaturesView from './components/GmbHAdmin/FeatureAnalysis/FeaturesView';
import TimelineView from './components/GmbHAdmin/Timeline/TimelineView';
import AIActivityView from './components/GmbHAdmin/AIActivity/AIActivityView'
import AIPerformanceView from './components/GmbHAdmin/AIActivity/AIPerformanceView'
import OAReportsView from './components/GmbHAdmin/OAReports/OAReportsView'
import ApplicationDraftsView from './components/GmbHAdmin/ApplicationDrafts/ApplicationDraftsView'
import BuildsView from './components/GmbHAdmin/Builds/BuildsView';
import TemplateList from './components/Templates/TemplateList';
import FTOSearchView from './components/FTOSearch/FTOSearchView';
import NewFTOSearchView from './components/FTOSearch/NewFTOSearchView';
import ClaimsSheetProcessorView from './components/ClaimsSheetProcessor/ClaimsSheetProcessorView';
import ClaimsLookupView from './components/FTOSearch/ClaimsLookupView';
import TrainingDataView from './components/TrainingData/TrainingDataView';
import NoveltyReportView from './components/NoveltyReport/NoveltyReportView';
import OfficeActionReport from './components/OfficeActionReport/OfficeActionReport';
import DocumentLibraryView from './components/DocumentLibrary/DocumentLibraryView';
import ADACDemoView from './components/ADACDemo/ADACDemoView';
import PatSurLoginView from './components/PatentSurveillance/Login/LoginView';
import PatSurReportList from './components/PatentSurveillance/ReportList/ReportListView';
import PatSurReportView from './components/PatentSurveillance/ReportView/ReportView';
import PatSurSettingsView from './components/PatentSurveillance/Settings/SettingsView';

const AppRoutes = [
    {
        path: '/Home/EmailConfirm',
        element: <EmailConfirm />
    },
    {
        path: '/UPCOptOut',
        element: <ClientSelector />
    },
    {
        path: '/UPCOptOut/Partners',
        element: <PartnerSelector />
    },
    {
        path: '/UPCOptOut/AddClient',
        element: <ClientSearch />
    },
    {
        path: '/UPCOptOut/Client/:id',
        element: <ClientView />
    },
    {
        path: '/UPCOptOut/Partners/:id',
        element: <ClientView isPartner={true} />
    },
    {
        path: '/Admin/Usage',
        element: <UsageView />
    },
    {
        path: '/Admin/Licensing',
        element: <LicensingView />
    },
    {
        path: '/Admin/Features',
        element: <FeaturesView />
    },
    {
        path: '/Admin/Timeline',
        element: <TimelineView />
    },
    {
        path: '/Admin/AI',
        element: <AIActivityView />
    },
    {
        path: '/Admin/AIPerf',
        element: <AIPerformanceView />
    },
    {
        path: '/Admin/OAReports',
        element: <OAReportsView />
    },
    {
        path: '/Admin/ApplicationDrafts',
        element: <ApplicationDraftsView />
    },
    {
        path: '/Admin/Builds',
        element: <BuildsView />
    },
    {
        path: '/Templates',
        element: <TemplateList />
    },
    {
        path: '/FTO/Search/:apikey',
        element: <FTOSearchView />
    },
    {
        path: '/FTO/NewSearch/:apikey',
        element: <NewFTOSearchView />
    },
    {
        path: '/ClaimsLookup/:apikey',
        element: <ClaimsLookupView />
    },
    {
        path: '/DocumentLibrary/:apikey',
        element: <DocumentLibraryView />
    },
    {
        path: '/TrainingData/:apikey',
        element: <TrainingDataView />
    },
    {
        path: '/NoveltyReport/:reportkey',
        element: <NoveltyReportView />
    },
    {
        path: '/OfficeActionReport/:reportkey',
        element: <OfficeActionReport />
    },
    {
        path: '/ClaimsSheet/:apikey',
        element: <ClaimsSheetProcessorView />
    },
    {
        path: '/ADACDemo',
        element: <ADACDemoView />
    },
    {
        path: '/PatentSurveillance',
        element: <PatSurLoginView />
    },
    {
        path: '/PatentSurveillance/Reports',
        element: <PatSurReportList />
    },
    {
        path: '/PatentSurveillance/Report/:reportid',
        element: <PatSurReportView />
    },
    {
        path: '/PatentSurveillance/Settings',
        element: <PatSurSettingsView />
    },
...ApiAuthorzationRoutes
];

export default AppRoutes;
