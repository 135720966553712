import React, { Component } from 'react';
import {Checkbox, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';

class ClientUserConfig extends Component {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
            clientId: props.clientId
        };
    }

    componentDidMount = () => {
        const token = localStorage.getItem('patentsurveillance-jwt');
        fetch('/api/patentsurveillance/users',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            users: resp
                        });
                    });
                });
            }
            });

        fetch('/api/patentsurveillance/categories',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            categories: resp
                        });
                    });
                });
            }
        });
    }

    initEventHandlers() {
    }

    changeAccess(userId, categoryId, evt) {
        const token = localStorage.getItem('patentsurveillance-jwt');
        if (evt.target.checked) {
            fetch('/api/patentsurveillance/users/' + userId + '/categories/' + categoryId + '/grantaccess',
                {
                    method: 'post',
                    headers:
                    {
                        'jwt-token': token
                    }
                }).then(response => {
                if (response.ok) {
                    alert('Access granted');
                    console.log('Granted access');
                }
            });
        } else {
            fetch('/api/patentsurveillance/users/' + userId + '/categories/' + categoryId + '/revokeaccess',
                {
                    method: 'post',
                    headers:
                    {
                        'jwt-token': token
                    }
                }).then(response => {
                if (response.ok) {
                    alert('Access revoked');
                    console.log('Revoked access');
                }
            });
        }
    }

    createUser() {
        var name = $('#newusername').val();
        var email = $('#newuseremail').val();
        var categories = [];
        this.state.categories.forEach(c => {
            if ($('#newuser-category-' + c.id).is(':checked'))
                categories.push(c.id);
        });

        fetch('/api/patentsurveillance/users/create',
            {
                method: 'post',
                body: JSON.stringify({ name: name, email: email, categories: categories }),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
                if (response.ok) {
                    $('#newusername').val('');
                    $('#newuseremail').val('');
                    response.json().then(resp => {
                        this.state.users.push(resp);
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                users: this.state.users
                            });
                        });
                    });
                alert('User created');
            } else {
                alert('Something went wrong');
            }
        });
    }

    deleteUser(userId, userName) {
        if (window.confirm('Are you sure you want to delete ' + userName + '?')) {
            fetch('/api/patentsurveillance/users/' + userId + '/delete',
                {
                    method: 'post',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                    }
                }).then(response => {
                if (response.ok) {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            users: this.state.users.filter(u => u.id !== userId)
                        });
                    });
                    alert('User deleted');
                } else {
                    alert('Something went wrong');
                }
            });
        }
    }

    render() {
        this.initEventHandlers();
        var userGrid = undefined;
        if (this.state.users && this.state.categories) {
            var templateCols = '1fr 1fr';
            var gridCells = [];
            gridCells.push(<div style={{ fontWeight: 'bold' }}>Name</div>);
            gridCells.push(<div style={{ fontWeight: 'bold' }}>Email</div>);
            this.state.categories.forEach(c => {
                templateCols += ' 1fr';
                gridCells.push(<div style={{ fontWeight: 'bold' }}>{c.name}</div>);
            });

            gridCells.push(<div/>);
            templateCols += ' 1fr';

            this.state.users.forEach(u => {
                if (!u.isClientAdmin && !u.isSuperAdmin) {
                    gridCells.push(<div style={{ margin: 'auto 0' }}>{u.name}</div>);
                    gridCells.push(<div style={{ margin: 'auto 0' }}>{u.email}</div>);
                    this.state.categories.forEach(c => {
                        var hasAccess = u.userCategories.some(uc => uc === c.id);
                        gridCells.push(
                            <div style={{ margin: 'auto 0'}}><Checkbox key={'user' + u.id + '-category-' + c.id} defaultChecked={hasAccess} onChange={(e) => this.changeAccess(u.id, c.id, e)} /></div>);
                    });
                    gridCells.push(<DefaultButton style={{ margin: '0.25em 0' }} onClick={() => this.deleteUser(u.id, u.name)}>Delete user</DefaultButton>);
                }
            });

            gridCells.push(<div style={{ margin: 'auto 0' }}><input id='newusername'/></div>);
            gridCells.push(<div style={{ margin: 'auto 0' }}><input id='newuseremail'/></div>);
            this.state.categories.forEach(c => {
                gridCells.push(<div style={{ margin: 'auto 0' }}><Checkbox key={'newuser-category-' + c.id} id={'newuser-category-' + c.id}/></div>);
            });
            gridCells.push(<DefaultButton style={{margin: '0.25em 0'}} onClick={() => this.createUser()}>Create new user</DefaultButton>);

            userGrid = <div style={{ display: 'grid', gridTemplateColumns: templateCols }}>
                {gridCells}
            </div>;
        }

        return (
            <div>
                <hr />
                <h2>User Rights Management</h2>
                {userGrid}
            </div>
        );
    }
}

export default withRouter(ClientUserConfig);
