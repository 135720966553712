import React, { Component } from 'react';
import { Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class CheckboxField extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            value: props.initialValue
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    onChange(evt) {
        fetch(this.props.saveUrl,
            {
                method: 'post',
                body: JSON.stringify(evt.target.checked ? 'Y' : 'N'),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                console.log('Saved boolean: ' + evt.target.checked + ' to ' + this.props.saveUrl);
            } else {
                console.log('Something went wrong');
            }
        });
    }

    saveValue(newValue) {
        fetch(this.props.saveUrl,
            {
                method: 'post',
                body: JSON.stringify(newValue),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                console.log('Saved text: ' + newValue + ' to ' + this.props.saveUrl);
            } else {
                console.log('Something went wrong');
            }
        });
    }

    render() {
        this.initEventHandlers();

        return (
            <Checkbox style={this.props.style} className={this.props.className} defaultChecked={this.state.value} onChange={(e) => this.onChange(e)} />
        );
    }
}

export default CheckboxField;