import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';
import JudgementControl from './JudgementControl';

class ClaimFeatureEntry extends Component {
    static displayName = "";

    constructor(props) {
        super(props);

        console.log('Contructing row with default values ' +
            this.props.defaultFeatureText + 
            ' & ' + 
            this.props.defaultGroundTruth +
            ' & ' +
            this.props.defaultReasoning);
        this.state = {
            userDisclosed: this.props.feature.userDisclosed,
            userReasoning: this.props.feature.userReasoning
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadFeatures(caseId) {
    }

    loadPriorArt(priorArtId) {
    }

    onCorrectionTextInput(evt) {
        if (this.correctionTextTimer) {
            clearTimeout(this.correctionTextTimer);
        }

        var newCorrectionText = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                userReasoning: newCorrectionText
            });
        });
        this.correctionTextTimer = setTimeout(() => { this.saveCorrectionText(newCorrectionText) }, 2500);
    }

    saveCorrectionText(newCorrectionText) {
        fetch('/api/noveltyreport/claimfeature/' +
            this.props.feature.id +
            '/correctiontext',
            {
                method: 'post',
                body: JSON.stringify(newCorrectionText),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    setUserReasoning(text) {
        if (text !== undefined && text !== null) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    userReasoning: text
                });
            });
        } else {
            alert(text);
        }
    }
    
    render() {
        this.initEventHandlers();
        var disablePriorArtControls = true;
        if (this.props.priorArtId) {
            disablePriorArtControls = false;
        }

        var disclosedSpan = this.props.feature.aiDisclosed
            ? (
                <div style={{ width: '1em', fontWeight: 'bold', fontSize: 22 }}>&#10003;</div>
            )
            : (<div style={{ width: '1em', fontWeight: 'bold', fontSize: 22 }}>&#10007;</div>
            );
        return (
            <tr>
                <td style={{verticalAlign: 'top', padding: '0 1em 0 0'}}>
                    {this.props.feature.featureText}
                </td>
                <td style={{ verticalAlign: 'top' }}>
                    {disclosedSpan}
                </td>
                <td style={{ verticalAlign: 'top', padding: '0 1em 0 1em'}}>
                    {this.props.feature.aiReasoning}
                </td>
                <td style={{verticalAlign: 'top',  padding: '0 1em 0 1em', display: this.props.feedbackDisplay }}>
                    <JudgementControl userDisclosed={this.state.userDisclosed} claimFeatureId={this.props.feature.id} setUserReasoning={
(t) => { this.setUserReasoning(t); }}/>
                </td>
                <td style={{ minWidth: '25em', verticalAlign: 'top', display: this.props.feedbackDisplay }}>
                    <textarea style={{width:'100%'}} value={this.state.userReasoning} onInput={(e) => this.onCorrectionTextInput(e)}></textarea>
                </td>
            </tr>
        );
    }
}

export default ClaimFeatureEntry;
