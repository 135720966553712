import React, { Component } from 'react';
import { Dropdown, DefaultButton, Dialog, TextField, ChoiceGroup } from '@fluentui/react';
import './CreateNewTemplateDialog.css';
import $ from 'jquery';

class CreateNewTemplateDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            processing: false,
            rowData: {}
        };

        window.showNewTemplateDialog = (rowData, responseType, germanVersion, sourceUrl) => {
            this.setState({
                showDialog: true,
                rowData: rowData,
                responseType: responseType,
                description: rowData?.extraInfo,
                germanVersion: germanVersion,
                sourceUrl: sourceUrl,
                personalDisplayName: window.teamsDisplayName,
                selectedGroupKey: 'personal',
                processing: false
            });
        };
    }

    close = () => {
        this.setState({
            showDialog: false,
            data: undefined
        });
    }

    setProcessingState = (processing, createdUrl, error) => {
        this.setState({
            showDialog: this.state.showDialog,
            rowData: this.state.rowData,
            responseType: this.state.responseType,
            germanVersion: this.state.germanVersion,
            sourceUrl: this.state.sourceUrl,
            personalDisplayName: this.state.personalDisplayName,
            selectedGroupKey: this.state.selectedGroupKey,
            processing: processing,
            createdUrl: createdUrl,
            processingError: error
        });
    }

    createTemplate = () => {
        this.setProcessingState(true);
        var sourcePath = [];
        if (this.state.rowData.group)
            sourcePath.push(this.state.rowData.group);
        if (this.state.rowData.office === 'DPMA')
            sourcePath.push('DPMA');
        if (this.state.germanVersion)
            sourcePath.push('German');
        var sourcePathString = sourcePath.join('/');
        var sourceFilename = this.state.rowData.originalFilename;

        var targetPath = [];
        if (this.state.selectedGroupKey === 'personal') {
            targetPath.push(window.teamsEmail);
        } else if (this.state.selectedGroupKey.startsWith('group-')) {
            targetPath.push(this.state.selectedGroupKey.split('-')[1]);
        }

        if (this.state.rowData.office === 'DPMA')
            targetPath.push('DPMA');
        if (this.state.germanVersion)
            targetPath.push('German');
        var targetPathString = targetPath.join('/');


        var targetResponseType = this.state.responseType;
        var targetDescription = this.state.description;
        var targetTrigger = this.state.rowData.trigger;
        var targetClient = this.state.rowData.client;

        var targetFilename = (targetResponseType  === targetTrigger)
            ? targetTrigger
            : targetResponseType + ' ' + targetTrigger;

        if (targetClient && targetClient !== '') {
            targetFilename += ' ' + targetClient;
        }

        if (targetDescription && targetDescription !== '') {
            targetFilename += ' ' + targetDescription;
        }

        targetFilename += '.docx';

        var data = {
            sourcePath: sourcePathString,
            sourceFilename: sourceFilename,
            targetPath: targetPathString,
            targetFilename: targetFilename
        };

        fetch('/api/templates', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.ok) {
                response.text().then(url => {
                    this.setProcessingState(true, url);
                });
            } else {
                if(response.status === 409)
                    this.setProcessingState(true, undefined, 'Template already exists');
                else
                    this.setProcessingState(true, undefined, 'Template creation failed');
            }
        });
    }

    cancel = () => {
        this.setState({
            showDialog: false,
            rowData: {}
        });
    }

    goBack = () => {
        this.setProcessingState(false);
    }

    groupChanged = (evt, opt) => {
        this.setState({
            showDialog: this.state.showDialog,
            rowData: this.state.rowData,
            responseType: this.state.responseType,
            germanVersion: this.state.germanVersion,
            sourceUrl: this.state.sourceUrl,
            personalDisplayName: this.state.personalDisplayName,
            selectedGroupKey: opt.key,
            processing: this.state.processing,
            createdUrl: this.state.createdUrl,
            processingError: this.state.processingError
        });
    }

    descriptionChanged = (evt, newValue) => {
        this.setState({
            showDialog: this.state.showDialog,
            rowData: this.state.rowData,
            responseType: this.state.responseType,
            description: newValue,
            germanVersion: this.state.germanVersion,
            sourceUrl: this.state.sourceUrl,
            personalDisplayName: this.state.personalDisplayName,
            selectedGroupKey: this.state.selectedGroupKey,
            processing: this.state.processing,
            createdUrl: this.state.createdUrl,
            processingError: this.state.processingError
        });
    }

    responseTypeChanged = (evt, newValue) => {
        this.setState({
            showDialog: this.state.showDialog,
            rowData: this.state.rowData,
            responseType: newValue,
            description: this.state.description,
            germanVersion: this.state.germanVersion,
            sourceUrl: this.state.sourceUrl,
            personalDisplayName: this.state.personalDisplayName,
            selectedGroupKey: this.state.selectedGroupKey,
            processing: this.state.processing,
            createdUrl: this.state.createdUrl,
            processingError: this.state.processingError
        });
    }

    render() {
        var dialogContent = <div />;
        if (this.state.processing) {
            if (this.state.createdUrl) {
                dialogContent =
                    <div className='process-dialog'>
                        <div>Template created successfully.</div>
                        <div><a href={this.state.createdUrl} target='_blank'>Open new template</a></div>
                        <div className='process-buttons'>
                            <DefaultButton onClick={e => this.cancel()}>OK</DefaultButton>
                        </div>
                    </div >;
            } else if (this.state.processingError) {
                dialogContent =
                    <div className='process-dialog'>
                        <div>{this.state.processingError}</div>
                        <div className='process-buttons'>
                            <DefaultButton onClick={e => this.goBack()}>Back</DefaultButton>
                            <DefaultButton onClick={e => this.cancel()}>Cancel</DefaultButton>
                        </div>
                    </div >;
            } else {
                dialogContent =
                    <div className='process-dialog'>
                        <div>Creating template - please wait...</div>
                        <div className='process-buttons'>
                            <DefaultButton onClick={e => this.cancel()}>Cancel</DefaultButton>
                        </div>
                    </div >;
            }
        }
        else {
            this.dialogStyles = {
                main: {
                    selectors: {
                        '@media (min-width: 0px)': {
                            minHeight: '200px',
                            maxHeight: '80vh',
                            maxWidth: '80vw',
                            minWidth: '600px'
                        }
                    }
                }
            };

            this.modalProps = {
                styles: this.dialogStyles,
                onDismiss: () => {
                    this.close();
                }
            };

            var title = 'Create new ' + (this.state.rowData?.trigger ?? '') + ' template';

            var existingTemplateDescription = undefined;
            var triggerSelection = undefined;

            if (this.state.rowData) {
                var basedName = this.state.responseType;
                if (this.state.germanVersion)
                    basedName = 'German ' + basedName;

                if (this.state.rowData?.extraInfo)
                    basedName = basedName + ' (' + this.state.rowData.extraInfo + ')';
                var basedLink = <a href={this.state.sourceUrl} target='_blank'>{basedName}</a>;
                existingTemplateDescription = (<div>
                    The new template will be based on the existing {basedLink} template.
                </div>);
            } else {
                var triggerOptions = [];
                triggerOptions.push({ key: 'One', text: 'One' });
                triggerOptions.push({ key: 'Two', text: 'Two' });
                triggerOptions.push({ key: 'Three', text: 'Three' });
                triggerSelection = (<div>
                    <div>What should trigger the new template?</div>
                    <Dropdown options={triggerOptions}></Dropdown>
                </div>);
            }
            var groupOptions = [];
            groupOptions.push({ key: 'personal', text: this.state.personalDisplayName });
            var activeGroups = window.getActiveGroups();
            activeGroups.forEach(group => {
                groupOptions.push({ key: 'group-' + group, text: 'Members of the ' + group + ' group' });
            });
            groupOptions.push({ key: 'shared', text: 'Everybody (create new shared template)' });
            var responseTypeRow = undefined;
            var showResponseTypeOption = false;

            if (this.state.rowData?.trigger !== this.state.responseType)
                showResponseTypeOption = true;
            else if (!this.state.rowData) {
                // TODO: Base this on the trigger dropdown option selected, or maybe disable instead of hiding
                showResponseTypeOption = true;
            }

            if (showResponseTypeOption) {
                responseTypeRow =
                    <tr>
                        <td>
                            Response type:
                        </td>
                        <td style={{ paddingLeft: '5px' }}>
                            <TextField id='response-type-input' value={this.state.responseType} onChange={(x, y) => this.responseTypeChanged(x, y)}></TextField>
                        </td>
                    </tr>;
            }

            var clientRow = undefined;
            if (this.state.rowData?.client && this.state.rowData.client !== '')
                clientRow =
                    <tr>
                        <td>
                            Client:
                        </td>
                        <td style={{ paddingLeft: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                            <span>{this.state.rowData.client}</span>
                        </td>
                    </tr>;

            dialogContent =
                <div>
                    {existingTemplateDescription}
                    {triggerSelection}
                    <div style={{ marginTop: '1em' }}>
                        <div>Who should the new template be offered to?</div>
                        <ChoiceGroup id='selected-group' style={{ marginLeft: '1em' }} options={groupOptions} selectedKey={this.state.selectedGroupKey} onChange={(evt, opt) => this.groupChanged(evt, opt)} />
                    </div>
                    <table style={{ marginTop: '1em' }}>
                        <tbody>
                            {responseTypeRow}
                            {clientRow}
                            <tr>
                                <td>
                                    Template description:
                                </td>
                                <td style={{ paddingLeft: '5px' }}>
                                    <TextField id='description-input' value={this.state.description} onChange={(x, y) => this.descriptionChanged(x, y)}></TextField>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ marginTop: '1em', display: 'flex', gap: '1em', justifyContent: 'center' }}>
                        <DefaultButton onClick={e => this.createTemplate()}>Create</DefaultButton>
                        <DefaultButton onClick={e => this.cancel()}>Cancel</DefaultButton>
                    </div>
                </div>;
        }
        return (
            <Dialog className='new-template-dialog' hidden={!this.state.showDialog} modalProps={this.modalProps} title={title}>
                {dialogContent}
            </Dialog>
        );
    }
}

export default CreateNewTemplateDialog;
