import $ from 'jquery';
import React, { Component } from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class GPTSpendingCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    openHistory() {
        fetch('/api/admin/gptusage/' + this.props.data.userName + '/' + this.props.getWindowName(),
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => response.json()).then((data) => {
            window.showGPTDialog(data);
        });
    }

    render() {
        if (this.props.data.gptSpending === undefined || this.props.data.gptSpending === null)
            return <div />;

        var spendingSpan = undefined;
        var gptSpending = this.props.data.gptSpending;
        spendingSpan = <span style={{color: 'blue', cursor: 'pointer'}}>
            €{(Math.round(gptSpending * 100) / 100).toFixed(2)}
        </span>;

        return (
            <div onClick={() => this.openHistory()}>
                {spendingSpan}
            </div>
        );
    }
}

export default GPTSpendingCellRenderer;