import React, { Component } from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import './FeaturesView.css';
import FilterControls from './FilterControls';
import TeamsComponent from '../../TeamsComponent';
import { withRouter } from '../../WithRouter';


class FeaturesView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = {
            featureMaxUsage: {},
            userCounts: {}
        };
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }

    teamsReady(myEmail) {
        window.myEmail = myEmail;
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    filterSelected(group, timeWindow) {
        if (group === undefined || timeWindow === undefined)
            return;

        fetch('/api/admin/features/' + group + '/' + timeWindow,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState({
                        featureMaxUsage: resp.featureMaxUsage,
                        userCounts: resp.userCounts
                    });
                });
            }
        });
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getColourForUsage(usage, maxUsage) {
        if (!usage || usage === 0) {
            return 'rgb(192,192,192)';
        }
        var lightness = 160 - (160 * usage/maxUsage);
        return 'rgb(' + lightness + ',255,' + lightness + ')';
    }
    
    render() {
        if (window.inTeams) {
        }

        var headerCols = [];
        headerCols.push(
            <th/>
        );
        for (const featureName in this.state.featureMaxUsage) {
            if (this.state.featureMaxUsage.hasOwnProperty(featureName)) {
                //if (this.state.featureMaxUsage[featureName] === 0)
                    //continue;
                    if (featureName.startsWith("GAP-")) {
                        headerCols.push(
                            <th style={{ padding: 0, width: '35px' }}/>
                        );
                    } else {
                        headerCols.push(
                            <th style={{ padding: 0, width: '35px' }}>
                                <div class="rotated-th">
                                    <span class="rotated-th__label">
                                        {featureName}
                                    </span>
                                </div>
                            </th>
                        );
                    }
            }
        }

        var userRows = [];

        for (const username in this.state.userCounts) {
            if (this.state.userCounts.hasOwnProperty(username)) {
                var userCols = [];
                for (const featureName in this.state.featureMaxUsage) {
                    if (this.state.featureMaxUsage.hasOwnProperty(featureName)) {
                        if (featureName.startsWith("GAP-")) {
                            userCols.push(
                                <td style={{ width: '35px', height: '35px' }} />
                            );
                        } else {
                            //if (this.state.featureMaxUsage[featureName] === 0)
                            //continue;
                            var thisCount = this.state.userCounts[username][featureName];
                            var usageColour = this.getColourForUsage(thisCount,
                                this.state.featureMaxUsage[featureName]);
                            var tooltip = 'Unused';
                            if (thisCount === 1)
                                tooltip = 'Used 1 time';
                            if (thisCount > 1)
                                tooltip = 'Used ' + thisCount + ' times';
                            userCols.push(
                                <td style={{ width: '35px', height: '35px' }}>
                                    <td style={{
                                        width: '25px',
                                        height: '25px',
                                        background: usageColour,
                                        border: 'black solid 1px'
                                    }} title={tooltip} />
                                </td>
                            );
                        }
                    }
                }

                userRows.push(
                    <tr>
                        <td style={{ paddingRight:'20px' }}>{username}</td>
                        {userCols}
                    </tr>
                );
            }
        }
        return (
            <div className='main-content'>
                <div style={{ display: 'flex', margin:'10px' }}>
                    <FilterControls ref={this.filterRef} onChange={(g, w) => this.filterSelected(g, w)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <table style={{margin:'10px'}}>
                    <thead style={{ position: 'sticky', top: 0, background:'#e5f4ff'}}>
                        <tr>
                            {headerCols}
                        </tr>
                    </thead>
                    <tbody>
                        {userRows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(FeaturesView);
