import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import CaseHeader from './CaseHeader';
import PriorArtPanel from './PriorArtPanel';
import FeatureResponsePanel from './FeatureResponsePanel';
import {PMHeader} from '../HeadersFooters/PatentMakerHeader';

class TrainingDataView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            selectedCase: undefined
        };

        this.caseHeaderRef = React.createRef();
        this.priorArtPanelRef = React.createRef();
        this.featureResponsePanelRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    openCase(caseId) {
        fetch('/api/trainingdata/cases/' + caseId,
            {
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                selectedCase: resp
                            });
                        });

                        this.caseHeaderRef.current.loadCase(resp);
                        this.priorArtPanelRef.current.loadCase(resp.id);
                        this.featureResponsePanelRef.current.loadFeatures(resp.id);
                    });
                }
        });
    }

    loadPriorArt(priorArtId) {
        // Also reload the features, since they may have been modified in the previous prior art
        this.featureResponsePanelRef.current.loadFeatures(this.state.selectedCase.id);
        this.featureResponsePanelRef.current.loadPriorArt(priorArtId);
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 3fr', gridTemplateRows: 'auto auto 1fr' }}>
                <div style={{ gridColumn: 1, gridColumnEnd: 3, gridRow: 1 }} >
                    <PMHeader header='AI Training Data' />
                </div>
                <div style={{ gridColumn: 1, gridColumnEnd: 3, gridRow: 2 }} >
                    <CaseHeader ref={this.caseHeaderRef} openCase={(caseId) => this.openCase(caseId)} />
                </div>
                <div style={{ gridColumn: 1, gridRow: 3 }}>
                    <PriorArtPanel ref={this.priorArtPanelRef} loadPriorArt={(priorArtId) => this.loadPriorArt(priorArtId)} />
                </div>
                <div style={{ gridColumn: 2, gridRow: 3, overflowY: 'scroll' }}  >
                    <FeatureResponsePanel ref={this.featureResponsePanelRef} apikey={this.props.params.apikey} />
                </div>
            </div>
        );
    }
}

export default withRouter(TrainingDataView);
