import React, { Component } from 'react';
import { Dropdown, DropdownMenuItemType } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import * as microsoftTeams from "@microsoft/teams-js";

import './FilterControls.css';


class FilterControls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            windowOptions: [],
            modelOptions: [],
            defaultClientOption: '',
            defaultWindowOption: '',
            defaultModelOption: ''
        };
        console.log(this.state);
        this.clientFilterRef = React.createRef();
        this.windowFilterRef = React.createRef();
        this.modelFilterRef = React.createRef();
    }

    componentDidMount() {
    }


    loadOptions() {
        fetch('/api/admin/aideployments',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    var optionList = [{ key: 'All', text: 'All' }];
                    resp.forEach(d => {
                        optionList.push({
                            key: d,
                            text: d
                        });
                    });

                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            modelOptions: optionList,
                            defaultModelOption: 'All'
                        });
                    });

                    this.selectedModel = 'All';
                    if (this.selectedClient && this.selectedWindow && this.selectedModel) {
                        this.onChange();
                    }
                });
            }
        });

        fetch('/api/admin/groups',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    var optionList = [];
                    resp.forEach(g => {
                        optionList.push({
                            key: g,
                            text: g
                        });
                    });

                    var defaultClientOption = 'All GPT Users';

                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            clientOptions: optionList,
                            defaultClientOption: defaultClientOption
                        });
                    });

                    this.selectedClient = defaultClientOption;
                    if (this.selectedClient && this.selectedWindow && this.selectedModel) {
                        this.onChange();
                    }
                });
            }
        });

        fetch('/api/admin/windows',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    var optionList = [];
                    resp.forEach(w => {
                        optionList.push({
                            key: w,
                            text: w
                        });
                    });

                    var defaultWindowOption = 'Last 30 days';
                    if (window.pmusername === 'wei') {
                        var lastMonth = defaultWindowOption;
                        resp.forEach(w => {
                            if (w.length === 8) {
                                defaultWindowOption = lastMonth;
                                lastMonth = w;
                            }
                        });
                    }

                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            windowOptions: optionList,
                            defaultWindowOption: defaultWindowOption
                        });
                    });

                    this.selectedWindow = defaultWindowOption;
                    if (this.selectedClient && this.selectedWindow && this.selectedModel) {
                        this.onChange();
                    }
                });
            }
        });
    }

    onChange() {
        this.props.onChange(this.selectedClient, this.selectedWindow, this.selectedModel);
    }

    onChangeClient(e, o) {
        this.selectedClient = o.key;
        this.onChange();
    }

    onChangeWindow(e, o) {
        this.selectedWindow = o.key;
        this.onChange();
    }

    onChangeModel(e, o) {
        this.selectedModel = o.key;
        this.onChange();
    }

    render() {
        console.log(this.state);
        if (window.inTeams) {
        }

        if (this.props.windowOnly) {
            return (
                <div id='filter-controls'>
                    <span>Time period:</span>
                    <Dropdown ref={this.windowFilterRef} className='filter-dropdown' options={this.state.windowOptions} defaultSelectedKey={this.state.defaultWindowOption} onChange={(e, o) => this.onChangeWindow(e, o)} />
                </div>
            );
        }
        else {
            return (
                <div id='filter-controls'>
                    <span>Client:</span>
                    <Dropdown ref={this.clientFilterRef} className='filter-dropdown' options={this.state.clientOptions} defaultSelectedKey={this.state.defaultClientOption} onChange={(e,o) => this.onChangeClient(e,o)} />
                    <span>Time period:</span>
                    <Dropdown ref={this.windowFilterRef} className='filter-dropdown' options={this.state.windowOptions} defaultSelectedKey={this.state.defaultWindowOption} onChange={(e, o) => this.onChangeWindow(e, o)} />
                    <span>Model:</span>
                    <Dropdown ref={this.modelFilterRef} className='filter-dropdown' options={this.state.modelOptions} defaultSelectedKey={this.state.defaultModelOption} onChange={(e, o) => this.onChangeModel(e, o)} />
                </div>
            );
        }
    }
}

export default FilterControls;
