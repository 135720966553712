import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';

class FTOSearchInputPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };
        this.sheetButtonRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }


    enableExcelButton() {
        this.sheetButtonRef.current.enableButton();
    }

    render() {
        this.initEventHandlers();

        var claimsButton = undefined;
        if (this.props.generateClaimsSheet) {
            claimsButton =
                <AutoDisableButton ref={this.sheetButtonRef} action={() => this.props.generateClaimsSheet()} label='Generate Claims Spreadsheet' />;
        }

        return (
            <div style={{height: '100%', padding: '0.5em', display: 'flex', flexDirection: 'column'}}>
                <div style={{fontWeight: 'bold'}}>Patents</div>
                <div style={{ flexGrow: 1 }}><textarea id='patents-box' style={{ height: '100%', width: '100%', resize: 'none' }} /></div>
                <div style={{ marginTop: '1em' }}>
                    {claimsButton}
                </div>
                <div style={{ marginTop: '1em', fontWeight: 'bold' }}>Product Description</div>
                <div style={{ flexGrow: 2 }}><textarea id='description-box' style={{ height: '100%', width: '100%', resize: 'none' }} /></div>
                <div style={{ marginTop: '1em' }} >
                    <DefaultButton style={{ marginTop: '1em' }} onClick={this.props.doSearch}>Analyze</DefaultButton>
                </div>
            </div>
        );
    }
}

export default FTOSearchInputPanel;
