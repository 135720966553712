import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class DraftStatusRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var status = undefined;
        if (this.props.data.status === 'E')  {
            status = <span style={{ color: 'red', fontWeight: 'bold' }}>Error</span>;
        } else if (this.props.data.status === 'P') {
            status = <span style={{ color: 'Black', fontWeight: 'bold' }}>Processing</span>;
        } else if (this.props.data.status === 'R') {
            status = <span style={{ color: 'DarkGreen', fontWeight: 'bold' }}>Ready</span>;
        } else if (this.props.data.status === 'C') {
            status = <span style={{ color: 'DarkGreen', fontWeight: 'bold' }}>Collected</span>;
        } else if (this.props.data.status === 'I') {
            status = <span style={{ color: 'Black', fontWeight: 'bold' }}>Interactive</span>;
        } else if (this.props.data.status === 'D') {
            status = <span style={{ color: 'DarkGreen', fontWeight: 'bold' }}>Downloaded</span>;
        }

        return (
            <div>
                {status}
            </div>
        );
    }
}

export default DraftStatusRenderer;