import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import historyIcon from './History.png';

class ProcStepRenderer extends Component {
    static displayName = ProcStepRenderer.name;

    constructor(props) {
        super(props);
        this.state = { };
    }

    openHistory() {
        const showHistory = this.props.showHistory;
        $.ajax({
            url: '/api/upc/applicationsteps/' + this.props.data.id,
            success: function (result) {
                window.showHistoryDialog(result);
                /*
                var table = document.createElement('table');

                result.forEach(s => {
                    var stepRow = document.createElement('tr');
                    var dateCell = document.createElement('td');
                    stepRow.appendChild(dateCell);
                    dateCell.innerHTML = s.Timestamp.substring(8, 10) +
                        '.' +
                        s.Timestamp.substring(5, 7) +
                        '.' +
                        s.Timestamp.substring(0, 4);
                    var descCell = document.createElement('td');
                    stepRow.appendChild(descCell);
                    descCell.innerHTML = s.Description;
                    descCell.setAttribute('title', s.DateType);
                    table.appendChild(stepRow);
                });
                $("#history-dialog-content").empty();
                $("#history-dialog-content").append(table);
                window.showHistoryDialog(result);

    */
            }
        });
    }

    render() {
        var appNum = this.props.data['applicationNumber'];
        var className = 'proc-step-plain';
        if(this.props.data['highlightLastProceduralStep'])
            className = 'proc-step-highlight';
        if (appNum === "SELECT ALL")
            return <div />;
        return (
            <div style={{ display: 'flex' }} >
                <div className={className}>{this.props.value}</div>
                <div className='hover-button' onClick={() => { this.openHistory(); }}>
                    <img src={historyIcon} />
                </div>
            </div>
        );
    }
}

export default ProcStepRenderer;
