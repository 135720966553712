import React, { Component } from 'react';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import OpenCaseDialog from './OpenCaseDialog';
import NewCaseDialog from './NewCaseDialog';

class CaseHeader extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            thisCase: undefined
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadCase(thisCase) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                thisCase: thisCase
            });
        });
    }

    render() {
        this.initEventHandlers();

        return (
            <div style={{ height: 'auto', padding: '0.5em', display: 'flex', flexDirection: 'row' }}>
                <span style={{ margin: 'auto 0' }}>Case Number:</span>
                <span style={{ margin: 'auto 0 auto 0.4em', minWidth: '160px' }}>{this.state.thisCase?.caseNo}</span>
                <span style={{ margin: 'auto 0' }}>Application Number:</span>
                <span style={{ margin: 'auto 0 auto 0.4em', minWidth: '160px' }}>{this.state.thisCase?.applicationNo}</span>
                <span style={{ margin: 'auto 0' }}>Technical Area:</span>
                <span style={{ margin: 'auto 0 auto 0.4em', minWidth: '160px' }}>{this.state.thisCase?.technicalArea}</span>
                <div style={{ flexGrow: 1 }} />
                <PrimaryButton style={{margin: 'auto 0'}} onClick={() => window.showOpenCaseDialog()}>Open...</PrimaryButton>
                <PrimaryButton style={{ margin: 'auto 0 auto 1.0em' }} onClick={() => window.showNewCaseDialog()}>New...</PrimaryButton>

                <OpenCaseDialog openCase={(caseId) => this.props.openCase(caseId)} />
                <NewCaseDialog openCase={(caseId) => this.props.openCase(caseId)} />
            </div>
        );
    }
}

export default CaseHeader;
