import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import NewPriorArtDialog from './NewPriorArtDialog';

class PriorArtPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            priorArt: [],
            caseId: undefined,
            selectedPriorArt: undefined
        };

        this.textAreaRef = React.createRef();
        this.priorArtSelectorRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadCase = (caseId) => {
        fetch('/api/trainingdata/cases/' + caseId + '/priorart',
            {
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        var x = this.priorArtSelectorRef.current;
                        x.value = undefined;
                        return ({
                            ...prevState,
                            caseId: caseId,
                            priorArt: resp,
                            selectedPriorArt: undefined
                        });
                    });
                });
            }
        });
    }

    selectPriorArt(priorArt) {
        fetch('/api/trainingdata/priorart/' + priorArt.id + '/text',
            {
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    this.setState((prevState) => {
                        this.textAreaRef.current.value = resp;
                        return ({
                            ...prevState,
                            selectedPriorArt: priorArt
                        });
                    });

                    this.props.loadPriorArt(priorArt.id);
                });
            }
        });
    }

    retrieveDescriptionText() {
        fetch('/api/trainingdata/priorart/' + this.state.selectedPriorArt.id + '/text/loaddefault',
            {
                method: 'post'
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    this.setState((prevState) => {
                        this.textAreaRef.current.value = resp;
                        return ({
                            ...prevState
                        });
                    });
                });
            }
        });
    }

    saveText() {
        var postBody = this.textAreaRef.current.value;
        fetch('/api/trainingdata/priorart/' + this.state.selectedPriorArt.id + '/text',
            {
                method: 'post',
                body: JSON.stringify(postBody),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    render() {
        this.initEventHandlers();

        var optionList = this.state.priorArt.map(p => {
            return (<option onClick={() => this.selectPriorArt(p)}>{p.referenceNumber}</option>);
        });

        var disableTextControls = true;
        if (this.state.selectedPriorArt) {
            disableTextControls = false;
        }

        return (
            <div style={{ height: '100%', padding: '0.5em', display: 'flex', flexDirection: 'column' }}>
                <select ref={this.priorArtSelectorRef} size='10' style={{ fontSize: 'large' }}>
                    {optionList}
                </select>
                <div style={{ padding: '0.5em 0' }}>
                    <PrimaryButton onClick={() => window.showNewPriorArtDialog()}>Add Prior Art...</PrimaryButton>
                </div>
                <div style={{ padding: '1.5em 0 0.5em 0' }}>
                    <PrimaryButton onClick={() => this.retrieveDescriptionText()} disabled={disableTextControls}>Retrieve Description Text</PrimaryButton>
                </div>
                <textarea ref={this.textAreaRef} style={{ flexGrow: 1, resize: 'none' }} disabled={disableTextControls} />
                <div style={{ padding: '0.5em 0 0 0' }}>
                    <PrimaryButton onClick={() => this.saveText()} disabled={disableTextControls}>Save</PrimaryButton>
                </div>
                <NewPriorArtDialog caseId={this.state.caseId} priorArtAdded={() => this.loadCase(this.state.caseId)}/>
            </div>
        );
    }
}

export default PriorArtPanel;
