import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';
import ClaimFeatureEntry from './ClaimFeatureEntry';

class ClaimEntry extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    render() {
        this.initEventHandlers();

        var claimSplits = (this.props.claim.claimNo + '. ' + this.props.claim.claimText).split(/\r|\n/).filter(l => l);
        var claimLines = [];
        claimSplits.forEach(claimLine => {
            claimLines.push(<div key={claimLines.length}>{claimLine}</div>);
        });
        var headerRows = [
            <tr><td colSpan={6} style={{padding: '2em 0 1em 0 '}}>
                <div style={{ fontWeight: 'bold', border: 'solid black 1px', padding: '0.5em' }}>
                    {claimLines}
                </div>
            </td></tr>,
            <tr><td colSpan={6} style={{ paddingBottom: '0.5em', fontStyle: 'italic'}}>{this.props.claim.comments}</td></tr>,
            <tr style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                <td/>
                <td colSpan={2}>AI Assessment</td>
                <td colSpan={2} style={{ padding: '0 1em 0 1em', display: this.props.feedbackDisplay }}>Your Assessment</td>
            </tr>,
            <tr style={{textDecoration: 'underline', fontWeight: 'bold'}}>
                <td>Feature</td>
                <td>Disclosed?</td>
                <td style={{ padding: '0 1em 0 1em' }}>Reasoning</td>
                <td style={{ padding: '0 1em 0 1em', display: this.props.feedbackDisplay }}>Disclosed?</td>
                <td style={{ display: this.props.feedbackDisplay }}>Reasoning</td>
            </tr>
            ];

        var featureRows = this.props.claim.features.map(feature => {
            return (
                <ClaimFeatureEntry key={feature.id} feature={feature} feedbackDisplay={this.props.feedbackDisplay} />
            );
        });
        return (headerRows.concat(featureRows));
    }
}

export default ClaimEntry;
