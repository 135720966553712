import React, { Component } from 'react';
import './Header.css';
import './ADAC.css';

export class ADACHeader extends Component {
    static displayName = ADACHeader.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <div className="header header-adac">
                    <div>
                        ADAC Licensing Demo
                    </div>
                </div>
            </header>
        );
    }
}
