import $ from 'jquery';
import React, { Component } from 'react';
import { PrimaryButton, Dialog } from '@fluentui/react';
//import './GPTUsageDialog.css';

class NewCaseDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        window.showNewCaseDialog = () => {
            this.setState({
                isOpen: true
            });
        };
    }

    close = () => {
        this.setState({
            isOpen: false,
            caseList: undefined
        });
    }

    createCase = () => {
        var caseNumber = $('#caseNumberInput')[0].value;
        var appNumber = $('#appNumberInput')[0].value;
        var techArea = $('#techAreaInput')[0].value;

        var postBody = {
            'CaseNo': caseNumber,
            'ApplicationNo': appNumber,
            'TechnicalArea': techArea
        };
        fetch('/api/trainingdata/cases/add',
            {
                method: 'post',
                body: JSON.stringify(postBody),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                response.text().then(caseId => {
                    this.props.openCase(caseId);
                    this.close();
                });
            }
        });
    }

    render() {
        this.dialogStyles = {
            main: {
                selectors: {
                    '@media (min-width: 0px)': {
                        minHeight: '200px',
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        minWidth: '400px'
                    }
                }
            }
        };

        this.modalProps = {
            styles: this.dialogStyles,
            onDismiss: () => {
                this.close();
            }
        };

        return (
            <Dialog hidden={!this.state.isOpen} modalProps={this.modalProps} title='New Case'>
                <table>
                    <tbody>
                        <tr>
                            <td>Case Number:</td>
                            <td><input id='caseNumberInput'/></td>
                        </tr>
                        <tr>
                            <td>Application Number:</td>
                            <td><input id='appNumberInput'/></td>
                        </tr>
                        <tr>
                            <td>Technical Area:</td>
                            <td><input id='techAreaInput'/></td>
                        </tr>
                        <tr>
                            <td colspan='2'>
                                <div style={{textAlign: 'center'}}>
                                    <PrimaryButton style={{ margin: '1em auto 0 auto' }} onClick={() => this.createCase()}>Create Case</PrimaryButton>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default NewCaseDialog;
