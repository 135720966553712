import React, { Component } from 'react';
import { Dialog, Checkbox, Panel, DefaultButton } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';

class CreateLicensePopup extends Component {
    static displayName = CreateLicensePopup.name;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            newKey: '',
            name: '',
            email: '',
            company: '',
            sendEmail: true,
            includeDownloadLink: true
        };

        window.showCreateLicensePopup = () => {
            this.open();
        };
    }

    open = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                newKey: ''
            });
        });

        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");
        fetch('/api/admin/newkey',
            {
                method: 'get',
                headers: headers
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            newKey: resp
                        });
                    });
                });
            }
        });

        this.setState((prevState) => {
            return ({
                ...prevState,
                isOpen: true
            });
        });
    };

    close = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                isOpen: false
            });
        });
    }


    componentDidMount() {
    }

    toggleEmail() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                sendEmail: !this.state.sendEmail
            });
        });
    }

    toggleDownloadLink() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                includeDownloadLink: !this.state.includeDownloadLink
            });
        });
    }

    close = () => {
        this.setState((prevState) => {
            return ({
                ...prevState,
                isOpen: false
            });
        });
    }

    extractValue(text, key) {
        const regex = new RegExp(`${key} : (.*?) (?=[A-Za-z]+( Name)? :)`, 'g');
        const match = text.match(regex);
        return match ? match[0].split(`${key} : `)[1].trim() : null;
    }

    onUpdateName(e) {
        var newValue = e.currentTarget.value;
        const name = this.extractValue(newValue, 'Name');
        const email = this.extractValue(newValue, 'Email');
        const company = this.extractValue(newValue, 'Company Name');
        if (name && email && company) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    name: name,
                    email: email,
                    company: company
                });
            });
        } else {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    name: newValue
                });
            });
        }
    }

    onUpdateEmail(e) {
        var newValue = e.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                email: newValue
            });
        });
    }

    onUpdateCompany(e) {
        var newValue = e.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                company: newValue
            });
        });
    }

    createLicense() {
        fetch('/api/admin/createLicense',
            {
                method: 'post',
                body: JSON.stringify({
                    licenseKey: this.state.newKey,
                    name: this.state.name,
                    email: this.state.email,
                    company: this.state.company,
                    sendWelcomeEmail: this.state.sendEmail,
                    includeDownloadLink: this.state.includeDownloadLink
                }),
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    this.close();
                });
            } else {
                response.json().then(resp => {
                    if (resp.detail)
                        alert(resp.detail);
                    else
                        alert('Something went wrong');
                });
            }
        });
    }

    render() {
        this.modalProps = {
            //styles: this.dialogStyles,
            style: {width: '60em'},
            onDismiss: () => {
                this.close();
            }
        };

        var downloadDisplay = this.state.sendEmail ? 'block' : 'none';
        var canCreate = this.state.name?.length && this.state.email?.length && this.state.email.indexOf('@') !== -1 && this.state.newKey?.length;
        return (
            <Dialog hidden={!this.state.isOpen} modalProps={this.modalProps} title='Create License' minWidth='35em' >
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1em' }}>
                    <div>License key:</div>
                    <div>{this.state.newKey}</div>
                    <div>Name:</div>
                    <input value={this.state.name} onInput={e => this.onUpdateName(e)}/>
                    <div>Email:</div>
                    <input value={this.state.email} onInput={e => this.onUpdateEmail(e)} />
                    <div>Company:</div>
                    <input value={this.state.company} onInput={e => this.onUpdateCompany(e)} />
                </div>

                <div style={{ marginTop: '1em', display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '1em' }}>
                    <Checkbox checked={this.state.sendEmail} onChange={e => this.toggleEmail()} label='Send welcome email' />
                    <div style={{ display: downloadDisplay }} >
                        <Checkbox checked={this.state.includeDownloadLink} onChange={e => this.toggleDownloadLink()} label='Include download link' />
                    </div>
                </div>
                <div style={{ marginTop: '1em' }} >
                    <DefaultButton disabled={!canCreate} onClick={() => this.createLicense()}>Create License</DefaultButton>
                </div>
            </Dialog>
        );
    }
}

export default withRouter(CreateLicensePopup);
