import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import { Line } from 'react-chartjs-2';
import FilterControls from './FilterControls';

import { CategoryScale, LinearScale, PointElement, Filler, LineElement, Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';

ChartJS.register(ArcElement, Tooltip, Legend, Filler, CategoryScale, LinearScale, PointElement, LineElement);

class AIPerformanceView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { aiChunks: null };
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    filterSelected(group, timeWindow, model) {
        if (group === undefined || timeWindow === undefined || model === undefined)
            return;

        fetch('/api/admin/aiperf/' + timeWindow,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.setState({
                            aiChunks: resp
                        });
                    });
                }
            });
    }

    render() {
        var options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: false
                }
            }
        };
        var chart = undefined;
        if (this.state.aiChunks) {
            var datasets = [];

            var colours = [
                'rgb(255, 0, 0)',
                'rgb(0, 255, 0)',
                'rgb(0, 0, 255)',
                'rgb(0, 255, 255)',
                'rgb(255, 0, 255)',
                'rgb(255, 255, 0)',
                'rgb(255, 128, 0)',
                'rgb(0, 255, 128)',
                'rgb(255, 0, 128)',
                'rgb(128, 255, 0)',
                'rgb(0, 128, 255)',
                'rgb(128, 0, 255)',
                'rgb(0, 0, 0)',
                'rgb(128, 0, 0)',
                'rgb(0, 128, 0)',
                'rgb(0, 0, 128)'
            ];
            var colourIndex = 0;
            for (const [key, value] of Object.entries(this.state.aiChunks[0].modelTimings)) {
                datasets.push({
                    label: key,
                    data: this.state.aiChunks.map(c => c.modelTimings[key]),
                    borderColor: colours[colourIndex++],
                    spanGaps: true,
                    tension: 0.15
                });
            }

            var data = {
                labels: this.state.aiChunks.map(c => c.label),
                datasets: datasets
            };

            chart = <Line options={options} data={data} style={{ maxWidth: '100%', maxHeight: '100%', height: '100%!important' }} />;
        }

        return (
            <div className='main-content' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <FilterControls windowOnly={true} ref={this.filterRef} onChange={(g, w, m) => this.filterSelected(g, w, m)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <div style={{ background: 'white', flexGrow: 1, width: '100%', position: 'relative' }} >
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                        {chart}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AIPerformanceView);
