import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class AddinVersionCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // var appNum = this.props.data['applicationNumber'];
        var localVersion = this.props.data.addinVersion;
        var versionClass = 'version-latest';
        switch(this.props.data.addinVersionLevel) {
            case 'Deprecated':
                versionClass = 'version-deprecated';
                break;
            case 'Old':
                versionClass = 'version-old';
                break;
            case 'Beta':
                versionClass = 'version-beta';
                break;
            case 'Recent':
                versionClass = 'version-recent';
                break;
        }

        return (
            <div className={versionClass}>
                {localVersion}
            </div>
        );
    }
}

export default AddinVersionCellRenderer;
