import React, { Component } from 'react';
import { ActionButton, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './TemplateFilterControls.css';
import TemplateGroupSelector from './TemplateGroupSelector';

class TemplateFilterControls extends Component {

    constructor(props) {
        super(props);
        this.groupSelectorRef = React.createRef();
        this.state = {
            showGroupSelector: false,
            gridRef: props.gridRef,
            radioOptions: [{ key: "EPO", text: "EPO" }, { key: "DPMA", text: "DPMA" }],
            selectedOption: 'EPO'
        };
    }

    componentDidMount() {
    }

    prepareGroups() {
        this.groupSelectorRef.current.updateFilter();
    }

    onChangePatentOffice(event, option) {
        console.log('Changing patent office filter to ' + option.key);
        this.setState({
            showGroupSelector: this.state.showGroupSelector,
            gridRef: this.state.gridRef,
            radioOptions: this.state.radioOptions,
            selectedOption: option.key
        });
        console.log(option.key);
        var officeFilter = this.state.gridRef.current.api.getFilterInstance('office');
        officeFilter.setModel({
            filterType: 'text',
            type: 'equals',
            filter: option.key
        });

        this.state.gridRef.current.api.onFilterChanged();
        this.state.gridRef.current.api.sizeColumnsToFit();
    }

    openNewTemplateDialog() {
        window.showNewTemplateDialog(/*rowData, responseType, germanVersion, sourceUrl*/);
    }

    render() {
        return (
            <div className="filter-control-panel">
                <div className="filter-control">
                    <ChoiceGroup options={this.state.radioOptions} onChange={(e,o) => this.onChangePatentOffice(e,o)} selectedKey={this.state.selectedOption} styles={{ flexContainer: { display: 'flex', gap: '1.5em' } }} />
                </div>
                <div>
                    <ActionButton style={{ display: 'none' }} iconProps={{ iconName: 'AddToShoppingList' }} onClick={() => this.openNewTemplateDialog()}>New template</ActionButton>
                </div>
                <div className="filter-spacer"></div>
                <TemplateGroupSelector ref={this.groupSelectorRef} gridRef={this.state.gridRef} />
            </div>
        );
    }
}

export default TemplateFilterControls;
