import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';
import ClaimEntry from './ClaimEntry';

class ClaimEntryPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    render() {
        this.initEventHandlers();

        var claimEntries = this.props.claims.map(claim => {
            console.log('Generating ClaimEntry for claim ' + claim);
            return (
                <ClaimEntry key={claim.claimNo} claim={claim} feedbackDisplay={this.props.feedbackDisplay} />
            );
        });

        var claimRows = claimEntries.flat();
        
        return (
            <table style={{ margin: '1em' }}>
                {claimRows}
            </table>
        );
    }
}

export default ClaimEntryPanel;
