import React, { Component } from 'react';
import { Link, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class SuggestedPartnerList extends Component {

    constructor(props) {
        super(props);
        this.state = { clients: [], myEmail: undefined, liveFilter: false, germanyFilter: false };
    }

    setPartners(clients, myEmail) {
        this.setState({
            clients: clients,
            myEmail: myEmail,
            liveFilter: this.state.liveFilter,
            germanyFilter: this.state.germanyFilter
        });
    }

    selectClient(clientId) {
        var nav = this.props.navigate;
        var clientUrl = clientId;
        nav(clientUrl);
    }

    toggleLiveFilter(checked) {
        this.setState({
            clients: this.state.clients,
            myEmail: this.state.myEmail,
            liveFilter: checked,
            germanyFilter: this.state.germanyFilter
        });
    }

    toggleGermanyFilter(checked) {
        this.setState({
            clients: this.state.clients,
            myEmail: this.state.myEmail,
            liveFilter: this.state.liveFilter,
            germanyFilter: checked
        });
    }

    instructionsRequested(partnerId, checked) {
        $.ajax({
            url: "/api/upc/partnerInstructionsRequested?partnerId=" + partnerId + "&requested=" + (checked ? 'Y' : 'N') + "&userEmail=" + this.state.myEmail,
            type: "POST"
        });
    }

    instructionsReceived(partnerId, checked) {
        $.ajax({
            url: "/api/upc/partnerInstructionsReceived?partnerId=" + partnerId + "&received=" + (checked ? 'Y' : 'N') + "&userEmail=" + this.state.myEmail,
            type: "POST"
        });
    }


    render() {
        var rows = [];

        var sortedClients = this.state.clients.sort((a, b) => {
            if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });

        sortedClients.forEach(client => {
            var includeClient = true;

            if (this.state.germanyFilter && !client.moreThanGermany)
                includeClient = false;

            if (this.state.liveFilter && client.liveApplicationCount === 0)
                includeClient = false;

            if (includeClient) {
                var otherDefaultAttorneys = [];
                if (client.defaultAttorneys) {
                    client.defaultAttorneys.forEach(attorney => {
                        if (attorney.email !== this.state.myEmail) {
                            otherDefaultAttorneys.push(attorney.name);
                        }
                    });
                }

                var otherClaimingAttorneys = [];
                if (client.claimingAttorneys) {
                    client.claimingAttorneys.forEach(attorney => {
                        if (attorney.email !== this.state.myEmail) {
                            otherClaimingAttorneys.push(attorney.name);
                        }
                    });
                }

                var instructionsRequestedChecked = false;
                var instructionsRequestedDisabled = false;
                if (client.instructionsRequestedBy) {
                    instructionsRequestedChecked = true;
                    if (client.instructionsRequestedBy.email !== this.state.myEmail) {
                        instructionsRequestedDisabled = true;
                    }
                }

                var instructionsReceivedChecked = false;
                var instructionsReceivedDisabled = false;
                if (client.instructionsReceivedBy) {
                    instructionsReceivedChecked = true;
                    if (client.instructionsReceivedBy.email !== this.state.myEmail) {
                        instructionsReceivedDisabled = true;
                    }
                }

                var clientNameSuffix = undefined;
                if (client.recentGrantCount > 1) {
                    clientNameSuffix = <div className='client-suffix'>&nbsp;[{client.recentGrantCount} applications qualify for UP!]</div>
                } else if (client.recentGrantCount == 1) {
                    clientNameSuffix = <div className='client-suffix'>&nbsp;[{client.recentGrantCount} application qualifies for UP!]</div>
                }
                rows.push(
                    <tr>
                        <td>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.5em' }}>
                                <div><Link onClick={() => { this.selectClient(client.id); }}>{client.name}</Link></div>{clientNameSuffix}
                            </div>
                        </td>
                        <td className='checkbox-cell'>
                            <div style={{ marginLeft: '1.5em', marginTop: '0.5em' }}>
                                <Checkbox defaultChecked={instructionsRequestedChecked} disabled={instructionsRequestedDisabled} label='Instructions requested' onChange={(ev, ch) => this.instructionsRequested(client.id, ch)} />
                            </div>
                        </td>
                        <td className='checkbox-cell'>
                            <div style={{ marginLeft: '1.5em', marginTop: '0.5em' }}>
                                <Checkbox defaultChecked={instructionsReceivedChecked} disabled={instructionsReceivedDisabled} label='Instructions received' onChange={(ev, ch) => this.instructionsReceived(client.id, ch)} />
                            </div>
                        </td>
                    </tr>
                );

                var otherRows = [];
                if (otherDefaultAttorneys.length !== 0) {
                    var otherAttorneyElements = [];
                    var prefix = '';
                    otherDefaultAttorneys.forEach(otherAttorney => {
                        var name = otherAttorney;
                        if (otherAttorney === client.instructionsRequestedBy?.name) {
                            name = <u>{otherAttorney}</u>;
                        }
                        otherAttorneyElements.push(<span>{prefix}{name}</span>);
                        prefix = ', ';
                    });

                    otherRows.push(
                        <tr>
                            <td style={{ width: '11em', verticalAlign: 'top' }}>Also responsible:</td>
                            <td>
                                {otherAttorneyElements}
                            </td>
                        </tr>
                    );
                }

                if (otherClaimingAttorneys.length !== 0) {
                    var otherAttorneyElements = [];
                    var prefix = '';
                    otherClaimingAttorneys.forEach(otherAttorney => {
                        var name = otherAttorney;
                        if (otherAttorney === client.instructionsRequestedBy?.name) {
                            name = <u>{otherAttorney}</u>;
                        }
                        otherAttorneyElements.push(<span>{prefix}{name}</span>);
                        prefix = ', ';
                    });
                    otherRows.push(
                        <tr>
                            <td style={{ width: '11em', verticalAlign: 'top' }}>Responsibility claimed:</td>
                            <td>
                                {otherAttorneyElements}
                            </td>
                        </tr>
                    );
                }

                if (otherRows.length !== 0) {
                    rows.push(
                        <tr>
                            <td colSpan={3}>
                                <table style={{ marginLeft: '2em', marginTop: '-0.5em', fontSize: '14px' }} >
                                    <tbody>
                                        {otherRows}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    );
                }
            }
        });

        /*
            <div style={{ display: 'flex', gap: '2em' }}>
                <Checkbox label='Only clients with live patents/applications' onChange={(ev, ch) => { this.toggleLiveFilter(ch); }} />
                <Checkbox label='Only clients with more than DE validation' onChange={(ev, ch) => { this.toggleGermanyFilter(ch); }} />
            </div>
        */

        return (
            <div>
                <table>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default SuggestedPartnerList;
