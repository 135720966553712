import React, { Component } from 'react';
import { Link, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class RepresentativeBreakdown extends Component {

    constructor(props) {
        super(props);
        this.state = { showMe: false, data: null };
    }

    toggle() {
        this.setState({
            showMe: !this.state.showMe,
            data: this.state.data
        });
    }

    hereIsTheData(data) {
        this.setState({ showMe: this.state.showMe, data: data });
    }

    render() {
        var breakdown = undefined;

        if (this.state.showMe) {
            var sortedReps = [];
            var haveBlank = false;
            for (let representative in this.state.data) {
                if (representative === "" || !representative)
                    haveBlank = true;
                else if (representative !== "TOTAL")
                    sortedReps.push(representative);
            }

            sortedReps.sort();

            if (haveBlank)
                sortedReps.push("");

            if (sortedReps.length > 1)
                sortedReps.push("TOTAL");

            var dataRows = [];
            sortedReps.forEach(representative => {
                var thisRow = (<tr>
                    <td>{representative}</td>
                    <td>{this.state.data[representative]["Granted"]}</td>
                    <td>{this.state.data[representative]["Pending"]}</td>
                    <td>{this.state.data[representative]["Refused"]}</td>
                    <td>{this.state.data[representative]["Total"]}</td>
                </tr>);
                dataRows.push(thisRow);
            });

            breakdown = (
                <div>
                    <table class="breakdown-table">
                        <thead>
                            <tr>
                                <td>Representative</td>
                                <td>Granted</td>
                                <td>Pending</td>
                                <td>Refused</td>
                                <td>Total</td>
                                <td/>
                            </tr>
                        </thead>
                        <tbody>
                            {dataRows}
                        </tbody>
                    </table>
                </div>);
        }

        return breakdown;
    }
}

export default RepresentativeBreakdown;
