import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class GPTBalanceRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    topUpBalance() {
        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");

        fetch('/api/admin/topupgpt?email=' + this.props.data.userEmail, { headers: headers, method: 'post' }).
            then((response) => {
                return response.text();
            }).then(respText => {
                this.props.node.setDataValue('gptBalance', respText);
            });
    }


    render() {
        // var appNum = this.props.data['applicationNumber'];
        var colour = '#000000';
        var gptBalance = this.props.data.gptBalance;
        if (gptBalance < 0.1) {
            colour = '#ff0000';
        }

        var balanceSpan = undefined;
        var topUpOption = undefined;
        if (gptBalance !== undefined && gptBalance !== null) {
            balanceSpan = <span style={{ color: colour }}>
                €{(Math.round(gptBalance * 100) / 100).toFixed(2)}
            </span>;
            topUpOption = <a onClick={() => this.topUpBalance()} style={{
                marginLeft: '1.0em',
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer'
            }} >Top-up</a>;
        }

        return (
            <div>
                {balanceSpan}
                {topUpOption}
            </div>
        );
    }
}

export default GPTBalanceRenderer;
