import React, { Component } from 'react';
import { Dialog } from '@fluentui/react';
//import './GPTUsageDialog.css';

class OpenCaseDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            caseList: []
        };

        window.showOpenCaseDialog = () => {
            fetch('/api/trainingdata/cases',
                {
                }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.setState({
                            isOpen: true,
                            caseList: resp
                        });
                    });
                }
            });
        };
    }

    close = () => {
        this.setState({
            isOpen: false,
            caseList: undefined
        });
    }

    openCase = (caseId) => {
        this.props.openCase(caseId);
        this.close();
    }

    buildRow = (d) => {
        return <tr key={d.id}>
            <td>{d.caseNo}</td>
                   <td>{d.applicationNo}</td>
                   <td>{d.technicalArea}</td>
            <td><a style={{color:'blue', cursor:'pointer'}} onClick={()=>this.openCase(d.id)}>Open</a></td>
        </tr>;
    };

    render() {
        this.dialogStyles = {
            main: {
                selectors: {
                    '@media (min-width: 0px)': {
                        minHeight: '400px',
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        minWidth: '800px'
                    }
                }
            }
        };

        this.modalProps = {
            styles: this.dialogStyles,
            onDismiss: () => {
                this.close();
            }
        };

        var contentList = [];
        if (this.state.caseList !== undefined)
            contentList = this.state.caseList.map(this.buildRow);
        return (
            <Dialog hidden={!this.state.isOpen} modalProps={this.modalProps} title='Open Case'>
                <table>
                    <thead>
                        <tr>
                            <td style={{ paddingRight: '2em', fontWeight: 'bold' }}>Case Number</td>
                            <td style={{ paddingRight: '2em', fontWeight: 'bold' }}>Application Number</td>
                            <td style={{ paddingRight: '2em', fontWeight: 'bold' }}>Technical Area</td>
                        </tr>
                    </thead>
                    <tbody>
                        {contentList}
                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default OpenCaseDialog;
