import React, { Component } from 'react';
import { ActionButton, Checkbox, TextField, Label } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './UPCContent.css';
import './UPCClientViewFilterControls.css';
import './ClientSelector.css';
import UPCClientViewColumnSelector from './UPCClientViewColumnSelector';

class UPCClientViewFilterControls extends Component {
    static displayName = UPCClientViewFilterControls.name;

    constructor(props) {
        super(props);
        this.state = {
            showColumnSelector: false,
            gridRef: props.gridRef,
            bbChecked: false
        };
    }

    componentDidMount() {
    }

    onChangeBoehmertOnly(boehmertOnly) {
        this.setState({
            showColumnSelector: this.state.showColumnSelector,
            gridRef: this.state.gridRef,
            bbChecked: boehmertOnly
        });

        var fieldFilter = this.state.gridRef.current.api.getFilterInstance('representative');
        fieldFilter.setModel({
            filterType: 'text',
            type: 'contains',
            filter: boehmertOnly ? "Boehmert" : null
        });
        if (boehmertOnly) {
            $("#representative-filter-control").css('visibility', 'hidden');
        } else {
            $("#representative-filter-control").css('visibility', 'visible');
        }
        this.state.gridRef.current.api.onFilterChanged();
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    onChangeOnlyGranted(onlyGranted) {
        var fieldFilter = this.state.gridRef.current.api.getFilterInstance('isGranted');
        fieldFilter.setModel({
            filterType: 'text',
            type: 'equals',
            filter: onlyGranted ? 'Y' : null
        });

        this.state.gridRef.current.api.onFilterChanged();
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    onChangeUpQualified(upQualified) {
        var fieldFilter = this.state.gridRef.current.api.getFilterInstance('upQualified');
        fieldFilter.setModel({
            filterType: 'text',
            type: 'equals',
            filter: upQualified ? 'Y' : null
        });

        this.state.gridRef.current.api.onFilterChanged();
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    onChangeRepresentativeFilter(newFilter) {
        var fieldFilter = this.state.gridRef.current.api.getFilterInstance('representative');
        fieldFilter.setModel({
            filterType: 'text',
            type: 'contains',
            filter: newFilter
        });
        this.state.gridRef.current.api.onFilterChanged();
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    render() {
        this.bbCheckbox = undefined;
        if (!this.props.isPartner)
            this.bbCheckbox = (<Checkbox id='boehmert-only-checkbox' checked={this.state.bbChecked} label='Only represented by Boehmert' onChange={e => {
                this.onChangeBoehmertOnly(e.target.checked);
            }}></Checkbox>);

        var iconProps = { iconName: 'TripleColumn' };
        var representativeBreakdownToggle = <ActionButton style={{ margin: 'auto' }} iconProps={iconProps} onClick={() => this.props.toggleBreakdown() }>Representative&nbsp;breakdown</ActionButton>

        var upQualifiedControl = undefined;
        if (window.teamsAuthenticated) {
            upQualifiedControl = (
                <div className="filter-control">
                    <Checkbox id='up-qualified-checkbox' label='Only applications that qualify for UP' onChange={e => {
                        this.onChangeUpQualified(e.target.checked);
                    }}></Checkbox>
                </div>
            );
        }

        return (
            <div className="filter-control-panel">
                <div className="filter-control">
                    <Checkbox id='only-granted-checkbox' label='Only granted patents' onChange={e => {
                            this.onChangeOnlyGranted(e.target.checked);
                    }}></Checkbox>
                </div>
                <div className="filter-control">
                    {this.bbCheckbox}
                </div>
                {upQualifiedControl}
                <div id="representative-filter-control" className="filter-control">
                    <Label className='text-field-label'>Filter by representative:</Label><div className='text-field-value' ><TextField id='representative-filter-textbox' onChange={e => {
                        this.onChangeRepresentativeFilter(e.target.value);
                    }} /></div>
                </div>
                <div className="filter-spacer"></div>
                {representativeBreakdownToggle}
                <UPCClientViewColumnSelector gridRef={this.state.gridRef} />
            </div>
        );
    }
}

export default UPCClientViewFilterControls;
