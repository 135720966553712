import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import './UPCContent.css';
import './ClientView.css';
import { withRouter } from '../WithRouter';
import UPCClientViewHistoryDialog from './UPCClientViewHistoryDialog';
import UPCClientViewFilterControls from './UPCClientViewFilterControls';
import { AgGridReact } from 'ag-grid-react';
import ProcStepRenderer from './CellRenderers/ProcStepRenderer';
import AutoDisableButton from './AutoDisableButton';
import ApplicationNumberRenderer from './CellRenderers/ApplicationNumberRenderer';
import BoehmertCaseNumberRenderer from './CellRenderers/BoehmertCaseNumberRenderer';
import ClientViewExtraInfo from './ClientViewExtraInfo';
import ExistingMandateList from './ExistingMandateList';
import RepresentativeBreakdown from './RepresentativeBreakdown';
import ClientViewWarning from './ClientViewWarning';
import OptOutSummary from './OptOutSummary';

function CheckboxRenderer() { }

CheckboxRenderer.prototype.init = function (params) {
    this.params = params;

    this.eGui = document.createElement('input');
    this.eGui.type = 'checkbox';
    this.eGui.classList.add('column-checkbox');
    this.eGui.checked = params.value;

    this.checkedHandler = this.checkedHandler.bind(this);
    this.eGui.addEventListener('click', this.checkedHandler);
}

CheckboxRenderer.prototype.checkedHandler = function (e) {
    let checked = e.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
    this.params.node.data.optOutChecked = checked;

    if (this.params.node.data.applicationNumber === "SELECT ALL") {
        this.params.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            rowData.optOutChecked = checked;
            node.setData(rowData);
        });
    }

    this.params.updateSubtotals();

    var anyChecked = false;
    this.params.api.forEachNodeAfterFilter(node => {
        var rowData = node.data;
        if (rowData.optOutChecked) {
            anyChecked = true;
        }
    });

    if (anyChecked) {
        this.params.mandateButtonRef.current.enableButton();
        this.params.apaButtonRef.current.enableButton();
    }
    else {
        this.params.mandateButtonRef.current.disableButton();
        this.params.apaButtonRef.current.disableButton();
    }
}

CheckboxRenderer.prototype.getGui = function () {
    return this.eGui;
}

CheckboxRenderer.prototype.destroy = function () {
    this.eGui.removeEventListener('click', this.checkedHandler);
}


// Application Number renderer
function AppNumRenderer() { }

AppNumRenderer.prototype.init = function (params) {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.classList.add('app-num-wrapper');
    this.eGui.innerHTML = '<div>' + params.value + '</div>';
    if (params.value !== 'SELECT ALL') {
        var linkRef = params.value.replace(' ', '');
        this.eGui.innerHTML += '<div class="hover-button" style="margin:auto 0 auto 0.5rem"><a href="https://register.epo.org/application?number=' +
            linkRef +
            '&lng=en&tab=doclist" target="_blank"><img src="https://patdoc.net/Content/Web/Images/EPO.png" /></a></div>';
    }
}

AppNumRenderer.prototype.getGui = function () {
    return this.eGui;
}

// Application status renderer
function AppStatusRenderer() { }

AppStatusRenderer.prototype.init = function (params) {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.classList.add('app-status-wrapper');

    var fullHtml = '';
    if (params.value) {
        var indicator;
        if (params.node.data.applicationStatusIndicator === 0 || params.node.data.applicationStatusIndicator === 1)
            indicator = '<span class="dot dot-red"></span>';
        else if (params.node.data.applicationStatusIndicator === 3)
            indicator = '<span class="dot dot-green"></span>';
        else if (params.node.data.applicationStatusIndicator === 4)
            indicator = '<span class="dot dot-grey"></span>';
        else
            indicator = '<span class="dot dot-yellow"></span>';
        fullHtml += '<div class="app-status-main">' + indicator + params.value + '</div>';
    }

    if (params.node.data.checkInForce && params.node.data.applicationStatusIndicator === 3) {
        var checkInForceLine = '(Possibly no longer in force)';
        fullHtml += '<div class="app-status-extra">' + checkInForceLine + '</div>';
    }

    if (params.node.data.otherClientNames && params.node.data.otherClientNames.length !== 0) {
        var coOwnedLine = 'Co-owned by ' + params.node.data.otherClientNames.join(' / ');
        fullHtml += '<div class="app-status-extra">' + coOwnedLine + '</div>';
    }

    if (params.node.data.internalOwners && params.node.data.internalOwners.length !== 0) {
        if (!params.node.data.internalOwners.some(o => o.replaceAll(/[^A-Za-z�-��-��-�0-9]/g, '').toLowerCase() === params.parent.clientName.replaceAll(/[^A-Za-z�-��-��-�0-9]/g, '').toLowerCase())) {
            var ownerMismatchLine = 'INTERNAL: Genese indicates ' + params.node.data.internalOwners.join(' / ') + " as proprietor.";
            fullHtml += '<div class="app-status-extra">' + ownerMismatchLine + '</div>';
        }
    }

    // Don't show this for "LG Energy Solution Ltd."
    //if (params.parent.props.params.id !== '3f8e16e5-7d72-4640-9e53-f137f39af8be') {
        if (params.node.data.dpmaMismatchLine) {
            fullHtml += '<div class="app-status-extra">' + params.node.data.dpmaMismatchLine + '</div>';
        }
        else if (params.node.data.dpmaMismatchOwners) {
            var dpmaMismatchLine = 'According to DPMA, the German part is owned by ' + params.node.data.dpmaMismatchOwners.join(', ');
            fullHtml += '<div class="app-status-extra">' + dpmaMismatchLine + '</div>';
        }
    //}

    this.eGui.innerHTML = fullHtml;
}

AppStatusRenderer.prototype.getGui = function () {
    return this.eGui;
}

// Last Procedural Step renderer
/*
function ProcStepRenderer() { }

ProcStepRenderer.prototype.init = function (params) {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.classList.add('proc-step-wrapper');
    if (params.value !== undefined) {
        if (params.value)
            this.eGui.innerHTML += '<div>' + params.value + '</div>';
        this.eGui.innerHTML += '<div class="hover-button" onclick="openHistory(\'' + this.params.data.Id + '\')"><img src="https://patdoc.net/Content/Web/Images/History.png" /></div>';
    }
}

ProcStepRenderer.prototype.getGui = function () {
    return this.eGui;
}
*/
class ClientView extends Component {
    static displayName = ClientView.name;

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };

        this.excelButtonRef = React.createRef();
        this.mandateButtonRef = React.createRef();
        this.apaButtonRef = React.createRef();
        this.filterRef = React.createRef();
        this.extraInfoRef = React.createRef();
        this.mandateListRef = React.createRef();
        this.breakdownRef = React.createRef();
        this.summaryRef = React.createRef();
    }

    componentDidMount = () => {
        var baseUrl = this.props.isPartner ? '/api/upc/partnername' : '/api/upc/clientname';
        $.ajax({
            url: baseUrl + '?id=' + this.props.params.id,
            type: "GET",
            success: (result) => {
                $("#client-name-label").text(result);
                this.clientName = result;
            }
        });
    }

    copyLink() {
        if (this.props.isPartner)
            this.copy('https://patdoc.net/UPCOptOut/Partners/' + this.props.params.id);
        else
            this.copy('https://patdoc.net/UPCOptOut/Client/' + this.props.params.id);
    }

    copy(txt) {
        var cb = document.getElementById("cb");
        cb.value = txt;
        cb.style.display = 'block';
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
    }

    representativeBreakdownToggle() {
        // TODO: When I was updating the state in checkForMandates() it was breaking the auto-refresh function (gridOptions.api was becoming null or undefined)
        // Make sure this doesn't do the same thing
        this.breakdownRef.current.toggle();
    }

    setCheckboxesFromMandate(applicationNumbers) {
        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            rowData.optOutChecked = applicationNumbers.some(n => n === rowData.applicationNumber || n === rowData.patentNumber || rowData.patentNumber.startsWith(n + ' '));
            node.setData(rowData);
        });

        var anyChecked = false;
        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            if (rowData.optOutChecked) {
                anyChecked = true;
            }
        });

        if (anyChecked) {
            this.mandateButtonRef.current.enableButton();
            this.apaButtonRef.current.enableButton();
        }
        else {
            this.mandateButtonRef.current.disableButton();
            this.apaButtonRef.current.disableButton();
        }

        this.updateSubtotals();
    }

    async pollForUpdates() {
        if (!this.gridOptions.api) {
            console.log('Aborting poll thread ' + this.gridOptions + ' / ' + this.gridOptions.api);
            console.log(this);
            return;
        }

        try {
            var gridOptions = this.gridOptions;
            var updatedIds = this.updatedIds;
            var nodeToUpdate = undefined;
            var idToUpdate;

            gridOptions.api.getRenderedNodes().forEach((rowNode) => {
                if (nodeToUpdate !== undefined)
                    return;

                var rowData = rowNode.data;
                var rowId = rowData.id;
                if (!updatedIds.has(rowId)) {
                    nodeToUpdate = rowNode;
                    idToUpdate = rowId;
                }
            });

            if (nodeToUpdate !== undefined) {
                var headers = {};
                headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");

                await $.ajax({
                    url: '/api/upc/performapplicationrefresh/' + this.props.params.id + '/' + idToUpdate + '?ispartner=' + (this.props.isPartner ? 'y' : 'n'),
                    headers: headers,
                    success: function (result) {
                        console.log('Result back for ' + result.id + ' : ' + idToUpdate);

                        // Not a column, so we need to do this manually. Can't use setDataValue for non-columns, either.
                        if (nodeToUpdate.data['applicationStatusIndicator'] !== result['applicationStatusIndicator']) {
                            console.log('Updating applicationStatusIndicator');
                            nodeToUpdate.data['applicationStatusIndicator'] = result['applicationStatusIndicator'];
                        }

                        gridOptions.columnDefs.forEach(col => {
                            if (col.field !== "isGranted" && col.field !== "optOutChecked" && col.field !== "upQualified") {
                                if (nodeToUpdate.data[col.field] !== result[col.field]) {
                                    console.log('Updating ' + col.field);
                                    nodeToUpdate.setDataValue(col.field, result[col.field]);
                                }
                            }
                        });

                        updatedIds.add(result.id);
                        console.log('Result processed for ' + result.id + ' : ' + idToUpdate);
                    },
                    error: function (err) {
                        console.log(err);
                    }
                });
            }
        }
        finally {
            setTimeout(() => this.pollForUpdates(), 50);
        }
    }

    openHistory(appId) {
        $.ajax({
            url: '/api/upc/applicationsteps/' + appId,
            success: function (result) {
                /*
                var table = document.createElement('table');

                result.forEach(s => {
                    var stepRow = document.createElement('tr');
                    var dateCell = document.createElement('td');
                    stepRow.appendChild(dateCell);
                    dateCell.innerHTML = s.Timestamp.substring(8, 10) +
                        '.' +
                        s.Timestamp.substring(5, 7) +
                        '.' +
                        s.Timestamp.substring(0, 4);
                    var descCell = document.createElement('td');
                    stepRow.appendChild(descCell);
                    descCell.innerHTML = s.Description;
                    descCell.setAttribute('title', s.DateType);
                    table.appendChild(stepRow);
                });
                $("#history-dialog-content").empty();
                $("#history-dialog-content").append(table);

    */
                window.showHistoryDialog(result);
            }
        });
    }

    hideHistory() {
        $("#history-overlay").hide();
    }

    updateSubtotals() {
        // TODO
        var totalData = {
            "TOTAL": { "Granted": 0, "Pending": 0, "Refused": 0, "Total": 0 }
        };

        var optedOut = 0;
        var total = 0;

        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            var repKey = rowData.representative;
            repKey = repKey.replace('et al, ', '');
            if (totalData[repKey] === undefined)
                totalData[repKey] = { "Granted": 0, "Pending": 0, "Refused": 0, "Total": 0 };
            if (rowData.applicationStatusIndicator === 0 || rowData.applicationStatusIndicator === 1) {
                ++totalData[repKey]["Refused"];
                ++totalData["TOTAL"]["Refused"];
            } else if (rowData.applicationStatusIndicator === 3) {
                ++totalData[repKey]["Granted"];
                ++totalData["TOTAL"]["Granted"];
            } else {
                ++totalData[repKey]["Pending"];
                ++totalData["TOTAL"]["Pending"];
            }
            ++totalData[repKey]["Total"];
            ++totalData["TOTAL"]["Total"];

            ++total;
            if (rowData.optOutChecked)
                ++optedOut;
        });

        this.breakdownRef.current.hereIsTheData(totalData);
        this.summaryRef.current.hereAreTheCounts(optedOut, total);

        return;

        var totalHtml = '<table><tr><td class="header">Representative</td><td class="header">Granted</td><td class="header">Pending</td><td class="header">Refused</td><td class="header">Total</td></tr>';

        var sortedReps = [];
        var haveBlank = false;
        for (let representative in totalData) {
            if (representative === "" || !representative)
                haveBlank = true;
            else if (representative !== "TOTAL")
                sortedReps.push(representative);
        }

        sortedReps.sort();

        if (haveBlank)
            sortedReps.push("");

        if (sortedReps.length > 1)
            sortedReps.push("TOTAL");

        sortedReps.forEach(representative => {
            totalHtml += '<tr><td>' +
                representative +
                '</td><td>' +
                totalData[representative]["Granted"] +
                '</td><td>' +
                totalData[representative]["Pending"] +
                '</td><td>' +
                totalData[representative]["Refused"] +
                '</td><td>' +
                totalData[representative]["Total"] +
                '</td></tr>';
        });

        totalHtml += '</table>';

        document.getElementById('subtotalgrid-container').innerHTML = totalHtml;
    }

    renderDataInTheTable(api) {
        if (!api) {
            console.log('renderDateInTheTable() - api is missing');
        }

        var headers = {};
        //if (window.bearerToken)
            headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");

        var baseUrl = this.props.isPartner ? '/api/upc/partnerapplications/' : '/api/upc/clientapplications/';
        fetch(baseUrl + this.props.params.id + '?includemissing=y', { headers: headers })
            .then((response) => {
                return response.json();
            }).then((allData) => {
                var data = allData[0];
                var extraData = allData[1];

                if (data.length > 1) {
                    api.setPinnedTopRowData([{ applicationNumber: "SELECT ALL" }]);
                }
                data.forEach(r => {
                    r.isGranted = (r.applicationStatus === 'The patent has been granted' ||
                        r.applicationStatus === 'Grant of patent is intended' ||
                        r.applicationStatus === 'No opposition filed within time limit' ||
                        r.applicationStatus === 'Opposition rejected' ||
                        r.applicationStatus === 'Patent maintained as amended' ||
                        r.applicationStatus === 'Opposition procedure closed'
                    )
                        ? 'Y'
                        : 'N';

                    r.upQualified = (r.highlightLastProceduralStep ? 'Y' : 'N');
                });
                api.setRowData(data);

                //$("#boehmert-only-checkbox").prop('checked', true).change();
                this.filterRef.current.onChangeBoehmertOnly(true);
                api.sizeColumnsToFit();
                api.resetRowHeights();

                if (extraData.length !== 0) {
                    this.extraInfoRef.current.setExtraInfo('INTERNAL: Genese indicates the following additional patents that are not shown by the EPO register: ' + extraData.map(a => {
                        var num = a.applicationNumber;
                        if (a.countryCodes) {
                            var countries = a.countryCodes.join(', ');
                            return 'EP ' + num + ' (' + countries + ')';
                        } else {
                            return 'EP ' + num;
                        }
                    }).join(', '));
                }

                if (this.mandateListRef.current)
                    this.mandateListRef.current.mainGridLoaded();
            });
    }

    toggleBoehmertOnlyCheckbox() {
        var fieldFilter = this.gridOptions.api.getFilterInstance('Representative');
        fieldFilter.setModel({
            filterType: 'text',
            type: 'contains',
            filter: $(this).prop("checked") ? "Boehmert" : null
        });
        if ($(this).prop("checked")) {
            $("#representative-filter-control").css('visibility', 'hidden');
        } else {
            $("#representative-filter-control").css('visibility', 'visible');
        }
        this.gridOptions.api.onFilterChanged();
        this.gridOptions.api.sizeColumnsToFit();
        this.gridOptions.api.resetRowHeights();
    }

    initEventHandlers() {
        $("#representative-filter-textbox").keyup(function () {
        });


        $("#select-all-checkbox").change(function () {
            if ($(this).prop("checked")) {
                this.gridOptions.api.forEachNodeAfterFilter(node => {
                    var rowData = node.data;
                    rowData.optOutChecked = true;
                    node.setData(rowData);
                });
            } else {
                this.gridOptions.api.forEachNodeAfterFilter(node => {
                    var rowData = node.data;
                    rowData.optOutChecked = false;
                    node.setData(rowData);
                });
            }
        });

    }

    checkForMandates() {
        fetch('/api/upc/requestedmandates?clientId=' + this.props.params.id)
            .then(response => {
                return response.json();
            }).then(data => {
                var timestamps = [];
                data.forEach(mandateTimestamp => {
                    timestamps.push(mandateTimestamp);
                });

                console.log('Setting state while this.gridoptions.api = ' + this.gridOptions.api);
                console.log(this);

                this.setState((prevState) => {
                    console.log(' Setting state while this.gridoptions.api = ' + this.gridOptions.api);
                    console.log(this);
                    console.log(prevState);
                    return ({
                        ...prevState,
                        mandateTimestamps: timestamps
                    });
                });
            });
    }
    
    initGrid() {
        // React reference
        this.gridRef = this.props.useRef;
        this.columnDefs = [
            {
                headerName: 'Opt Out?',
                field: 'optOutChecked',
                cellRenderer: 'checkboxRenderer',
                cellRendererParams: { mandateButtonRef: this.mandateButtonRef, apaButtonRef: this.apaButtonRef, updateSubtotals: () => this.updateSubtotals() },
                width: 95,
                suppressSizeToFit: true,
                onchange: () => {
                    //alert('Ping');
                }
            },
            {
                field: 'applicationNumber',
                width: 175,
                suppressSizeToFit: true,
                sortable: true,
                cellRenderer: ApplicationNumberRenderer,
                enableCellChangeFlash: true
            },
            {
                field: 'boehmertCaseNumber',
                width: 185,
                suppressSizeToFit: true,
                sortable: true,
                cellRenderer: BoehmertCaseNumberRenderer,
                enableCellChangeFlash: true
            },
            {
                field: 'applicants',
                filter: 'agTextColumnFilter',
                flex: 3,
                sortable: true,
                wrapText: true,
                //autoHeight: true,
                cellClass: 'wrap-cell',
                enableCellChangeFlash: true,
                hide: !this.props.isPartner
            },
            {
                field: 'title',
                filter: 'agTextColumnFilter',
                flex: 3,
                sortable: true,
                wrapText: true,
                //autoHeight: true,
                cellClass: 'wrap-cell',
                enableCellChangeFlash: true
            },
            {
                field: 'representative',
                filter: 'agTextColumnFilter',
                flex: 1,
                sortable: true,
                wrapText: true,
                // autoHeight: true,
                cellClass: 'wrap-cell',
                enableCellChangeFlash: true
            },
            {
                field: 'applicationStatus',
                filter: 'agTextColumnFilter',
                flex: 2,
                sortable: true,
                cellRenderer: 'appStatusRenderer',
                cellRendererParams: { parent: this },
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                    if (nodeA.data.applicationStatusIndicator !== nodeB.data.applicationStatusIndicator)
                        return nodeB.data.applicationStatusIndicator - nodeA.data.applicationStatusIndicator;
                    if (valueA === valueB) return 0;
                    if (valueA < valueB) return (isInverted ? 1 : -1);
                    return (isInverted ? -1 : 1);
                },
                enableCellChangeFlash: true
            },
            {
                headerName: 'PatDoc: Last Procedural Step',
                field: 'lastProceduralStep',
                filter: 'agTextColumnFilter',
                wrapText: true,
                // autoHeight: true,
                hide: false,
                cellClass: 'wrap-cell',
                cellRenderer: ProcStepRenderer,
                cellRendererParams: {
                    showHistory: this.showHistory
                },//'procStepRenderer',
                enableCellChangeFlash: true
            },
            { field: 'patentNumber', maxWidth: 150, sortable: true, enableCellChangeFlash: true },
            { headerName: 'In Force In (According to EPO Register)', wrapHeaderText: true, maxWidth: 190, field: 'validatedIn', filter: 'agTextColumnFilter', flex: 1, sortable: true, enableCellChangeFlash: true },
            { field: 'optOutStatus', maxWidth: 130, sortable: true, enableCellChangeFlash: true },
            { field: 'isGranted', filter: 'agTextColumnFilter', hide: true, enableCellChangeFlash: true },
            { field: 'upQualified', filter: 'agTextColumnFilter', hide: true }
        ];

        this.gridOptions = {
            getRowHeight: params => {
                //return 36;
                /*
                alert('get row height called');
                alert('this.gridoptions = ' + this.gridOptions);
                alert('this.gridoptions.api = ' + this.gridOptions.api);
                alert('this.gridoptions.api.columnModel = ' + this.gridOptions.api.columnModel);
                alert('this.gridoptions.api.columnModel.columnApi = ' + this.gridOptions.api.columnModel.columnApi);
                */
                var maxTitleRows = Math.round(1000 / this.gridRef.current.columnApi.getColumn('title').actualWidth);
                var maxStatusRows = Math.round(500 / this.gridRef.current.columnApi.getColumn('applicationStatus').actualWidth);


                // Add extra rows here if we're showing extra information such as other applicants or internal data
                if (params.node.data.checkInForce && params.node.data.applicationStatusIndicator === 3) {
                    ++maxStatusRows;
                }

                if (params.node.data.otherClientNames && params.node.data.otherClientNames.length !== 0) {
                    var coOwnedLine = 'Co-owned by ' + params.node.data.otherClientNames.join(' / ');
                    var coOwnedWidthEstimate = coOwnedLine.length * 10;
                    var coOwnedRows = Math.round(coOwnedWidthEstimate / this.gridRef.current.columnApi.getColumn('applicationStatus').actualWidth);
                    maxStatusRows += coOwnedRows;
                }

                if (params.node.data.internalOwners && params.node.data.internalOwners.length !== 0) {
                    if (!params.node.data.internalOwners.some(o => o.toLowerCase() === this.clientName.toLowerCase())) {
                        var ownerMismatchLine = 'INTERNAL: Genese indicates ' + params.node.data.internalOwners.join(' / ') + " as proprietor.";
                        var ownerMismatchWidthEstimate = ownerMismatchLine.length * 10;
                        var ownerMismatchRows = Math.round(ownerMismatchWidthEstimate / this.gridRef.current.columnApi.getColumn('applicationStatus').actualWidth);
                        maxStatusRows += ownerMismatchRows;
                    }
                }

                if (params.node.data.dpmaMismatchLine) {
                    var dpmaMismatchWidthEstimate = params.node.data.dpmaMismatchLine.length * 10;
                    var dpmaMismatchRows = Math.round(dpmaMismatchWidthEstimate / this.gridRef.current.columnApi.getColumn('applicationStatus').actualWidth);
                    maxStatusRows += dpmaMismatchRows;
                }
                else if (params.node.data.dpmaMismatchOwners) {
                    var dpmaMismatchLine = 'According to DPMA, the German part is owned by ' + params.node.data.dpmaMismatchOwners.join(', ');
                    var dpmaMismatchWidthEstimate = dpmaMismatchLine.length * 10;
                    var dpmaMismatchRows = Math.round(dpmaMismatchWidthEstimate / this.gridRef.current.columnApi.getColumn('applicationStatus').actualWidth);
                    maxStatusRows += dpmaMismatchRows;
                }

                // New minimum height of 47 instead of 36 (2 rows instead of 1)
                return params.data['applicationNumber'] === "SELECT ALL" ? 36 : Math.max(47, 11 + 25 * Math.max(maxTitleRows, maxStatusRows))
                // return params.data['applicationNumber'] === "SELECT ALL" ? 36 : Math.max(36, 11 + 25 * Math.round(1000 / this.gridOptions.api.columnModel.columnApi.getColumn('Title').actualWidth))
            },
            columnDefs: this.columnDefs,
            enableCellChangeFlash: false,
            onGridReady: (event) => {
                this.renderDataInTheTable(event.api);
                this.updatedIds = new Set();
                this.pollForUpdates();
                // this.checkForMandates();
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
                this.gridRef.current.api.resetRowHeights();
            },
            onFilterChanged: () => {
                this.updateSubtotals();
            }
        };

        // this.eGridDiv = document.getElementById('data-table');
        this.gridOptions.components = {
            checkboxRenderer: CheckboxRenderer,
            appNumRenderer: AppNumRenderer,
            appStatusRenderer: AppStatusRenderer,
            // procStepRenderer: ProcStepRenderer
        }
        // window.upcGrid = new Grid(this.eGridDiv, this.gridOptions);
        this.initEventHandlers();
    }

    GenerateXlsx() {
        var buttonToEnable = this.excelButtonRef.current;

        var request = [];
        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            if (rowData.ApplicationNumber !== "SELECT ALL") {
                var entry = {};
                entry.Id = rowData.id;
                entry.OptOutChecked = rowData.optOutChecked;
                request.push(entry);
            }
        });

        var url = "/api/upc/generateexcel?id=" + this.props.params.id;
        if (this.props.isPartner)
            url += "&isPartner=y";
        else
            url += "&isPartner=n";

        $.ajax({
            url: url,
            type: "POST",
            data: JSON.stringify(request),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                window.location.href = "/api/upc/downloadexcel/" + result;
                buttonToEnable.enableButton();
            }
        });
    }

    GenerateMandate() {
        var buttonToEnable = this.mandateButtonRef.current;

        var request = [];
        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            if (rowData.ApplicationNumber !== "SELECT ALL") {
                var entry = {};
                entry.ApplicationNumber = rowData.applicationNumber;
                entry.OptOutChecked = rowData.optOutChecked;
                entry.PatentNumber = rowData.patentNumber;
                request.push(entry);
            }
        });

        $.ajax({
            url: "/api/upc/generatemandate?clientId=" + this.props.params.id + "&username=" + (window?.teamsDisplayName ?? ''),
            type: "POST",
            data: JSON.stringify(request),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                window.location.href = "/api/upc/downloadmandate/" + result;
                buttonToEnable.enableButton();
            }
        });
    }

    GenerateAPAList() {
        var buttonToEnable = this.apaButtonRef.current;

        var request = [];
        this.gridOptions.api.forEachNodeAfterFilter(node => {
            var rowData = node.data;
            if (rowData.ApplicationNumber !== "SELECT ALL") {
                var entry = {};
                entry.ApplicationNumber = rowData.applicationNumber;
                entry.OptOutChecked = rowData.optOutChecked;
                entry.PatentNumber = rowData.patentNumber;
                request.push(entry);
            }
        });

        $.ajax({
            url: "/api/upc/generateapalist?clientId=" + this.props.params.id,
            type: "POST",
            data: JSON.stringify(request),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                window.location.href = "/api/upc/downloadapalist/" + result;
                buttonToEnable.enableButton();
            }
        });
    }

    reloadGrid() {
        this.renderDataInTheTable(this.gridOptions.api);
    }

    render() {
        this.initGrid();
        var historyPopup = <UPCClientViewHistoryDialog />;


        /*
        if (window.viewBag.teams !== 'y') {
            header = <div className="header" id="header">
                <div>
                    <a href="https://patdoc.net/upcoptout" style={{ color: 'white', textDecoration: 'none' }}>
                        Boehmert UPC Tool
                    </a>
                </div>
                <img className="logo" src="../../Content/Web/Images/Boehmert.png" />
            </div>;
        }
        */

        let backLink = undefined;
        let warning = undefined;
        if (window.inTeams) {
            var backUrl = this.props.isPartner ? '/UPCOptOut/Partners' : '/UPCOptOut';
            var backLabel = this.props.isPartner ? 'Select contact' : 'Select client';

            backLink =
                <Link onClick={() => {
                    this.props.navigate(backUrl);
                }} style={{
                    fontSize: '16px',
                    fontWeight: 'normal',
                    float: 'right',
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)'
                    }}>{backLabel}</Link>;
        }

        /*
        if (window.viewBag.status === "QUEUED") {
            warning = <MessageBar isMultiline={false} messageBarType={MessageBarType.warning} actions={
                <div>
                    <MessageBarButton onClick={() => this.reloadPage()}>Refresh</MessageBarButton>
                </div>}>This client is queued for processing - the application list will be available shortly.</MessageBar>;
        }
        else if (window.viewBag.status === "PROCESSING") {
            warning = <MessageBar isMultiline={false} messageBarType={MessageBarType.warning} actions={
                <div>
                    <MessageBarButton onClick={() => this.reloadPage()}>Refresh</MessageBarButton>
                </div>}>Applications are currently being processed - the list below may be incomplete.</MessageBar>;
        }
        */

        if (!this.props.isPartner)
            warning = <ClientViewWarning id={this.props.params.id} refreshFunc={()=>this.reloadGrid()} />;

        // this.generateExcelButton = <PrimaryButton id="generateButton" onClick={() => { this.GenerateXlsx(); }} style={{ marginTop: '1rem' }}>Generate .XLSX</PrimaryButton>;
        var generateExcelButton = <AutoDisableButton ref={this.excelButtonRef} label='Create Excel table' action={() => this.GenerateXlsx()} />;
        /*
        if (!window.inTeams)
            generateExcelButton = undefined;
        */
        var generateMandateButton = <AutoDisableButton defaultDisabled={true} ref={this.mandateButtonRef} label='Save and Create Mandate' action={() => this.GenerateMandate()} />;
        if (this.props.isPartner)
            generateMandateButton = undefined;

        var existingMandateList = undefined;
        var createAPAListButton = undefined;
        if (!this.props.isPartner) {
            createAPAListButton = <AutoDisableButton defaultDisabled={true} ref={this.apaButtonRef} label='Create list without mandate' action={() => this.GenerateAPAList()} />;
        }

        if (!this.props.isPartner && window.inTeams) {
            existingMandateList = <ExistingMandateList ref={this.mandateListRef} clientId={this.props.params.id} setCheckboxesFromMandate={(a) => this.setCheckboxesFromMandate(a)} />;
        }

        return (
            <div className="grid-container">
                {warning}

                <div>
                    <div>
                        <h2 style={{ position: 'relative' }}>
                            {this.props.isPartner ? 'Contact' : 'Client'}:&nbsp;
                            <span id="client-name-label" />
                            <DefaultButton onClick={e => this.copyLink()} style={{
                                marginLeft: '1em',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }}>Copy link</DefaultButton>
                            <input id="cb" type="text" style={{display: 'none'}}></input>
                            {backLink}
                        </h2>
                    </div>
                </div>

                <ClientViewExtraInfo ref={this.extraInfoRef} />

                <RepresentativeBreakdown ref={this.breakdownRef} />

                <UPCClientViewFilterControls ref={this.filterRef} gridRef={this.gridRef} isPartner={this.props.isPartner} toggleBreakdown={() => this.representativeBreakdownToggle()} />

                {existingMandateList}

                <div id="maingrid-container" className="ag-theme-alpine">
                    <AgGridReact
                        ref={this.gridRef}
                        gridOptions={this.gridOptions} />
                </div>


                <div style={{ display: 'flex', gap: '1em' }}>
                    <div style={{ marginTop: '1rem' }} >
                        {generateMandateButton}
                    </div>
                    <div style={{ marginTop: '1rem' }} >
                        {createAPAListButton}
                    </div>
                    <div style={{ marginTop: '1rem' }} >
                        {generateExcelButton}
                    </div>
                    <div style={{flexGrow: '1'}} />
                    <div style={{ marginTop: '1rem' }}>
                        <OptOutSummary ref={this.summaryRef} />
                    </div>
                </div>
                {historyPopup}
            </div>
        );
    }
}

export default withRouter(ClientView);
