import React, { Component } from 'react';
import { MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class ADACDemoView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }


    render() {
        return (
            <div style={{ display: 'flex', height: '100%', padding:'0', margin:'0' }}>
                <div style={{ flexGrow: 1, margin: '1em' }}>
                <form target="docframe" action="https://35.158.255.76:9980/browser/baa6eef/cool.html?WOPISrc=https://api.patdoc.net/api/wopi/files/123" encType="multipart/form-data" method="post">
                    <input name="access_token" value="test" type="hidden" />
                    <input type="submit" value="Load Collabora Online" />
                </form>
                </div>
                <div style={{flexGrow:2}}>
                <iframe name="docframe" width="100%" height="100%" />
                </div>
            </div>
        );
    }
}

export default withRouter(ADACDemoView);
