import React, { Component } from 'react';
import { MessageBar, MessageBarType, MessageBarButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class ClientViewWarning extends Component {

    constructor(props) {
        super(props);
        this.state = { clientStatus: undefined };
    }

    componentDidMount = () => {
        $.ajax({
            url: '/api/upc/clientstatus/' + this.props.id,
            type: "GET",
            success: (result) => {
                this.setState({ clientStatus: result });
            }
        });
    }

    reloadPage() {
        this.props.refreshFunc();
        $.ajax({
            url: '/api/upc/clientstatus/' + this.props.id,
            type: "GET",
            success: (result) => {
                this.setState({ clientStatus: result });
            }
        });
    }

    render() {
        var warning = undefined;
        if (this.state.clientStatus === "QUEUED") {
            warning = <MessageBar isMultiline={false} messageBarType={MessageBarType.warning} actions={
                <div>
                    <MessageBarButton onClick={() => this.reloadPage()}>Refresh</MessageBarButton>
                </div>}>This client is queued for processing - the application list will be available shortly.</MessageBar>;
        }
        else if (this.state.clientStatus === "PROCESSING") {
            warning = <MessageBar isMultiline={false} messageBarType={MessageBarType.warning} actions={
                <div>
                    <MessageBarButton onClick={() => this.reloadPage()}>Refresh</MessageBarButton>
                </div>}>Applications are currently being processed - the list below may be incomplete.</MessageBar>;
        }

        return warning;
    }
}

export default ClientViewWarning;
