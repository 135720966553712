import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class ReportIdRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        // var appNum = this.props.data['applicationNumber'];
        var reportUrl = 'https://api.patdoc.net/officeactionreport/' + this.props.data.id;

        return (
            <div>
                <a href={reportUrl} target='_blank'>{this.props.data.id}</a>
            </div>
        );
    }
}

export default ReportIdRenderer;