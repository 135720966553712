import React, { Component } from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './EmailConfirm.css';
import { withRouter } from '../WithRouter';
import $ from 'jquery';

class EmailConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {
    }

    render() {
        const search = this.props.location.search;
        const confirmCode = new URLSearchParams(search).get('confirmcode');
        $.ajax({
            url: '/api/license/confirm/' + confirmCode,
            type: "GET",
            success: function (result) {
                if (result === "OK") {
                    $("#messageTitle").text("Email confirmed");
                    $("#messageBody").text("Your email address has been confirmed and your PatentMaker license has been activated. Please return to the application to complete configuration and get started.");
                } else {
                    $("#messageTitle").text("Error");
                    $("#messageBody").text("Your email address could not be confirmed at this time. Note that this could indicate that it has already been confirmed.");
                }
            }, error: function (error) {
                $("#messageTitle").text("Error");
                $("#messageBody").text("Your email address could not be confirmed.");
            }
        });

        return (
            <div id='email-confirm-body'>
                <h2 id='messageTitle' />
                <span id='messageBody' />
            </div>
        );
    }
}

export default withRouter(EmailConfirm);
