import $ from 'jquery';
import React, { Component } from 'react';
import { DefaultButton } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import editIcon from './Edit.png';

class MasterClientConfig extends Component {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
            clientId: props.clientId
        };
    }

    componentDidMount = () => {
        const token = localStorage.getItem('patentsurveillance-jwt');

        window.addEventListener('clientchange',
            (evt) => {
                this.setState((prevState => {
                    return ({
                        ...prevState,
                        clientId: evt.detail
                    });
                }));

                this.loadClientConfig();
            });

        fetch('/api/patentsurveillance/clients',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            allClients: resp
                        });
                    });
                });
            }
        });

        this.loadClientConfig();
        // super.componentDidMount();
    }

    loadClientConfig() {
        const token = localStorage.getItem('patentsurveillance-jwt');
        fetch('/api/patentsurveillance/categories',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            categories: resp
                        });
                    });
                });
            }
            });

        fetch('/api/patentsurveillance/prompts',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            prompts: resp
                        });
                    });
                });
            }
        });
    }

    initEventHandlers() {
    }

    onPromptInput(evt, promptKey) {
        var newValue = evt.currentTarget.value;
        this.state.prompts.filter(p => p.promptKey === promptKey)[0].promptTemplate = newValue;
        this.setState((prevState) => {
            return ({
                ...prevState,
                prompts: this.state.prompts
            });
        });
    }

    savePromptTemplate(promptKey) {
        fetch('/api/patentsurveillance/prompt',
            {
                method: 'post',
                body: JSON.stringify({
                    promptKey: promptKey,
                    promptTemplate: this.state.prompts.filter(p => p.promptKey === promptKey)[0].promptTemplate
                }),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
            } else {
                alert('Save failed');
            }
        });
    }

    editCategory(categoryId) {
        var category = this.state.categories.filter(c => c.id === categoryId)[0];
        category.editMode = true;
        category.editName = category.name;
        this.setState((prevState) => {
            return ({
                ...prevState,
                categories: this.state.categories
            });
        });
    }

    onCategoryEditInput(evt, categoryId) {
        var category = this.state.categories.filter(c => c.id === categoryId)[0];
        category.editName = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                categories: this.state.categories
            });
        });
    }

    saveCategory(categoryId) {
        var category = this.state.categories.filter(c => c.id === categoryId)[0];
        category.editMode = false;

        fetch('/api/patentsurveillance/categories/' + categoryId + '/rename',
            {
                method: 'post',
                body: JSON.stringify(category.editName),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                category.name = category.editName;
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        categories: this.state.categories
                    });
                });
            } else {
                alert('Save failed');
            }
        });
    }

    deleteCategory(categoryId) {
        var category = this.state.categories.filter(c => c.id === categoryId)[0];
        if (window.confirm('Are you sure you want to delete the category ' + category.name + '?')) {
            fetch('/api/patentsurveillance/categories/' + categoryId + '/delete',
                {
                    method: 'post',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                    }
                }).then(response => {
                if (response.ok) {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            categories: this.state.categories.filter(c => c.id !== categoryId)
                        });
                    });
                } else {
                    alert('Save failed');
                }
            });
        }
    }

    createCategory() {
        fetch('/api/patentsurveillance/categories/create',
            {
                method: 'post',
                body: JSON.stringify('New category'),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    this.state.categories.push({
                        id: resp,
                        name: 'New category',
                        editName: 'New category',
                        editMode: true
                    });

                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            categories: this.state.categories
                        });
                    });
                });
            } else {
                alert('Create failed');
            }
        });
    }

    render() {
        this.initEventHandlers();

        if (this.state.allClients) {
            var clientName = this.state.allClients.filter(c => c.id === this.state.clientId)[0].name;
        }

        var categoryCells = [];
        if (this.state.categories) {
            this.state.categories.forEach(c => {
                var nameCell, modeCell;
                if (c.editMode) {
                    nameCell = <div><input style={{width: '12em'}} value={c.editName} onInput={(e) => this.onCategoryEditInput(e, c.id)}/></div>;
                    modeCell = <div style={{ margin: 'auto', color: 'green', cursor: 'pointer' }} onClick={() => this.saveCategory(c.id)} >✓</div>;
                } else {
                    nameCell = <div style={{marginLeft: '4px'}}>{c.name}</div>;
                    modeCell = <div style={{ margin: 'auto 0.5em auto 1em', cursor: 'pointer' }} onClick={() => this.editCategory(c.id)} ><img src={editIcon} style={{ width: '1em' }} /></div>;
                }
                categoryCells.push(<div style={{ width: '10em', height: '2em', display: 'flex', alignItems: 'center' }} >{nameCell}</div>);
                categoryCells.push(modeCell);
                categoryCells.push(<div style={{ margin: 'auto', color: 'red', cursor: 'pointer' }} onClick={() => this.deleteCategory(c.id)} >✗</div>);
                categoryCells.push(<div/>);
            });
        }

        var promptCells = [];
        if (this.state.prompts) {
            this.state.prompts.forEach(p => {
                promptCells.push(<div style={{ verticalAlign: 'top', paddingRight: '1em' }}>{p.promptKey}:</div>);
                promptCells.push(
                    <div><textarea style={{ width: '100%', height: '15em' }} value={p.promptTemplate} onInput={(e) =>
                        this.onPromptInput(e, p.promptKey)} /></div>);
                promptCells.push(
                    <div style={{ verticalAlign: 'top', paddingLeft: '1em' }}><DefaultButton onClick={() =>
                        this.savePromptTemplate(p.promptKey)}>Save</DefaultButton></div>);
            });
        }

        return (
            <div>
                <hr/>
                <h2>Client Setup</h2>
                <h3>Change settings for {clientName} or create new client</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', marginTop: '1em'}}>
                    <div>
                        <h4>Prompts</h4>
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto' }} >
                            {promptCells}
                        </div>
                    </div>
                    <div style={{marginLeft: '4em'}}>
                        <h4 >Categories</h4>
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto 1fr' }}>
                            {categoryCells}
                            <div style={{ cursor: 'pointer', marginLeft: '4px' }} onClick={() => this.createCategory()}>Create new category</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MasterClientConfig);
