import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import FilterControls from './FilterControls';

import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';
import DraftStatusRenderer from './CellRenderers/DraftStatusRenderer';


class ApplicationDraftsView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { reports: null };
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
        this.gridRef = React.createRef();
        this.columnDefs = [
            { field: 'id', sortable: true, width: 300 },
            { headerName: 'User Email', field: 'userEmail', sortable: true, width: 300 },
            { headerName: 'Queued Time', field: 'timestampQueuedText', sortable: true, width: 200 },
            { headerName: 'Completed Time', field: 'timestampCompletedText', sortable: true, width: 200 },
            { headerName: 'Figures?', field: 'figuresIncluded', sortable: true, width: 150 },
            { headerName: 'Status', cellRenderer: DraftStatusRenderer}
        ];

        this.gridOptions = {
            columnDefs: this.columnDefs,
            onGridReady: (event) => {
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
                this.gridRef.current.api.resetRowHeights();
            },
            onFilterChanged: () => {
            },
            suppressRowVirtualisation: true
        };
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    filterSelected(group, timeWindow) {
        var gridApi = this.gridOptions.api;
        if (group === undefined || timeWindow === undefined)
            return;

        fetch('/api/admin/applicationdrafts/' + group + '/' + timeWindow,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        gridApi.setRowData(resp);
                        gridApi.sizeColumnsToFit();
                    });
                }
            });
    }

    render() {

        return (
            <div className='main-content' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <FilterControls ref={this.filterRef} onChange={(g, w) => this.filterSelected(g, w)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <AgGridReact className="ag-theme-alpine"
                             ref={this.gridRef}
                             gridOptions={this.gridOptions} />
            </div>
        );
    }
}

export default withRouter(ApplicationDraftsView);
