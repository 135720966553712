import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';

// import './UsageView.css';
import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import TeamsComponent from '../../TeamsComponent';
import ExpiryDateRenderer from './CellRenderers/ExpiryDateRenderer';
import GPTBalanceRenderer from './CellRenderers/GPTBalanceRenderer';
import AutoExtendRenderer from './CellRenderers/AutoExtendRenderer';
import FeatureEnabledRenderer from './CellRenderers/FeatureEnabledRenderer';
import TextWithCopyOptionRenderer from './CellRenderers/TextWithCopyOptionRenderer';
import PrivateAzureRenderer from './CellRenderers/PrivateAzureRenderer';
import GPTModelRenderer from './CellRenderers/GPTModelRenderer';
import { Link, DefaultButton } from '@fluentui/react';
import FilterControls from '../GeneralUsage/FilterControls';
import ColumnSelector from './ColumnSelector';
import CreateLicensePopup from './CreateLicensePopup';

class LicensingView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { };
        this.debugUsername = 'iod@patentmaker.eu';

        this.gridRef = React.createRef();
        this.columnDefs = [
            { field: 'userEmail', filter: 'agTextColumnFilter', sortable: true, width: 300 },
            { field: 'name', filter: 'agTextColumnFilter', sortable: true, width: 150 },
            {
                field: 'licenseKey', filter: 'agTextColumnFilter', sortable: true, minWidth: 340, maxWidth: 340, hide: true, cellRenderer: TextWithCopyOptionRenderer, cellRendererParams:
                { fieldName: 'licenseKey' }
            },
            { field: 'company', filter: 'agTextColumnFilter', sortable: true, width: 150 },
            { field: 'expiry', sortable: true, minWidth: 190, maxWidth: 190, cellRenderer: ExpiryDateRenderer },
            { field: 'autoExtend', sortable: true, width: 150, cellRenderer: AutoExtendRenderer },
            {
                field: 'aiGroup', editable: true, sortable: true, width: 150, cellEditor: 'agSelectCellEditor', onCellValueChanged: (
                    e) => {
                        var headers = {};
                        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");
                        fetch('/api/admin/aigroup?email=' +
                            e.data.userEmail +
                            '&groupName=' + e.data.aiGroup,
                            { headers: headers, method: 'post' }).then((response) => {
                            return response.text();
                        }).then(respText => {
                        });
                    },
                cellEditorParams: {
                    values: ['', 'Prefer OpenAI', 'Prefer OpenAI (August 2024)', 'Prefer Azure', 'Prefer Claude', 'Azure only'],
                    valueListGap: 0
                }, headerName: 'AI Group' },
            {
                headerName: 'Use Azure MCA-PSA',
                field: 'privateAzure',
                sortable: true,
                cellRenderer: PrivateAzureRenderer,
                width: 180,
                suppressSizeToFit: true,
                onchange: () => {
                }
            },
            { field: 'gptBalance', sortable: true, width: 150, cellRenderer: GPTBalanceRenderer, headerName: 'GPT Balance' }
        ];

        this.gridOptions = {
            columnDefs: this.columnDefs,
            onGridReady: (event) => {
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
                this.gridRef.current.api.resetRowHeights();
            },
            onFilterChanged: () => {
            }
        };
    }

    teamsReady(myEmail) {
        window.myEmail = myEmail;
        var gridApi = this.gridOptions.api;
        fetch('/api/admin/licenses',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    gridApi.setRowData(resp);
                });
            }
            });

        fetch('/api/builds/featureswitches',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    var insertAt = 6;
                    resp.forEach(feature => {
                        var showColumn = false;
                        if (feature.id === 'CHATGPT' || feature.id === 'SUPERSHOT' || feature.id === 'PATENTDRAFTING' || feature.id === 'STANDARDMAPPING')
                            showColumn = true;
                        this.columnDefs.splice(insertAt, 0, {
                            field: 'feature' + feature.id,
                            headerName: feature.name,
                            sortable: true,
                            comparator: (vA, vB, nA, nB, isInverted) => {
                                var aInc = nA.data.featuresAvailable.includes(feature.id);
                                var bInc = nB.data.featuresAvailable.includes(feature.id);
                                if (aInc === bInc) return 0;
                                if (!aInc && bInc) return 1;
                                return -1;
                            },
                            hide: !showColumn,
                            width: 150,
                            cellRenderer: FeatureEnabledRenderer,
                            cellRendererParams: { feature: feature.id }
                        });
                        ++insertAt;
                    });

                    window.prepareColumnSelector(this.columnDefs);

                    this.gridOptions.api.setColumnDefs(this.columnDefs);
                    this.gridRef.current.api.sizeColumnsToFit();
                    this.gridRef.current.api.resetRowHeights();
                });
            }
        });
    }

    copyGrid() {
        var copyText = 'User email\tName\tLicense key\tCompany\tExpiry\tAuto Extend\tGPT Enabled\tAI Report\tAzure EPO Mirror\tSpeech Assistant\tGPT Model\tGPT Balance\r\n';
        this.gridOptions.api.forEachNodeAfterFilterAndSort(node => {
            var rowData = node.data;
            copyText += rowData.userEmail + '\t';
            copyText += (rowData.name ?? '') + '\t';
            copyText += (rowData.licenseKey ?? '') + '\t';
            copyText += (rowData.company ?? '') + '\t';
            copyText += (rowData.expiry ?? '') + '\t';
            copyText += (rowData.autoExtend ? 'Y' : 'N') + '\t';
            copyText += (rowData.featuresAvailable.includes('CHATGPT') ? 'Y' : 'N') + '\t';
            copyText += (rowData.featuresAvailable.includes('SUPERSHOT') ? 'Y' : 'N') + '\t';
            copyText += (rowData.featuresAvailable.includes('PDFMIRROR') ? 'Y' : 'N') + '\t';
            copyText += (rowData.featuresAvailable.includes('SPEECH') ? 'Y' : 'N') + '\t';
            copyText += (rowData.gptModelName ?? '') + '\t';
            copyText += (rowData.gptBalance ?? '');
            copyText += '\r\n';
        });
        this.copy(copyText);
    }

    copy(txt) {
        var cb = document.getElementById("cb");
        cb.value = txt;
        cb.style.display = 'block';
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
    }

    createLicense() {
        window.showCreateLicensePopup();
    }

    render() {
        if (window.inTeams) {
        }

        return (
            <div className='main-content' style={{ display: 'flex', flexDirection: 'column', margin: '2em' }}>
                <div id='display-name'></div>
                <div style={{ display: 'flex', marginBottom: '0.5em' }}>
                    <Link style={{ margin: 'auto' }} onClick={() => this.copyGrid()}>Copy</Link>
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                    <div style={{ flexGrow: 1 }} />
                    <DefaultButton style={{ margin: 'auto 1em'}} onClick={() => this.createLicense()}>Create License</DefaultButton>
                    <ColumnSelector ref={this.colSelectRef} style={{textAlign: 'right'}} gridRef={this.gridRef} />
                </div>
                <div id="maingrid-container" className="ag-theme-alpine" style={{height:'100%'}}>
                    <AgGridReact
                        ref={this.gridRef}
                        gridOptions={this.gridOptions} />
                </div>
                <div>
                </div>
                <CreateLicensePopup />
            </div>
        );
    }
}

export default withRouter(LicensingView);
