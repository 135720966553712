import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class FTOSearcPatentResult extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            patentNumber: '',
            status: '',
            message: '',
            claims: []
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    render() {
        this.initEventHandlers();

        var resultDetail = <div/>;
        if (this.props.resultData.status === 'error') {
            resultDetail = <div style={{ marginLeft: '2em', color:'#ff0000' }} >Ouch!</div>;
        }
        else if (this.props.resultData.status === 'ok') {
            var claims = [];
            this.props.resultData.claims.forEach(c => {
                claims.push(
                    <div style={{marginLeft: '2em', marginBottom: '1em' }}>
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td colSpan={2} style={{ textDecoration: 'underline' }}>
                                    Claim {c.claimNumber}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '40%' }}>{c.claimText}</td>
                                <td style={{ width: '60%' }}>{c.claimResult}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                );
            });
            resultDetail = <div>{claims}</div>;
        }

        return (
            <div style={{ background: '#bbbbff', marginBottom: '1em'}}>
                <div style={{fontWeight: 'bold'}}>{this.props.resultData.patentNumber}</div>
                {resultDetail}
            </div>
        );
    }
}

export default FTOSearcPatentResult;
