import React, { Component } from 'react';
import ReportSectionBase from './ReportSectionBase'
import CheckboxField from './FieldControls/CheckboxField';

class ClaritySection extends ReportSectionBase {
    constructor(props) {
        super(props,
            {},
            'Clarity',
            '/api/officeactionreport/' + props.reportkey + '/clarity',
            'No clarity objections were found in the examiner communication.',
            'The examiner communication is being checked for clarity objections. Please wait...');
    }

    dataLoaded(data) {
        if (!data) {
            this.pleaseWait();
        } else if (data.length === 0) {
            this.emptySection();
            this.stopPolling();
        } else {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    clarity: data
                });
            });
            this.dataReady();
            this.stopPolling();
        }
    }

    renderData() {
        var rows = [];
        this.state.clarity.forEach(c => {
            rows.push(
                <tr key={c.id}>
                    <td style={{ verticalAlign: 'top', padding: '0.25em' }}>{c.objectionText}</td>
                    <td style={{ verticalAlign: 'top', paddingLeft: '1.5em', paddingTop: '0.5em' }}>
                        <CheckboxField initialValue={c.showInReply}
                            saveUrl={'/api/officeactionreport/clarity/' + c.id + '/includeyn'} /></td>
                    <td style={{ verticalAlign: 'top', padding: '0.25em' }}>{c.responseText}</td>
                </tr>);
        });

        return (
            <table>
                <thead style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    <tr>
                        <td style={{ verticalAlign: 'top', padding: '0.25em' }}>Objections</td>
                        <td />
                        <td style={{ verticalAlign: 'top', padding: '0.25em' }}>Responses</td>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}

export default ClaritySection;