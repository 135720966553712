import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { useNavigate } from 'react-router-dom';
import AutoDisableButton from './AutoDisableButton';

class LoginView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.loginButtonRef = React.createRef();
        this.state = {
            errorMessage: undefined
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    quickLogin() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                errorMessage: undefined
            });
        });

        var email = $('#emailinput').val();
        var password = $('#passwordinput').val();

        fetch('/api/patentsurveillance/auth/login',
            {
                method: 'post',
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    localStorage.setItem('patentsurveillance-jwt', resp);
                    window.dispatchEvent(new Event('userupdate'));
                    this.props.navigate('/patentsurveillance/reports');
                });
            } else {
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        errorMessage: 'Authentication failed'
                    });
                });
                $('#passwordinput').val('');
                this.loginButtonRef.current.enableButton();
            }
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.quickLogin();
        }
    }

    render() {
        this.initEventHandlers();
        var errorDiv = undefined;
        if (this.state.errorMessage) {
            errorDiv = <div style={{ color: 'red' }}>{this.state.errorMessage}</div>;
        }

        return (
            <div style={{ height: '100%', padding: '2em' }}>
                <table style={{margin:'auto'}}>
                    <tbody>
                    <tr>
                        <td>Email:</td>
                            <td>
                                <input id='emailinput' style={{ margin: '0.5em', width: '15em' }} onKeyPress={(e) => this.handleKeyPress(e)} />
                            </td>
                    </tr>
                    <tr>
                        <td>Password:</td>
                            <td>
                                <input id='passwordinput' type='password' style={{ margin: '0.5em', width: '15em' }} onKeyPress={(e) => this.handleKeyPress(e)} />
                            </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{ margin: '1em', textAlign: 'center' }} >
                    <AutoDisableButton ref={this.loginButtonRef} label='Log in' action={() => this.quickLogin()} />
                </div>
                {errorDiv}
            </div>
        );
    }
}

export default withRouter(LoginView);
