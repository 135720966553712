import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import epoIcon from './EPO.png';

class ApplicationNumberRenderer extends Component {
    static displayName = ApplicationNumberRenderer.name;

    constructor(props) {
        super(props);
        this.state = { };
    }

    openHistory() {
        const showHistory = this.props.showHistory;
        $.ajax({
            url: '/api/upc/applicationsteps/' + this.props.data.id,
            success: function (result) {
                window.showHistoryDialog(result);
                /*
                var table = document.createElement('table');

                result.forEach(s => {
                    var stepRow = document.createElement('tr');
                    var dateCell = document.createElement('td');
                    stepRow.appendChild(dateCell);
                    dateCell.innerHTML = s.Timestamp.substring(8, 10) +
                        '.' +
                        s.Timestamp.substring(5, 7) +
                        '.' +
                        s.Timestamp.substring(0, 4);
                    var descCell = document.createElement('td');
                    stepRow.appendChild(descCell);
                    descCell.innerHTML = s.Description;
                    descCell.setAttribute('title', s.DateType);
                    table.appendChild(stepRow);
                });
                $("#history-dialog-content").empty();
                $("#history-dialog-content").append(table);
                window.showHistoryDialog(result);

    */
            }
        });
    }

    render() {
        var appNum = this.props.data['applicationNumber'];
        var icon = null;
        if (appNum !== "SELECT ALL") {
            var linkRef = this.props.data['applicationNumber'].replace(' ', '');
            var epoUrl = 'https://register.epo.org/application?number=' + linkRef + '&lng=en&tab=doclist';
            icon =
                <div className='hover-button' style={{ marginTop: '-0.3em' }}>
                    <a href={epoUrl} target='_blank'>
                        <img src={epoIcon} />
                    </a>
                </div>;
        }
        return (
            <div className='app-num-wrapper'>
                <div style={{ whiteSpace: 'pre-wrap', lineHeight: 'normal', marginTop: '0.5em' }}>
                    {appNum}
                </div>
                {icon}
            </div>
        );
    }
}

export default ApplicationNumberRenderer;
