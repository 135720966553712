import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import CreateNewIcon from './Icons/CreateNew.png';

class SingleResponseRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    newTemplate(rowData, responseType, german, sharePointUrl) {
        window.showNewTemplateDialog(rowData, responseType, german, sharePointUrl);
    }

    render() {
        var classname = this.props.clientTemplate ? 'template-client local-hover' : 'template-office local-hover';
        var label = this.props.german ? 'German' : this.props.responseType;

        return (
            <div className={classname}>
                <a href={this.props.sharePointUrl} target='_blank' style={{ verticalAlign: 'middle' }}>{label}</a>
                <div onClick={() => this.newTemplate(this.props.rowData, this.props.responseType, this.props.german, this.props.sharePointUrl)}>
                    <img src={CreateNewIcon} style={{ height: '1em', width: '1em', verticalAlign: 'middle' }} />
                </div>
            </div>
        );
    }
}

export default SingleResponseRenderer;
