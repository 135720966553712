import React, { Component } from 'react';
import { Link, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class OptOutSummary extends Component {

    constructor(props) {
        super(props);
        this.state = { optedOut: 0, total: 0 };
    }


    hereAreTheCounts(optedOut, total) {
        this.setState({ optedOut: optedOut, total: total });
    }

    render() {
        return <div>{this.state.optedOut} / {this.state.total} opted out</div>
    }
}

export default OptOutSummary;
