import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';

import { withRouter } from '../../WithRouter';
import TeamsComponent from '../../TeamsComponent';
import { Checkbox } from '@fluentui/react';
import { Link } from '@fluentui/react';


class BuildsView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = {builds: null, features: null, branches: null, selectedBranch: null, showFeatureSwitches: false};
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.loadBuilds();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    loadBuilds() {
        fetch('/api/builds',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            builds: resp
                        });
                    });
                });
            }
        });

        fetch('/api/builds/featureswitches',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            features: resp
                        });
                    });
                });
            }
        });

        var branches = [];
        // branches.push({ label: 'Stable (2.8)', repoBranch: '2.8.1', product: 'PatentMakerClient' });
        branches.push({ label: 'PatentMaker Client', repoBranch: 'main', product: 'PatentMakerClient' });
        branches.push({ label: 'PatentMaker Web Server', repoBranch: 'main', product: 'PatentMakerWeb' });
        this.setState((prevState) => {
            return ({
                ...prevState,
                branches: branches,
                selectedBranch: branches[0]
            });
        });
    }

    selectBranch(branch) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                showFeatureSwitches: false,
                selectedBranch: branch
            });
        });
    }

    showFeatureSwitches() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                showFeatureSwitches: true
            });
        });
    }

    approveBuild(build) {
        fetch('/api/builds/' + build.id + '/approvereject/approve',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                },
                method: 'post'
            }).then((response) => {
            build.approveReject = 'Approve';
            this.setState((prevState) => {
                return ({
                    ...prevState
                });
            });
        }).then(respText => {
        });
    }

    releaseBuildToBeta(build) {
        fetch('/api/builds/' + build.id + '/release/beta',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                },
                method: 'post'
            }).then((response) => {
            if (response.ok) {
                response.json().then(resp => {
                    build.betaReleaseTimestamp = resp;
                    this.setState((prevState) => {
                        return ({
                            ...prevState
                        });
                    });
                });
            }
        });
    }

    releaseBuildToStable(build) {
        fetch('/api/builds/' + build.id + '/release/stable',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                },
                method: 'post'
            }).then((response) => {
            if (response.ok) {
                response.json().then(resp => {
                    build.stableReleaseTimestamp = resp;
                    this.setState((prevState) => {
                        return ({
                            ...prevState
                        });
                    });
                });
            }
        });
    }

    rejectBuild(build) {
        fetch('/api/builds/' + build.id + '/approvereject/reject',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
                , method: 'post' }).then((response) => {
            build.approveReject = 'Reject';
            this.setState((prevState) => {
                return ({
                    ...prevState
                });
            });
        }).then(respText => {
        });
    }

    releaseFeatureToTest(feature, evt) {
        var newValue = evt.target.checked;
        fetch('/api/builds/featureswitches/' + feature.id + '/release/test/' + newValue,
            {
                method: 'post'
            }).then(response => {
            if (response.ok) {
                console.log('Updated tester status of ' + feature.id + ' to ' + newValue);
                feature.releaseToAllTestUsers = newValue;
                this.setState((prevState) => {
                    return ({
                        ...prevState
                    });
                });
            } else {
                alert('Something went wrong');
                console.log('Something went wrong');
            }
        });
    }

    releaseFeatureToBeta(feature, evt) {
        var newValue = evt.target.checked;
        fetch('/api/builds/featureswitches/' + feature.id + '/release/beta/' + newValue,
            {
                method: 'post'
            }).then(response => {
            if (response.ok) {
                console.log('Updated beta status of ' + feature.id + ' to ' + newValue);
                feature.releaseToAllBetaUsers = newValue;
                this.setState((prevState) => {
                    return ({
                        ...prevState
                    });
                });
            } else {
                alert('Something went wrong');
                console.log('Something went wrong');
            }
        });
    }

    releaseFeatureToStable(feature, evt) {
        var newValue = evt.target.checked;
        fetch('/api/builds/featureswitches/' + feature.id + '/release/stable/' + newValue,
            {
                method: 'post'
            }).then(response => {
            if (response.ok) {
                console.log('Updated stable status of ' + feature.id + ' to ' + newValue);
                feature.releaseToAllStableUsers = newValue;
                this.setState((prevState) => {
                    return ({
                        ...prevState
                    });
                });
            } else {
                alert('Something went wrong');
                console.log('Something went wrong');
            }
        });
    }

    render() {
        var branchOptions = [];
        if (this.state.branches) {
            this.state.branches.forEach(branch => {
                var fontWeight = 'normal';
                if (!this.state.showFeatureSwitches && this.state.selectedBranch === branch) {
                    fontWeight = 'bold';
                }

                branchOptions.push(
                    <td>
                    <span style={{
                        textDecoration: 'underline',
                        marginRight: '2em',
                        fontSize: '1.2em',
                        fontWeight: fontWeight,
                        cursor: 'pointer'
                    }} onClick={() => this.selectBranch(branch)}>{branch.label}</span>
                </td>
                );
            });
        }

        branchOptions.push(
            <td>
                <span style={{
                    textDecoration: 'underline',
                    marginLeft: '2em',
                    fontSize: '1.2em',
                    fontWeight: this.state.showFeatureSwitches ? 'bold' : 'normal',
                    cursor: 'pointer'
                }} onClick={() => this.showFeatureSwitches()}>Feature Switches</span>
            </td>);

        var body = undefined;
        if (!this.state.showFeatureSwitches && this.state.builds && this.state.selectedBranch) {
            var buildRows = [];
            var rowBackground = '#ddeeff';
            var firstPending = true;
            var firstPendingBeta = true;
            var firstPendingStable = true;

            var latestBetaId = undefined;
            var latestStableId = undefined;
            this.state.builds.forEach(build => {
                if (build.repoBranch === this.state.selectedBranch.repoBranch &&
                    build.product === this.state.selectedBranch.product) {
                    if (build.approveReject !== 'Reject') {
                        if (build.betaReleaseTimestamp)
                            latestBetaId = build.id;
                        if (build.stableReleaseTimestamp)
                            latestStableId = build.id;
                    }
                }
            });

            this.state.builds.forEach(build => {
                if (build.repoBranch === this.state.selectedBranch.repoBranch &&
                    build.product === this.state.selectedBranch.product) {

                    var comments = [];
                    if (build.comment) {
                        if (build.comment.indexOf('296') !== -1) {
                            console.log('!');
                        }
                        build.comment.split('\n').forEach(comment => {
                            var jiraMatch = comment.match(/^\s*((PM|DEV|DEV2)-\d+):\s*(.*)\s*$/);
                            if (jiraMatch) {
                                console.log('Match: ---' + comment + '---');
                                var jiraRef = jiraMatch[1];
                                var restOfComment = jiraMatch[3];
                                var jiraLink = 'https://patentmaker-team.atlassian.net/browse/' + jiraRef;
                                comments.push(<li><a href={jiraLink}>{jiraRef}</a>: {restOfComment}</li>);
                            } else {
                                console.log('Not match: ---' + comment + '---');
                                comments.push(<li>{comment}</li>);
                            }
                        });
                    }

                    var approveRejectTimestampString = undefined;
                    if (build.approveRejectTimestamp && build.approveRejectTimestamp.length >= 10) {
                        approveRejectTimestampString = build.approveRejectTimestamp.substring(8, 10) +
                            '.' +
                            build.approveRejectTimestamp.substring(5, 7) +
                            '.' +
                            build.approveRejectTimestamp.substring(0, 4);
                    }

                    var betaReleaseTimestampString = undefined;
                    if (build.betaReleaseTimestamp && build.betaReleaseTimestamp.length >= 10) {
                        betaReleaseTimestampString = build.betaReleaseTimestamp.substring(8, 10) +
                            '.' +
                            build.betaReleaseTimestamp.substring(5, 7) +
                            '.' +
                            build.betaReleaseTimestamp.substring(0, 4);
                    }

                    var stableReleaseTimestampString = undefined;
                    if (build.stableReleaseTimestamp && build.stableReleaseTimestamp.length >= 10) {
                        stableReleaseTimestampString = build.stableReleaseTimestamp.substring(8, 10) +
                            '.' +
                            build.stableReleaseTimestamp.substring(5, 7) +
                            '.' +
                            build.stableReleaseTimestamp.substring(0, 4);
                    }

                    var approveRejectCell = <td />
                    if (build.approveReject === 'Reject') {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: 'bold', color: '#800' }}>Rejected {approveRejectTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (stableReleaseTimestampString) {
                        var fontWeight = 'normal';
                        var fontStyle = 'italic';
                        if (build.id === latestStableId) {
                            fontWeight = 'bold';
                            fontStyle = undefined;
                        }
                        approveRejectCell = (
                                <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                    <table style={{ width: '100%' }}>
                                        <tr><td colSpan='2' style={{ fontWeight: fontWeight, fontStyle: fontStyle, color: '#080' }}>Stable {
                                            stableReleaseTimestampString}</td></tr>
                                    </table>
                                </td>
                            );
                    } else if (betaReleaseTimestampString && firstPendingStable) {
                        var fontWeight = 'normal';
                        var fontStyle = 'italic';
                        if (build.id === latestBetaId) {
                            fontWeight = 'bold';
                            fontStyle = undefined;
                        }
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: fontWeight, fontStyle: fontStyle, color: '#080' }}>Beta {betaReleaseTimestampString}</td></tr>
                                    <tr>
                                        <td style={{ width: '6em' }}><span style={{
                                            color: '#080',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => this.releaseBuildToStable(build)}>Release to Stable</span></td>
                                        <td style={{ width: '6em' }}><span style={{
                                            color: '#800',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => this.rejectBuild(build)}>Reject</span></td>
                                    </tr>
                                </table>
                            </td>
                        );
                        firstPendingStable = false;
                    } else if (betaReleaseTimestampString) {
                        var fontWeight = 'normal';
                        var fontStyle = 'italic';
                        if (build.id === latestBetaId) {
                            fontWeight = 'bold';
                            fontStyle = undefined;
                        }
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: fontWeight, fontStyle: fontStyle, color: '#080' }}>Beta {betaReleaseTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (firstPendingBeta) {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', padding: '0.5em 0 0.5em 1em', width: '20em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ width: '6em' }}><span style={{
                                            color: '#080',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => this.releaseBuildToBeta(build)}>Release to Beta</span></td>
                                        <td style={{ width: '6em' }}><span style={{
                                            color: '#800',
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                        }} onClick={() => this.rejectBuild(build)}>Reject</span></td>
                                    </tr>
                                </table>
                            </td>
                        );
                        firstPendingBeta = false;
                    }
                    /*
                    if (build.approveReject === 'Approve') {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: 'bold', color: '#080' }}>Approved {approveRejectTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (build.approveReject === 'Reject') {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', width: '12em', padding: '0.5em 0 0.5em 1em' }}>
                                <table style={{ width: '100%' }}>
                                    <tr><td colSpan='2' style={{ fontWeight: 'bold', color: '#800' }}>Rejected {approveRejectTimestampString}</td></tr>
                                </table>
                            </td>
                        );
                    } else if (build.approveReject === 'Pending' && firstPending) {
                        approveRejectCell = (
                            <td style={{ verticalAlign: 'top', padding: '0.5em 0 0.5em 1em' }}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ width: '6em' }}><span style={{
                                        color: '#080',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }} onClick={() => this.approveBuild(build)}>Approve</span></td>
                                    <td style={{ width: '6em' }}><span style={{
                                        color: '#800',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }} onClick={() => this.rejectBuild(build)}>Reject</span></td>
                                </tr>
                            </table>
                        </td>
                        );
                        firstPending = false;
                    }
                    */

                    var dateString = '';
                    if (build.timestamp && build.timestamp.length >= 10) {
                        dateString = build.timestamp.substring(8, 10) +
                            '.' +
                            build.timestamp.substring(5, 7) +
                            '.' +
                            build.timestamp.substring(0, 4);
                    }

                    var activityList = [];
                    if (build.activityInfo) {
                        build.activityInfo.forEach(activity => {
                            activityList.push(<li>{activity}</li>);
                        });
                    }

                    // "unshift" to add at start/top - we want newest first (but want to parse bottom-up to easily identify
                    // the first unapproved build)
                    buildRows.unshift(
                        <tr style={{ borderBottom: 'solid 1px #999' }}>
                            <td style={{ verticalAlign: 'top', width: '8em', padding: '0.5em 0' }}>{build.version}</td>
                            <td style={{ verticalAlign: 'top', width: '10em', padding: '0.5em 0 0.5em 1em' }}>{dateString}</td>
                            <td style={{ verticalAlign: 'top', padding: '0.5em 0 0.5em 1em' }}><ul style={{ margin: '0 0 0 -0.75em'}}>{comments}</ul></td>
                            {approveRejectCell}
                            <td style={{ verticalAlign: 'top', fontStyle: 'italic', width: '16em', padding: '0.5em 0 0.5em 0' }}><ul style={{ listStyleType: 'none', marginLeft: '-1em' }}>{activityList}</ul></td>
                    </tr>);

                    if (rowBackground === '#ddeeff')
                        rowBackground = '#eeffff';
                    else
                        rowBackground = '#ddeeff';
                }
            });

            body = (
                <table style={{ margin: '1em' }}>
                    <thead>
                        <tr>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Version</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Date</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Comments</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Release</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', paddingLeft: '1em' }}>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {buildRows}
                    </tbody>
                </table>
            );
        }
        else if (this.state.showFeatureSwitches) {
            var featureRows = [];
            if (this.state.features) {
                this.state.features.forEach(feature => {
                    if (feature.releaseLevelSwitch) {
                        featureRows.push(
                            <tr>
                                <td style={{ verticalAlign: 'top' }}>{feature.name}</td>
                                <td style={{ verticalAlign: 'top' }}>{feature.id}</td>
                                <td style={{ verticalAlign: 'top', fontStyle: 'italic' }}>{feature.description}</td>
                                <td style={{ verticalAlign: 'top', marginTop: '0.3em' }}><Checkbox checked={feature.releaseToAllTestUsers} onChange={(e) => this.releaseFeatureToTest(feature, e)} /></td>
                                <td style={{ verticalAlign: 'top', marginTop: '0.3em' }}><Checkbox checked={feature.releaseToAllBetaUsers} onChange={(e) => this.releaseFeatureToBeta(feature, e)} /></td>
                                <td style={{ verticalAlign: 'top', marginTop: '0.3em' }}><Checkbox checked={feature.releaseToAllStableUsers} onChange={(e) => this.releaseFeatureToStable(feature, e)} /></td>
                            </tr>);
                    }
                });
            }

            body = (
                <table style={{ margin: '-1em 0 0 -1em', borderSpacing: '2em 1em', borderCollapse: 'separate' }}>
                    <thead>
                        <tr>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Feature</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Id</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Description</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', width: '6em' }}>Test</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', width: '6em' }}>Beta</td>
                            <td style={{ fontWeight: 'bold', textDecoration: 'underline', width: '6em' }}>Stable</td>
                        </tr>
                    </thead>
                    <tbody>
                        {featureRows}
                    </tbody>
                </table>
            );
        }

        return (
            <div className='main-content' style={{ height: '100%' }}>
                <table style={{ margin: '1em' }}>
                    <tbody>
                        <tr>
                            {branchOptions}
                        </tr>
                    </tbody>
                </table>
                {body}
            </div>
        );
    }
}

export default withRouter(BuildsView);
