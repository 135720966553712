import React, { Component } from 'react';
import { Link, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class ClientViewExtraInfo extends Component {

    constructor(props) {
        super(props);
        this.state = { extraInfo: undefined, showFullExtraInfo: true };
    }

    setExtraInfo(info) {
        var showFull = true;
        if (info.length > 300)
            showFull = false;

        this.setState({
            extraInfo: info,
            showFullExtraInfo: showFull
        });
    }

    showAll() {
        this.setState({
            extraInfo: this.state.extraInfo,
            showFullExtraInfo: true
        });
    }

    hideAll() {
        this.setState({
            extraInfo: this.state.extraInfo,
            showFullExtraInfo: false
        });
    }

    render() {
        var extraInfo = undefined;
        if (this.state.extraInfo) {
            if (this.state.showFullExtraInfo) {
                if (this.state.extraInfo.length > 300) {
                    extraInfo = <span>{this.state.extraInfo} <Link onClick={() => { this.hideAll(); }}>Hide</Link></span>;
                } else {
                    extraInfo = <span>{this.state.extraInfo}</span>;
                }
            } else {
                var truncated = this.state.extraInfo.substring(0, 200);
                extraInfo = <span>{truncated}... <Link onClick={() => { this.showAll(); } }>Show all</Link></span>
            }
        }

        return (
            <div>
                {extraInfo}
            </div>
        );
    }
}

export default ClientViewExtraInfo;
