import React from 'react';
import TeamsComponent from '../TeamsComponent';
import { PrimaryButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './UPCContent.css';
import './ClientSelector.css';
import { withRouter } from '../WithRouter';
import SuggestedPartnerList from './SuggestedPartnerList';

class PartnerSelector extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { myEmail: undefined };
        this.suggestedPartnerListRef = React.createRef();
        this.debugUsername = 'hofmann@boehmert.org'
        this.debugDisplayName = 'Matthias Hofmann';
        //this.debugUsername = 'donnell@boehmert.org'
        //this.debugDisplayName = 'Ian O\'Donnell';
        //this.debugUsername = 'donnell@boehmert.org';
        //this.debugDisplayName = 'Ian O\'Donnell';
        //this.debugUsername = 'engelhard@boehmert.org';
    }


    openPartner() {
        var partnerName = $("#partnerSearchView").val();
        this.openPartnerByName(partnerName);
    }

    addPartner() {
        var myEmail = this.state.myEmail;
        var clientName = $("#partnerSearchAdd").val();
        $.ajax({
            url: "/api/upc/partnerid?partnerName=" + encodeURIComponent(clientName),
            type: "GET",
            contentType: "application/json; charset=utf-8",
            success: (clientId) => {
                $.ajax({
                    url: "/api/upc/attorneyPartners?partnerId=" + clientId + "&attorneyEmail=" + myEmail,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    success: () => {
                        this.getPartnerList(myEmail);
                        $("#partnerSearchAdd").val('');
                    }
                });
            }
        });
    }


    openPartnerByName(clientName) {
        var nav = this.props.navigate;
        $.ajax({
            url: "/api/upc/partnerid?partnerName=" + encodeURIComponent(clientName),
            type: "GET",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                var clientUrl = result;
                nav(clientUrl);
            }
        });
    }

    getPartnerList(attorneyEmail) {
        var partnerListComponent = this.suggestedPartnerListRef.current;

        $.ajax({
            url: '/api/upc/attorneypartners?attorneyEmail=' + attorneyEmail,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            success: (partnerList) => {
                partnerListComponent.setPartners(partnerList, attorneyEmail);
            }
        });
    }

    teamsReady(myEmail, myName) {
        $.ajax({
            url: '/api/upc/secretaryinfo?myEmail=' + myEmail,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            success: (secretaryInfo) => {
                var attorneyEmail;
                if (secretaryInfo?.attorneyEmail) {
                    $("#display-name").text('Welcome, ' + myName + ', working for ' + secretaryInfo.attorneyName);
                    attorneyEmail = secretaryInfo.attorneyEmail;
                } else {
                    $("#display-name").text('Welcome, ' + myName);
                    attorneyEmail = myEmail;
                }
                this.setState({ myEmail: attorneyEmail });
                this.getPartnerList(attorneyEmail);
            }
        });
    }

    componentDidMount() {
        super.componentDidMount();

        $("#partnerSearchView").autocomplete({
            minLength: 2,
            source: function (request, response) {
                $.ajax({
                    dataType: 'json',
                    url: '/api/upc/partnerfilter',
                    data: request,
                    headers: { Authorization: 'Bearer ' + localStorage.getItem("bearerToken") },
                    success: function (data, status, xhr) {
                        response(data);
                    }
                });
            }
        });

        $("#partnerSearchAdd").autocomplete({
            minLength: 2,
            source: function (request, response) {
                $.ajax({
                    dataType: 'json',
                    url: '/api/upc/partnerfilter',
                    data: request,
                    headers: { Authorization: 'Bearer ' + localStorage.getItem("bearerToken") },
                    success: function (data, status, xhr) {
                        response(data);
                    }
                });
            }
        });
    }

    render() {
        var clientLink = (<span>&nbsp;-&nbsp;<a href='/upcoptout?teams=y'>View clients</a></span>);

        return (
            <div className="client-selector" id="main-content">
                <div id='greeting'>
                    <span id="display-name" />
                    {clientLink}
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>Select contact to view:</td>
                            <td>
                                <input id="partnerSearchView" style={{ width: '300px', fontSize: '16px', padding: '5px' }} />
                            </td>
                            <td>
                                <PrimaryButton id="viewClientButton" onClick={() => this.openPartner()}>View</PrimaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id='suggested-client-list'>
                    <SuggestedPartnerList ref={this.suggestedPartnerListRef} navigate={this.props.navigate} openClientByName={(clientName) => this.openClientByName(clientName)} />
                </div>
                <table style={{ marginTop: '2em' }}>
                    <tbody>
                        <tr>
                            <td>Select contact to claim responsibility for:</td>
                            <td>
                                <input id="partnerSearchAdd" style={{ width: '300px', fontSize: '16px', padding: '5px' }} />
                            </td>
                            <td>
                                <PrimaryButton id="addClientButton" onClick={() => this.addPartner()}>Claim</PrimaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(PartnerSelector);
