import React, { Component } from 'react';
import { Checkbox, Panel, ActionButton } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';

class TemplateGroupSelector extends Component {

    constructor(props) {
        super(props);
        this.groups = [];
        var settings = {};
        this.state = {
            gridRef: props.gridRef,
            selectedGroups: settings
        };

        window.getActiveGroups = () => {
            var active = [];
            for (const group in this.state.selectedGroups) {
                if (this.state.selectedGroups[group])
                    active.push(group);
            }
            return active;
        };
    }

    componentDidMount() {
        this.fetchDataWhenReady();
    }

    fetchDataWhenReady() {
        if (window.teamsAuthenticated) {
            fetch('/api/templates/groups', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
                return response.json();
            }).then(data => {
                var selectedGroups = this.state.selectedGroups;

                data.forEach(group => {
                    this.groups.push(group);
                    selectedGroups[group] = false;
                });

                this.setState({
                    isOpen: this.state.isOpen,
                    gridRef: this.state.gridRef,
                    selectedGroups: selectedGroups
                });

                this.updateFilter();
            });
        } else {
            console.log('Waiting for teams authentication...');
            window.setTimeout(() => { this.fetchDataWhenReady(); }, 500);
        }
    }

    open = () => {
        this.setState(
            {
                isOpen: true,
                gridRef: this.state.gridRef,
                selectedGroups: this.state.selectedGroups
            });
    };

    close = () => {
        this.setState({
            isOpen: false,
            gridRef: this.state.gridRef,
            selectedGroups: this.state.selectedGroups
        });
    }

    toggleGroup(groupId, showGroup) {
        var selectedGroups = this.state.selectedGroups;
        selectedGroups[groupId] = showGroup;
        this.setState({
            isOpen: this.state.isOpen,
            gridRef: this.state.gridRef,
            selectedGroups: selectedGroups
        });

        this.updateFilter();
    }

    updateFilter() {
        var selectedGroups = this.state.selectedGroups;

        var anySelected = false;
        for (const item in selectedGroups)
            anySelected |= selectedGroups[item];
        //selectedGroups.forEach(g => anySelected |= selectedGroups)

        this.state.gridRef.current.api.forEachNode(n => {
            var data = n.data;
            if (data.group === '') {
                data.groupFilter = 'Y';
            }
            else if (data.group === window.teamsEmail) {
                data.groupFilter = 'Y';
                anySelected = true;
            }
            else {
                data.groupFilter = selectedGroups[data.group] ? 'Y' : 'N';
            }

            n.setData(data);
        });

        this.state.gridRef.current.columnApi.applyColumnState({ state: [{ colId: 'groupLabel', hide: !anySelected}] });

        this.state.gridRef.current.api.onFilterChanged();

        this.state.gridRef.current.api.sizeColumnsToFit();
    }

    render() {
        var iconProps = { iconName: 'TripleColumn' };

        var rows = [];
        for (const group in this.state.selectedGroups) {
            rows.push(
                <tr>
                    <td>
                        <Checkbox className='template-group-checkbox' id={group} label={group} onChange={e => {
                            this.toggleGroup(group, e.target.checked);
                        }} />
                    </td>
                </tr>
            );
        }

        return (
            <div id="column-selector-option" className="filter-control" onClick={this.open}>
                <ActionButton id="column-selector-button" iconProps={iconProps}>Show&nbsp;group&nbsp;templates</ActionButton>

                <Panel
                    isOpen={this.state.isOpen}
                    isLightDismiss
                    isHiddenOnDismiss={true}
                    onDismiss={this.close}
                    headerText='Template groups'>
                    <table>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </Panel>
            </div>
        );
    }
}

export default withRouter(TemplateGroupSelector);
