import React, { Component } from 'react';
import './Header.css';
import './PatentSurveillance.css';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import logo from './Images/Boehmert.png';
import logoutIcon from './Images/Logout.png';
import settingsIcon from './Images/Settings.png';
import { withRouter } from '../WithRouter';

class PatentSurveillanceHeader extends Component {
    static displayName = PatentSurveillanceHeader.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    logOut() {
        localStorage.setItem('patentsurveillance-jwt', undefined);

        this.setState((prevState) => {
            return ({
                ...prevState,
                userInfo: undefined,
                authenticated: false
            });
        });

        this.props.navigate('/patentsurveillance');
    }

    openSettings() {
        this.props.navigate('/patentsurveillance/settings');
    }

    toHome() {
        this.props.navigate('/patentsurveillance/reports');
    }

    componentDidMount() {
        this.refreshUser();

        window.addEventListener('userupdate',
            () => {
                this.refreshUser();
            });
    }

    refreshUser() {
        const token = localStorage.getItem('patentsurveillance-jwt');
        if (token) {
            fetch('/api/patentsurveillance/auth/validate',
                {
                    method: 'get',
                    headers:
                    {
                        'jwt-token': token
                    }
                }).then(response => {
                if (response.ok) {
                    response.json().then((resp) => {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                userInfo: resp,
                                authenticated: true
                            });
                        });

                        if (resp.isSuperAdmin) {
                            fetch('/api/patentsurveillance/clients',
                                {
                                    method: 'get',
                                    headers:
                                    {
                                        'jwt-token': token
                                    }
                                }).then(response => {
                                if (response.ok) {
                                    response.json().then((resp) => {
                                        this.setState((prevState) => {
                                            return ({
                                                ...prevState,
                                                allClients: resp
                                            });
                                        });
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    onClientChange(evt, option) {
        const token = localStorage.getItem('patentsurveillance-jwt');
        fetch('/api/patentsurveillance/selectclient/' + option.key,
            {
                method: 'post',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
            if (response.ok) {
                window.dispatchEvent(new CustomEvent('clientchange', { detail: option.key }));
                this.state.userInfo.clientId = option.key;
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        userInfo: this.state.userInfo
                    });
                });

                if (this.props.location.pathname.indexOf('/patentsurveillance/report/') === 0)
                    this.toHome();
            }
        });
    }

    render() {
        
        var userHeader = undefined;
        if (this.state.userInfo?.name) {
            var userInfoParts = [];
            if (this.state.userInfo.isSuperAdmin) {
                if (this.state.allClients) {
                    var options = [];
                    this.state.allClients.forEach(c => {
                        options.push({ key: c.id, text: c.name });
                    });
                    userInfoParts.push(<div><Dropdown options={options} defaultSelectedKey={this.state.userInfo.clientId} onChange={(e,o) => this.onClientChange(e,o)} style={{ width: '20em' }} /></div>);
                }
            }
            userInfoParts.push(<div id='settingsLink'><img src={settingsIcon} style={{ width: '2em', height: '2em' }} onClick={() => this.openSettings()} /></div>);
            userInfoParts.push(<div id='logOutLink' onClick={() => this.logOut()}><img src={logoutIcon} style={{width: '2em', height: '2em'}} /></div>);
            userHeader = <div className='userinfo'>{userInfoParts}</div>;
        }

        return (
            <header>
                <div className="header header-patentsurveillance">
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-0.25em', marginBottom: '-0.5em' }} >
                        <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', cursor: 'pointer' }} onClick={() => this.toHome()} >
                            <div>
                                <img src={logo} style={{ height: '1.5em', marginRight: '0.5em' }} onClick={() => this.toHome()} />
                            </div>
                            <div>
                                BOEHMERT & BOEHMERT
                            </div>
                        </div>
                        <div style={{ fontSize: '0.75em', textAlign: 'center', fontWeight: 'normal', marginTop: '-0.25em' }}>
                            <span style={{ cursor: 'pointer' }} onClick={() => this.toHome()}>
                                Patent Surveillance
                            </span>
                        </div>
                        {userHeader}
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(PatentSurveillanceHeader);
