import React, { Component } from 'react';
import './Header.css';
import './PatDoc.css';
import logo from './Images/Boehmert.png';

export class PatDocTemplatesHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <div className="header header-patdoc">
                    <div>
                        PatDoc Templates
                    </div>
                    <img className="logo" src={logo} />
                </div>
            </header>
        );
    }
}
