import $ from 'jquery';
import React, { Component } from 'react';
import ReportSectionBase from './ReportSectionBase'
import TextField from './FieldControls/TextField'
import FeatureDisclosedField from './FieldControls/FeatureDisclosedField'

class NoveltySection extends ReportSectionBase {
    constructor(props) {
        super(props, {}, 'Novelty', '/api/officeactionreport/' + props.reportkey + '/novelty');
    }

    dataLoaded(data) {
        if (data && data.novelty && data.novelty.length > 0 && data.priorArt && data.priorArt.length > 0) {
            var activeIndex = this.state.activeIndex;
            if (activeIndex === undefined) {
                activeIndex = data.priorArt.findIndex(p => p.status === 'R');
                if (activeIndex === -1)
                    activeIndex = undefined;
            }
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    priorArt: data.priorArt,
                    novelty: data.novelty,
                    activeIndex: activeIndex
                });
            });
            this.dataReady();

            if(!data.priorArt.some(p => p.status === 'P'))
                this.stopPolling();
        } else {
            this.pleaseWait();
        }
    }

    myclick() {
        $("#gg").css('transition', '1000ms');
        $("#gg").css('gridTemplateColumns', '10em 10em 10em 10em 10em 10em 10em 10em 10em ');
    }

    getColumnTemplate(activeColumn) {
        var template = '1fr';
        for (var c = 0; c < this.state.priorArt.length; ++c) {
            template += ' 3em';
            template += (c == activeColumn ?? this.state.activeIndex) ? ' 1fr' : ' 0fr';
        }

        return template;
    }

    showPriorArt(priorArtNum) {
        var opacityTransition = 250;
        var columnTransition = 400;

        if (this.state.priorArt[priorArtNum].status !== 'R')
            return;
        if (this.state.activeIndex === priorArtNum)
            return;

        for (var p = 0; p < this.state.priorArt.length; ++p) {
            $('.reason-' + p).css('transition', opacityTransition + 'ms');
            $('.reason-' + p).css('opacity', 0);
        }

        setTimeout(() => {
            for (var p = 0; p < this.state.priorArt.length; ++p) {
                $('.reason-' + p).css('display', 'none');
            }
            $('#novelty-grid').css('transition', columnTransition + 'ms');
            $('#novelty-grid').css('gridTemplateColumns', this.getColumnTemplate(priorArtNum));
            setTimeout(() => {
                $('.reason-' + priorArtNum).css('display', 'block');
                $('.reason-' + p).css('transition', opacityTransition + 'ms');
                setTimeout(() => {
                        $('.reason-' + priorArtNum).css('opacity', 1);
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                activeIndex: priorArtNum
                            });
                        });
                    },
                    50);
            }, columnTransition);
        }, opacityTransition);
    }

    getHeaderCells(keySuffix) {
        var headerCells = [];
        headerCells.push(<div key={'cornercell-' + keySuffix} />);
        var aNum = 0;
        this.state.novelty[0].features[0].analysis.forEach(result => {
            var priorArtNum = aNum;
            headerCells.push(<div key={'header-' + aNum + '-' + keySuffix} style = {{
                marginLeft: '2em',
                marginRight: '-0.5em',
                marginBottom: '0.5em',
                fontWeight: 'bold',
                gridColumn: 'span 2',
                cursor: 'pointer',
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textDecorationColor: '#2e3688'
            }} onClick = {() => this.showPriorArt(priorArtNum)}> { result.priorArtLabel }</div >);
            ++aNum;
        });

        return headerCells;
    }

    renderData() {
        var cells = [];

        // var firstFeature = true;
        var claimSpan = this.state.priorArt.length * 2 + 1;

        this.state.novelty.forEach(claim => {
            var headerCells = this.getHeaderCells(claim.claimNo);
            cells.push(<div key={'claim-'+claim.claimNo} style={{ gridColumn: 'span ' + claimSpan, background: '#def', boxShadow:'2px 2px 4px #ace', padding: '0.25em 0.5em', marginBottom: '0.5em', marginRight: '-0.5em' }}>{claim.claimNo}. {claim.claimText}</div>);
            cells.push(...headerCells);
            var firstFeature = true;
            claim.features.forEach(feature => {
                cells.push(<div key={'featuretext-' + feature.id} style={{ height:'9em'}}>{feature.text}</div>);
                var aNum = 0;
                feature.analysis.forEach(result => {
                    if (this.state.priorArt[aNum].status === 'R') {
                        cells.push(<div key={'analysis-' + feature.id + '-' + result.priorArtLabel} style={{ marginLeft: '2em' }}><FeatureDisclosedField aiOpinion={result.aiDisclosed} judgedCorrect={result.judgedCorrect} featureId={result.featureId}/></div>);
                    }  else if (firstFeature && this.state.priorArt[aNum].status === 'P')
                        cells.push(<div key={'analysis-' + feature.id + '-' + result.priorArtLabel} style={{ marginLeft: '2em' }}>
                                       <div class="lds-miniring">
                                           <div></div><div></div><div></div><div></div>
                                       </div>

                        </div>);
                    else
                        cells.push(<div key={'analysis-' + feature.id + '-' + result.priorArtLabel} style={{ marginLeft: '2em' }}></div>);

                    var myDisplay = (aNum === this.state.activeIndex) ? 'block' : 'none';
                    // <textarea className={'reason-' + aNum} style={{ width: '100%', marginBottom: '0.5em', marginLeft: '0.5em', display: myDisplay }} value={result.analysisText} />

                    /*
                     *                                 

                     */
                    if (this.state.priorArt[aNum].status === 'R') {
                        cells.push(
                            <div key={'analysistext-' + feature.id + '-' + result.priorArtLabel}>
                                <TextField className={'reason-' + aNum} style={{
                                    width: '100%',
                                    height: '8em',
                                    marginBottom: '0.5em',
                                    marginLeft: '0.5em',
                                    border: '#bcc solid 1px',
                                    resize: 'none',
                                display: myDisplay
                            }} initialValue={result.analysisText} saveUrl={
                                        '/api/officeactionreport/featureanalysis/' + result.featureId + '/text'} />
                            </div>
                        );
                    } else {
                        cells.push(
                            <div key={'analysistext-' + feature.id + '-' + result.priorArtLabel}>
                                <div className={'reason-' + aNum} style={{
                                    marginBottom: '0.5em',
                                    marginLeft: '0.5em',
                                    display: myDisplay
                                }} value={result.analysisText} />
                            </div>);
                    }
                    ++aNum;
                });

                firstFeature = false;
            });
        });

        return (
            <div id='novelty-grid' style={{ display: 'grid', gridTemplateColumns: this.getColumnTemplate(this.state.activeIndex), marginRight: '0.5em' }}>
                {cells}
            </div>
        );
    }
}

export default NoveltySection;