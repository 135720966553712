import React, { Component } from 'react';
import ReportSectionBase from './ReportSectionBase'
import CheckboxField from './FieldControls/CheckboxField';

class AmendmentsSection extends ReportSectionBase {
    constructor(props) {
        super(props,
            {},
            'Amendments',
            '/api/officeactionreport/' + props.reportkey + '/amendments',
            'No amendments were found in the claims document.',
            'The claims document is being checked for recent amendments. Please wait...');
    }

    dataLoaded(data) {
        if (!data) {
            this.pleaseWait();
        } else if (data.length === 0) {
            this.emptySection();
            this.stopPolling();
        } else {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    amendments: data
                });
            });
            this.dataReady();
            this.stopPolling();
        }
    }

    renderData() {
        var rows = [];
        this.state.amendments.forEach(a => {
            rows.push(
                <tr key={a.id}>
                    <td style={{ verticalAlign: 'top', paddingTop: '0.4em' }}><CheckboxField initialValue={a.showInReply}
                        saveUrl={'/api/officeactionreport/amendment/' + a.id + '/includeyn'} /></td>
                    <td style={{ verticalAlign: 'top', padding: '0.25em' }}>{a.description}</td>
                </tr>);
        });

        return (
            <table>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}

export default AmendmentsSection;