import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { Navigate, useLocation } from 'react-router-dom';
import PatentSurveillanceComponent from '../PatentSurveillanceComponent';
import ReportListEntry from './ReportListEntry';

class ReportListMonth extends Component {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
            label: props.month.label,
            reports: props.month.reports
        };
        console.log('Month constructed with ' + this.state.reports.length + ' reports');
    }

    componentDidMount = () => {
        //super.componentDidMount();
    }

    initEventHandlers() {
    }

    render() {
        this.initEventHandlers();

        var entries = [];
        if (this.state.reports) {
            this.state.reports.forEach(report => {
                var entryKey = this.props.month.monthDateText + '~' + report.label;
                entries.push(<ReportListEntry key={entryKey} monthDateText={this.props.month.monthDateText} report={report} isSuperAdmin={this.props.isSuperAdmin} />);
            });
        }
        return (
            <div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{fontSize: '2em'}}>{this.state.label}</div>
                    <div style={{height: '0.1em', background: 'black', flexGrow: 1, margin: 'auto 0 auto 1em'}}></div>
                </div>
                <div style={{margin: '1em 2em'}}>
                    {entries}
                </div>
            </div>
        );
    }
}

export default withRouter(ReportListMonth);
