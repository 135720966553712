import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import patDocIcon from './PatDoc.png';

class BoehmertCaseNumberRenderer extends Component {
    static displayName = BoehmertCaseNumberRenderer.name;

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        var appNum = this.props.data['applicationNumber'];
        var caseNum = this.props.data['boehmertCaseNumber'];
        var icon = null;
        /* Custom URIs don't work in Teams - commenting out for now
        if (appNum !== "SELECT ALL" && window.inTeams && caseNum) {
            var patDocUrl = 'patentmaker://' + caseNum;
            icon =
                <div className='hover-button' style={{marginTop: '-0.3em'}}>
                    <a href={patDocUrl} target='_blank'>
                        <img src={patDocIcon} />
                    </a>
                </div>;
        }
        */
        return (
            <div className='app-num-wrapper'>
                <div style={{ whiteSpace: 'pre-wrap', lineHeight: 'normal', marginTop: '0.5em' }}>
                    {caseNum}
                </div>
                {icon}
            </div>
        );
    }
}

export default BoehmertCaseNumberRenderer;
