import React, { Component } from 'react';
import './Header.css';
import './PatentMaker.css';
import logo from './Images/PatentMaker.png';

export class PMAdminHeader extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <div className="header header-patentmaker">
                    <div>
                        PatentMaker Admin Tool
                    </div>
                    <img className="logo" src={logo} />
                </div>
            </header>
        );
    }
}
