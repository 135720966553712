import React, { Component } from 'react';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class NoveltyReportHeader extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            thisCase: undefined
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadCase(thisCase) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                thisCase: thisCase
            });
        });
    }

    render() {
        this.initEventHandlers();

        return (
            <div style={{ height: 'auto', padding: '0.5em', display: 'flex', flexDirection: 'row', fontSize: 22, fontWeight: 'bold' }}>
                <span style={{ margin: 'auto 0' }}>Case Number:</span>
                <span style={{ margin: 'auto 0 auto 0.4em' }}>{this.props.report.caseNo}</span>
                <span style={{ margin: 'auto 0 auto 2em' }}>Application Number:</span>
                <span style={{ margin: 'auto 0 auto 0.4em' }}>{this.props.report.applicationNo}</span>
                <span style={{ margin: 'auto 0 auto 2em' }}>Prior Art:</span>
                <span style={{ margin: 'auto 0 auto 0.4em' }}>{this.props.report.citationReference}</span>
                <div style={{ flexGrow: 1 }} />
            </div>
        );
    }
}

export default NoveltyReportHeader;
