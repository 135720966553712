import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class TextWithCopyOptionRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    doCopy() {
        var textValue = this.props.data[this.props.fieldName];
        var cb = document.getElementById("cb");
        cb.value = textValue;
        cb.style.display = 'block';
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
    }

    render() {
        // var appNum = this.props.data['applicationNumber'];
        var textValue = this.props.data[this.props.fieldName];
        if (textValue) {
            return (
                <div>
                {textValue}&nbsp;<a style={{fontSize: 'smaller', color: 'blue', cursor: 'pointer'}} onClick={() => this.doCopy()}>Copy</a>
            </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default TextWithCopyOptionRenderer;
