import React, { Component } from 'react';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class JudgementControl extends Component {
    static displayName = "";

    constructor(props) {
        super(props);

        this.state = {
            userDisclosed: this.props.userDisclosed
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    click(v) {
        this.setState({ userDisclosed: v });

        var yn = v === true ? 'Y' : v === false ? 'N' : '';

        fetch('/api/noveltyreport/claimfeature/' +
            this.props.claimFeatureId +
            '/userdisclosedyn',
            {
                method: 'post',
                body: JSON.stringify(yn),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    response.text().then(resp => {
                        if(resp.length)
                            this.props.setUserReasoning(resp);
                    });
                }
        });
    }


    render() {
        this.initEventHandlers();

        var noControl = this.state.userDisclosed === false
            ? (
                <div style={{ width: '1em', fontWeight: 'bold', fontSize: 22, color: 'red', cursor: 'pointer' }} onClick={() => this.click(undefined)}>&#10007;</div>
            )
            : (
                <div style={{ width: '1em', opacity: 0.35, fontSize: 22, color: 'red', cursor: 'pointer' }} onClick={() => this.click(false)}>&#10007;</div>
            );

        var yesControl = this.state.userDisclosed === true
            ? (
                <div style={{ width: '1em', fontWeight: 'bold', fontSize: 22, color: 'green', cursor: 'pointer' }} onClick={() => this.click(undefined)}>&#10003;</div>
            )
            : (
                <div style={{ width: '1em', opacity: 0.35, fontSize: 22, color: 'green', cursor: 'pointer' }} onClick={() => this.click(true)}>&#10003;</div>
            );

        return (
            <div style={{ display: 'flex' }}>
                {noControl}
                {yesControl}
            </div>
        );
    }
}

export default JudgementControl;
