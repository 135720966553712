import React, { Component } from 'react';
import { Checkbox, Panel, ActionButton } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './UPCContent.css';
import './ClientSelector.css';
import { withRouter } from '../WithRouter';

class UPCClientViewColumnSelector extends Component {
    static displayName = UPCClientViewColumnSelector.name;

    constructor(props) {
        super(props);
        this.state = {
            gridRef: props.gridRef
        };
    }

    componentDidMount() {
    }

    open = () => {
        this.setState(
            {
                isOpen: true
            });
    };

    close = () => {
        this.setState({ isOpen: false });
    }

    toggleColumn(columnId, showColumn) {
        this.state.gridRef.current.columnApi.applyColumnState({ state: [{ colId: columnId, hide: !showColumn }] });
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    componentDidMount() {
    }

    render() {
        var iconProps = { iconName: 'TripleColumn' };
        return (
            <div id="column-selector-option" className="filter-control" onClick={this.open}>
                <ActionButton id="column-selector-button" iconProps={iconProps}>Column&nbsp;selector</ActionButton>

                <Panel
                    isOpen={this.state.isOpen}
                    isLightDismiss
                    isHiddenOnDismiss={true}
                    onDismiss={this.close}
                    headerText='Select columns'>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-representative' defaultChecked label='Representative' onChange={e => {
                                        this.toggleColumn('representative', e.target.checked);
                                    }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-applicationstatus' defaultChecked label='Application Status' onChange={e => {
                                        this.toggleColumn('applicationStatus', e.target.checked);
                                    }}></Checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-lastprocstep' defaultChecked label='PatDoc: Last Procedural Step' onChange={e => {
                                        this.toggleColumn('lastProceduralStep', e.target.checked);
                                    }}></Checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-patentnumber' defaultChecked label='Patent Number' onChange={e => {
                                        this.toggleColumn('patentNumber', e.target.checked);
                                    }}></Checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-validatedin' defaultChecked label='In Force In (According to EPO Register)' onChange={e => {
                                        this.toggleColumn('validatedIn', e.target.checked);
                                    }}></Checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox id='show-column-optoutstatus' defaultChecked label='Opt Out Status' onChange={e => {
                                        this.toggleColumn('optOutStatus', e.target.checked);
                                    }}></Checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        );
    }
}

export default withRouter(UPCClientViewColumnSelector);
