import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class AutoExtendRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    click() {
        this.props.data['autoExtend'] = !this.props.data['autoExtend'];
        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");
        fetch('/api/admin/autoextendlicense?email=' +
            this.props.data.userEmail +
            '&autoExtend=' +
            this.props.data['autoExtend'],
            { headers: headers, method: 'post' }).then((response) => {
            return response.text();
        }).then(respText => {
        });
    }

    render() {
        if (this.props.data['autoExtend']) {
            return (
                <input type='checkbox' defaultChecked onClick={() => this.click()}>
                </input>
            );
        } else {
            return (
                <input type='checkbox' onClick={() => this.click()}>
                </input>
            );
        }
    }
}

export default AutoExtendRenderer;
/*
function AutoExtendRenderer() { }

AutoExtendRenderer.prototype.init = function (params) {
    this.params = params;

    this.eGui = document.createElement('input');
    this.eGui.type = 'checkbox';
    this.eGui.classList.add('column-checkbox');
    this.eGui.checked = params.value;

    this.checkedHandler = this.checkedHandler.bind(this);
    this.eGui.addEventListener('click', this.checkedHandler);
}

AutoExtendRenderer.prototype.checkedHandler = function (e) {
    let checked = e.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
    this.params.node.data.autoExtend = checked;

    var headers = {};
    headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");
    fetch('/api/admin/autoextendlicense?email=' + this.params.node.data.userEmail + '&autoExtend=' + checked, { headers: headers, method: 'post' }).
        then((response) => {
            return response.text();
        }).then(respText => {
        });
}

AutoExtendRenderer.prototype.getGui = function () {
    return this.eGui;
}

AutoExtendRenderer.prototype.destroy = function () {
    this.eGui.removeEventListener('click', this.checkedHandler);
}
*/