import React, { Component } from 'react';
import ReportSectionBase from './ReportSectionBase'

class InventiveStepSection extends ReportSectionBase {
    constructor(props) {
        super(props,
            {},
            'Inventive Step',
            '/api/officeactionreport/' + props.reportkey + '/inventivestep');
    }

    dataLoaded(data) {
        if (data && data.length > 0) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    inventiveStepText: data
                });
            });
            this.dataReady();
            this.stopPolling();
        } else {
            this.emptySection();
            this.stopPolling();
        }
    }

    renderData() {
        var inventiveStepLines = this.state.inventiveStepText.split('\n');
        var content = [];
        var lineNo = 0;
        inventiveStepLines.forEach(l => {
            if (l.trim().length > 0)
                content.push(<p key={lineNo++}>{l.trim()}</p>);
        });

        return <div>{content}</div>;
    }
}

export default InventiveStepSection;
