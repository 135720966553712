import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class LastUsedRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // var appNum = this.props.data['applicationNumber'];
        var lastUsed = this.props.data.lastUsed;
        lastUsed = lastUsed.replace('T', ' ');
        return (
            <div>
                {lastUsed}
            </div>
        );
    }
}

export default LastUsedRenderer;
