import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';

class FeatureResponsePanelEntry extends Component {
    static displayName = "";

    constructor(props) {
        super(props);

        console.log('Contructing row with default values ' +
            this.props.defaultFeatureText + 
            ' & ' + 
            this.props.defaultGroundTruth +
            ' & ' +
            this.props.defaultReasoning);
        this.state = {
            featureText: this.props.defaultFeatureText,
            groundTruth: this.props.defaultGroundTruth,
            reasoning: this.props.defaultReasoning
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadFeatures(caseId) {
    }

    loadPriorArt(priorArtId) {
    }


    onFeatureTextInput(evt) {
        if (this.featureTextTimer) {
            clearTimeout(this.featureTextTimer);
        }

        var newFeatureText = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                featureText: newFeatureText
            });
        });
        this.featureTextTimer = setTimeout(() => { this.saveFeatureText(newFeatureText) }, 2500);
    }

    saveFeatureText(newFeatureText) {
        console.log('Saving ' + this.props.featureId + ' with featureText ' + newFeatureText);

        var postBody = { text: newFeatureText };
        fetch('/api/trainingdata/features/' + this.props.featureId,
            {
                method: 'post',
                body: JSON.stringify(postBody),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    onReasoningInput(evt) {
        if (this.reasoningTimer) {
            clearTimeout(this.reasoningTimer);
        }

        var newReasoningText = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                reasoning: newReasoningText
            });
        });
        this.reasoningTimer = setTimeout(() => { this.saveReasoning(newReasoningText) }, 2500);
    }

    saveReasoning(newReasoningText) {
        console.log('Saving ' + this.props.featureId + ' with reasoning ' + newReasoningText);

        fetch('/api/trainingdata/priorart/' +
            this.props.priorArtId +
            '/featuretruth/' +
            this.props.featureId +
            '/reasoning',
            {
                method: 'post',
                body: JSON.stringify(newReasoningText),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    onGroundTruthChange(evt) {
        var newGroundTruth = evt.currentTarget.checked;
        this.setState((prevState) => {
            return ({
                ...prevState,
                groundTruth: newGroundTruth
            });
        });

        console.log('Saving ' + this.props.featureId + ' with ground truth ' + newGroundTruth);

        fetch('/api/trainingdata/priorart/' +
            this.props.priorArtId +
            '/featuretruth/' +
            this.props.featureId +
            '/groundtruth',
            {
                method: 'post',
                body: JSON.stringify(newGroundTruth),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    render() {
        this.initEventHandlers();
        var disablePriorArtControls = true;
        if (this.props.priorArtId) {
            disablePriorArtControls = false;
        }

        return (
            <tr>
                <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <textarea value={this.state.featureText} style={{ flexGrow: 1, resize: 'none' }} onInput={(e) => this.onFeatureTextInput(e)}/>
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Checkbox disabled={disablePriorArtControls} checked={this.state.groundTruth} onChange={(e) => this.onGroundTruthChange(e)} />
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <textarea disabled={disablePriorArtControls} value={this.state.reasoning} style={{ flexGrow: 1, resize: 'none' }} onInput={(e) => this.onReasoningInput(e)} />
                    </div>
                </td>
            </tr>
        );
    }
}

export default FeatureResponsePanelEntry;
