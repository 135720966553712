import $ from 'jquery';
import React, { Component } from 'react';
import { PrimaryButton, Dialog } from '@fluentui/react';
//import './GPTUsageDialog.css';

class NewPriorArtDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        window.showNewPriorArtDialog = () => {
            this.setState({
                isOpen: true
            });
        };
    }

    close = () => {
        this.setState({
            isOpen: false,
            caseList: undefined
        });
    }

    createPriorArt = () => {
        var referenceNumber = $('#referenceNumberInput')[0].value;

        var postBody = {
            'ReferenceNumber': referenceNumber
        };
        fetch('/api/trainingdata/cases/' + this.props.caseId + '/priorart/add',
            {
                method: 'post',
                body: JSON.stringify(postBody),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                response.text().then(priorArtId => {
                    this.props.priorArtAdded();
                    this.close();
                });
            }
        });
    }

    render() {
        this.dialogStyles = {
            main: {
                selectors: {
                    '@media (min-width: 0px)': {
                        minHeight: '200px',
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        minWidth: '400px'
                    }
                }
            }
        };

        this.modalProps = {
            styles: this.dialogStyles,
            onDismiss: () => {
                this.close();
            }
        };


        return (
            <Dialog hidden={!this.state.isOpen} modalProps={this.modalProps} title='New Prior Art'>
                <table>
                    <tbody>
                        <tr>
                            <td>Reference Number:</td>
                            <td><input id='referenceNumberInput'/></td>
                        </tr>
                        <tr>
                            <td colspan='2'>
                                <div style={{textAlign: 'center'}}>
                                    <PrimaryButton style={{ margin: '1em auto 0 auto' }} onClick={() => this.createPriorArt()}>Create Prior Art</PrimaryButton>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default NewPriorArtDialog;
