import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import CreateNewIcon from './Icons/CreateNew.png';
import SingleResponseRenderer from './SingleResponseRenderer';

class ResponseOptionsRenderer extends Component {
    static displayName = ResponseOptionsRenderer.name;

    constructor(props) {
        super(props);
        this.state = { };
    }

    newTemplate(rowData, responseType, german, sharePointUrl) {
        window.showNewTemplateDialog(rowData, responseType, german, sharePointUrl);
    }

    render() {
        var templates = [];

        var dataCopy = [...this.props.data['templates']];
        dataCopy.sort((a, b) => {
            if (a.clientTemplate && !b.clientTemplate)
                return -1;
            if (b.clientTemplate && !a.clientTemplate)
                return 1;
            if (a.responseType < b.responseType)
                return -1;
            if (b.responseType < a.responseType)
                return 1;
            return 0;
        });

        var key = 0;
        dataCopy.forEach(t => {
            var classname = t.clientTemplate ? 'template-client' : 'template-office';
            if (t.languages.length == 1) {
                templates.push(
                    <div key={key} style={{display:'flex'}}>
                        <SingleResponseRenderer clientTemplate={t.clientTemplate} sharePointUrl={t.languages[0].sharePointUrl} responseType={t.responseType} rowData={this.props.data} />
                    </div>
                );
            }
            else if (t.languages.length > 1) {
                var defaultTemplate = t.languages.filter(l => l.language !== 'de')[0];
                var germanTemplate = t.languages.filter(l => l.language === 'de')[0];
                if (defaultTemplate && germanTemplate) {
                    templates.push(
                        <div key={key} style={{display: 'flex', flexDirection:'horizontal'}}>
                            <SingleResponseRenderer clientTemplate={t.clientTemplate} sharePointUrl={defaultTemplate.sharePointUrl} responseType={t.responseType} rowData={this.props.data} german={false} />
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <SingleResponseRenderer clientTemplate={t.clientTemplate} sharePointUrl={germanTemplate.sharePointUrl} responseType={t.responseType} rowData={this.props.data} german={true} />
                        </div>
                    );
                }
            }

            ++key;
        });

        return (
            <div>
                {templates}
            </div>
        );
    }
}

export default ResponseOptionsRenderer;
