import React, { Component } from 'react';
import { Dialog } from '@fluentui/react';
import './GPTUsageDialog.css';

class GPTUsageDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            historyList: []
        };

        window.showGPTDialog = (data) => {
            this.setState({
                showGPTDialog: true,
                historyList: data
            });
        };
    }

    close = () => {
        this.setState({
            showGPTDialog: false,
            historyList: undefined
        });
    }

    buildRow = (d) => {
        var formattedDate = d.timestamp.substring(8, 10) +
            '.' +
            d.timestamp.substring(5, 7) +
            '.' +
            d.timestamp.substring(0, 4);
        //+ ' ' + d.timestamp.substring(11, 13) + ':' + d.timestamp.substring(14, 16);
        var formattedCost = '€' + (Math.round(d.estimatedCost * 100) / 100).toFixed(2);
        var formattedDuration = Math.round(d.duration / 1000) + 's';
        return <tr>
            <td>{formattedDate}</td>
                   <td>{d.description}</td>
                   <td>{formattedDuration}</td>
            <td>{d.promptTokens} / {d.completionTokens}</td>
                   <td>{formattedCost}</td>
                   <td>{d.model}</td>
        </tr>;
    };

    render() {
        this.dialogStyles = {
            main: {
                selectors: {
                    '@media (min-width: 0px)': {
                        minHeight: '400px',
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        minWidth: '800px'
                    }
                }
            }
        };

        this.modalProps = {
            styles: this.dialogStyles,
            onDismiss: () => {
                this.close();
            }
        };

        var contentList = [];
        if (this.state.historyList !== undefined)
            contentList = this.state.historyList.map(this.buildRow);
        return (
            <Dialog className='gpt-usage-dialog' hidden={!this.state.showGPTDialog} modalProps={this.modalProps} title='GPT Usage'>
                <table>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Description</td>
                            <td>Duration</td>
                            <td>Prompt / Completion Tokens</td>
                            <td>Estimated Cost</td>
                            <td>Model</td>
                        </tr>
                    </thead>
                    <tbody>
                        {contentList}
                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default GPTUsageDialog;
