import React, { Component } from 'react';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class TextField extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            initialValue: props.initialValue,
            value: props.initialValue
        };
    }

    componentWillReceiveProps(props) {
        // If the value hasn't been changed, update it with the new initial value (but if the user has made changes
        // we don't want to overwrite them).
        if (this.state.value === this.state.initialValue) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    value: props.initialValue
                });
            });
        }

        this.setState((prevState) => {
            return ({
                ...prevState,
                initialValue: props.initialValue
            });
        });
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    onInput(evt) {
        if (this.inputTimer) {
            clearTimeout(this.inputTimer);
        }

        var newValue = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                value: newValue
            });
        });
        this.inputTimer = setTimeout(() => { this.saveValue(newValue) }, 2500);
    }

    saveValue(newValue) {
        fetch(this.props.saveUrl,
            {
                method: 'post',
                body: JSON.stringify(newValue),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    console.log('Saved text: ' + newValue + ' to ' + this.props.saveUrl);
                } else {
                    console.log('Something went wrong');
                }
            });
    }

    render() {
        this.initEventHandlers();

        return (
            <textarea style={this.props.style} className={this.props.className} value={this.state.value} onInput={(e) => this.onInput(e)} />
        );
    }
}

export default TextField;
