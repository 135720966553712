import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { Navigate, useLocation } from 'react-router-dom';

class ChangePassword extends Component {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
        // super.componentDidMount();
    }

    initEventHandlers() {
    }

    changePassword() {
        var oldPassword = $('#oldpassword').val();
        var newPassword = $('#newpassword').val();
        var confirmNewPassword = $('#confirmnewpassword').val();
        if (newPassword !== confirmNewPassword) {
            alert('Passwords do not match');
            return;
        }

        fetch('/api/patentsurveillance/auth/changepassword',
            {
                method: 'post',
                body: JSON.stringify({ oldPassword: oldPassword, newPassword: newPassword }),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
                if (response.ok) {
                    alert('Password changed');
                } else {
                alert('Something went wrong');
            }
        });
    }

    render() {
        this.initEventHandlers();

        return (
            <div>
                <h2>Change Password</h2>
                <div style={{ display: 'grid', gridTemplateColumns: '12em 10em 10em', gridGap: '0.5em' }}>
                    <div style={{ margin: 'auto 0' }}>Old password:</div>
                    <input id='oldpassword' type='password' />
                    <div />
                    <div style={{ margin: 'auto 0' }}>New password:</div>
                    <input id='newpassword' type='password' />
                    <div />
                    <div style={{ margin: 'auto 0' }}>Confirm new password:</div>
                    <input id='confirmnewpassword' type='password' />
                    <DefaultButton onClick={() => this.changePassword()}>Change</DefaultButton>
                </div>
            </div>
        );
    }
}

export default withRouter(ChangePassword);
