import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import FTOSearchPatentResult from './FTOSearchPatentResult';

class FTOSearchOutputPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            patentResults: []
            /*
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                },
                { patentNumber: 'EP 1234567', status: 'pending', message: '', claims: [] },
                { patentNumber: 'EP 0000000', status: 'error', message: 'Unknown patent', claims: [] },
                {
                    patentNumber: 'EP 1111111',
                    status: 'ok',
                    message: '',
                    claims: [
                        {
                            claimNumber: 1,
                            claimText: 'A device with a feature',
                            claimStatus: 'ok',
                            claimResult: 'This is the result for this claim'
                        },
                        {
                            claimNumber: 6,
                            claimText: 'Another device with another feature',
                            claimStatus: 'pending',
                            claimResult: ''
                        }
                    ]
                }
            ]*/
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    clearPatents() {
        this.setState({
            patentResults: []
        });
    }

    addPatent(patent) {
        var existingPatents = this.state.patentResults;
        existingPatents.push(patent);
        this.setState({
            patentResults: existingPatents
        });
    }

    addUpdateClaim(patentNumber, claim) {
        var existingPatents = this.state.patentResults;
        var patent = existingPatents.filter(p => p.patentNumber === patentNumber)[0];
        if (patent) {
            patent.status = 'ok';
            patent.claims.push(claim);
        }

        this.setState({
            patentResults: existingPatents
        });
    }

    callLoop(claims, descText, apikey, onUpdate) {
        if (claims.length === 0)
            return;

        claims[0].status = 'pending';
        onUpdate();

        fetch('/api/chat/v2/ftosearch',
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'API-KEY': apikey
                },
                body: JSON.stringify({
                    claimText: claims[0].claimText,
                    productText: descText
                })
            }).then((response) => {
            if (response.ok) {
                response.text().then(result => {
                    var claimResultText = result;
                    claims[0].claimResult = claimResultText;
                    claims[0].status = 'ok';
                    onUpdate();
                    this.callLoop(claims.slice(1), descText, apikey, onUpdate);
                });
            } else {
                var claimResultText = 'Something went wrong';
                claims[0].claimResult = claimResultText;
                claims[0].status = 'error';
                onUpdate();
                this.callLoop(claims.slice(1), descText, apikey, onUpdate);
            }
        });
    }

    callGPT(descText, apikey) {
        var existingPatents = this.state.patentResults;
        var allClaims = [];
        existingPatents.forEach(r => {
            if (r.claims) {
                r.claims.forEach(c => {
                    allClaims.push(c);
                });
            }
        });

        this.callLoop(allClaims, descText, apikey, () => {
            this.setState({
                patentResults: existingPatents
            });
        });
    }

    render() {
        this.initEventHandlers();

        var results = [];
        this.state.patentResults.forEach(r => {

            var resultDetail = <div />;
            if (r.status === 'error') {
                resultDetail = <div style={{ marginLeft: '2em', color: '#ff0000' }} >{r.message}</div>;
            }
            else if (r.status === 'ok') {
                var claims = [];
                r.claims.forEach(c => {
                    var rhs = <div />
                    if (c.status === 'ok') {
                        // Default/ambiguous answers are yellow
                        var border = '#666600 1px solid';
                        var background = '#ffffbb';

                        if (c.claimResult.match(/^\s*Yes\b/)) {
                            // "Yes" answers are red
                            var border = '#660000 1px solid';
                            var background = '#ffbbbb';
                        } else if (c.claimResult.match(/^\s*No\b/)) {
                            // "No" answers are green
                            var border = '#006600 1px solid';
                            var background = '#bbffbb';
                        }

                        rhs = <div style={{border: border, background: background, margin: '-0.5em', padding: '0.5em', borderRadius: '0.5em'}}>{c.claimResult}</div>;
                    }
                    else if (c.status === 'error') {
                        rhs = <div style={{color: '#ff0000'}}>An error occurred.</div>;
                    } else if (c.status === 'pending') {
                        rhs = <div className='lds-dual-ring'/>;
                    }

                    claims.push(
                        <div style={{ marginLeft: '2em', marginBottom: '1em' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ textDecoration: 'underline' }}>
                                            Claim {c.claimNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '40%', verticalAlign: 'top', paddingRight: '1em' }}>{c.claimText}</td>
                                        <td style={{ width: '60%', verticalAlign: 'top', paddingLeft: '1em' }}>{rhs}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                });
                resultDetail = <div>{claims}</div>;
            } else if (r.status === 'pending') {
                resultDetail = <div className='lds-dual-ring' />;
            }


            results.push(
                <div style={{ marginBottom: '1em' }}>
                    <div style={{ fontWeight: 'bold' }}>{r.patentNumber}</div>
                    {resultDetail}
                </div>
            );
        });

        return (
            <div style={{ height: '100%', padding: '0.5em', overflowY: 'auto' }}>
                {results}
            </div>
        );
    }
}

export default FTOSearchOutputPanel;
