import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class PromptStatusRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    openDetails(parentId) {
        this.props.openPromptPopup(parentId);
    }

    render() {
        var status = [];
        var pending = this.props.data.totalRequestCount;
        pending -= this.props.data.errorRequestCount;
        pending -= this.props.data.successRequestCount;
        if (pending) {
            status.push(<span style={{ color: 'gray', fontWeight: 'bold' }}>{pending}</span>);
        } 

        if (this.props.data.errorRequestCount) {
            status.push(<span style={{ color: 'red', fontWeight: 'bold' }}>{this.props.data.errorRequestCount}</span>);
        } 

        if (this.props.data.successRequestCount) {
            status.push(<span style={{ color: 'green', fontWeight: 'bold' }}>{this.props.data.successRequestCount}</span>);
        } 

        return (
            <div style={{display: 'flex', gap: '0.5em', cursor: 'pointer'}} onClick={() => this.openDetails(this.props.data.id)}>
                {status}
            </div>
        );
    }
}

export default PromptStatusRenderer;