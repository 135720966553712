import React, { Component } from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import './TimelineView.css';
import FilterControls from './FilterControls';
import TeamsComponent from '../../TeamsComponent';
import { withRouter } from '../../WithRouter';


class TimelineView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = {
            periods: [],
            userTimelines: {}
        };
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }

    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    filterSelected(group, timeWindow) {
        if (group === undefined || timeWindow === undefined)
            return;

        fetch('/api/admin/timeline/' + group + '/' + timeWindow,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState({
                        periods: resp.periods,
                        userTimelines: resp.userTimelines
                    });
                });
            }
        });
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getColourForUsage(usage, maxUsage) {
        if (!usage || usage === 0) {
            return 'rgb(192,192,192)';
        }
        if (usage > maxUsage) usage = maxUsage;
        var lightness = 160 - (160 * usage/maxUsage);
        return 'rgb(255,' + lightness + ',' + lightness + ')';
    }
    
    render() {
        if (window.inTeams) {
        }

        var allActivity = [];
        for (const username in this.state.userTimelines) {
            if (this.state.userTimelines.hasOwnProperty(username)) {
                var userCols = [];
                this.state.periods.forEach(periodName => {
                        var thisCount = this.state.userTimelines[username][periodName] ?? 0;
                        if (thisCount > 0) {
                            allActivity.push(thisCount);
                        }
                    }
                );
            }
        }

        allActivity.sort(function (a, b) {
            return a - b;
        });

        var overallMax = allActivity[Math.floor(allActivity.length * 0.80)];


        var userRows = [];

        for (const username in this.state.userTimelines) {
            if (this.state.userTimelines.hasOwnProperty(username)) {
                var userCols = [];
                this.state.periods.forEach(periodName => {
                    var thisCount = this.state.userTimelines[username][periodName] ?? 0;
                    var usageColour = this.getColourForUsage(thisCount, overallMax);
                        
                    var tooltip = periodName + ': Unused';
                    if (thisCount === 1)
                        tooltip = periodName + ': Used 1 time';
                    if (thisCount > 1)
                        tooltip = periodName + ': Used ' + thisCount + ' times';
                    userCols.push(
                        <td style={{ width: '15px', height: '15px', padding:0 }}>
                                    <td style={{
                                        width: '15px',
                                        height: '15px',
                                        background: usageColour,
                                        border: 'black solid 0px'
                                    }} title={tooltip} />
                                </td>
                    );
                });

                userRows.push(
                    <tr>
                        <td style={{ paddingRight:'20px' }}>{username}</td>
                        {userCols}
                    </tr>
                );
            }
        }
        return (
            <div className='main-content'>
                <div style={{ display: 'flex', margin:'10px' }}>
                    <FilterControls ref={this.filterRef} onChange={(g, w) => this.filterSelected(g, w)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <table style={{margin:'10px'}}>
                    <tbody>
                        {userRows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withRouter(TimelineView);
