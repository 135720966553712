import React, { Component } from 'react';
import { Dialog } from '@fluentui/react';
import './UPCClientViewHistoryDialog.css';

class UPCClientViewHistoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            historyList: []
        };

        window.showHistoryDialog = (data) => {
            this.setState({
                showHistoryDialog: true,
                historyList: data
            });
        };
    }

    close = () => {
        this.setState({
            showHistoryDialog: false,
            historyList: undefined
        });
    }

    buildRow = (d) => {
        var formattedDate = d.timestamp.substring(8, 10) +
            '.' +
            d.timestamp.substring(5, 7) +
            '.' +
            d.timestamp.substring(0, 4);
        return <tr><td>{formattedDate}</td><td>{d.description}</td></tr>;
    };

    render() {
        this.dialogStyles = {
            main: {
                selectors: {
                    '@media (min-width: 0px)': {
                        minHeight: '400px',
                        maxHeight: '80vh',
                        maxWidth: '80vw',
                        minWidth: '800px'
                    }
                }
            }
        };

        this.modalProps = {
            styles: this.dialogStyles,
            onDismiss: () => {
                this.close();
            }
        };

        var contentList = [];
        if (this.state.historyList !== undefined)
            contentList = this.state.historyList.map(this.buildRow);
        return (
            <Dialog className='history-dialog' hidden={!this.state.showHistoryDialog} modalProps={this.modalProps} title='Procedural step history'>
                <table>
                    <tbody>
                        {contentList}
                    </tbody>
                </table>
            </Dialog>
        );
    }
}

export default UPCClientViewHistoryDialog;
