import React, { Component } from 'react';
import {PrimaryButton, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import {PMHeader} from '../HeadersFooters/PatentMakerHeader';
import TextDocumentEntry from './TextDocumentEntry';

class DocumentLibraryView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            documents: []
        };
    }

    componentDidMount = () => {
        this.openLibrary();
    }

    initEventHandlers() {
    }

    openLibrary() {
        fetch('/api/documentlibrary',
            {
                headers:
                {
                    'API-KEY': this.props.params.apikey
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                documents: resp
                            });
                        });
                    });
                }
        });
    }

    loadPriorArt(priorArtId) {
        // Also reload the features, since they may have been modified in the previous prior art
        this.featureResponsePanelRef.current.loadFeatures(this.state.selectedCase.id);
        this.featureResponsePanelRef.current.loadPriorArt(priorArtId);
    }

    addNew() {
        fetch('/api/documentlibrary/add',
            {
                method: 'post',
                headers:
                {
                    'API-KEY': this.props.params.apikey,
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    this.openLibrary();
                }
        });
    }

    render() {
        this.initEventHandlers();

        var documentRows = this.state.documents.map(d => {
            return (
                <TextDocumentEntry document={d}/>
                );
        });

        return (
            <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'auto 1fr auto' }}>
                <div style={{ gridColumn: 1, gridColumnEnd: 2, gridRow: 1 }} >
                    <PMHeader header='Voice Assistant - Library of Text Documents' />
                </div>
                <div style={{ gridColumn: 1, gridColumnEnd: 2, gridRow: 2 }} >
                    <table style={{ width: '98%', margin:'1%' }}>
                        <thead style={{fontWeight: 'bold'}}>
                        <td>Name</td>
                        <td>Description</td>
                        <td>Text</td>
                        </thead>
                        <tbody>
                            {documentRows}
                        </tbody>
                    </table>
                </div>
                <div type={{ gridColumn: 1, gridColumnEnd: 2, gridRow: 3 }}>
                    <PrimaryButton onClick={()=>this.addNew()}>Add new document</PrimaryButton>
                </div>
            </div>
        );
    }
}

export default withRouter(DocumentLibraryView);
