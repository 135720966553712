import React, { Component } from 'react';
import { Checkbox, Panel, ActionButton } from '@fluentui/react';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';

class ColumnSelector extends Component {
    static displayName = ColumnSelector.name;

    constructor(props) {
        super(props);
        this.state = {
            gridRef: props.gridRef,
            rows: []
        };

        window.prepareColumnSelector = (cols) => {
            var rows = [];
            rows.push(
                <tr>
                    <td>
                        <Checkbox id='show-column-licensekey' label='License Key' onChange={e => {
                            this.toggleColumn('licenseKey', e.target.checked);
                        }} />
                    </td>
                </tr>
                );
            cols.forEach(colDef => {
                if (colDef.field.indexOf('feature') === 0) {
                    var id = 'show-column-' + colDef.field;
                    var label = colDef.headerName;
                    if (colDef.hide) {
                        rows.push(
                            <tr>
                                <td>
                                    <Checkbox id={id} label={label} onChange={e => {
                                        this.toggleColumn(colDef.field, e.target.checked);
                                    }} />
                                </td>
                            </tr>
                        );
                    } else {
                        rows.push(
                            <tr>
                                <td>
                                    <Checkbox id={id} label={label} defaultChecked onChange={e => {
                                        this.toggleColumn(colDef.field, e.target.checked);
                                    }} />
                                </td>
                            </tr>
                        );
                    }
                }
            });

            this.setState({ rows: rows });
        };
    }

    componentDidMount() {
    }

    open = () => {
        this.setState(
            {
                isOpen: true
            });
    };

    close = () => {
        this.setState({ isOpen: false });
    }

    toggleColumn(columnId, showColumn) {
        this.state.gridRef.current.columnApi.applyColumnState({ state: [{ colId: columnId, hide: !showColumn }] });
        this.state.gridRef.current.api.sizeColumnsToFit();
        this.state.gridRef.current.api.resetRowHeights();
    }

    componentDidMount() {
    }

    render() {
        var iconProps = { iconName: 'TripleColumn' };
        return (
            <div id="column-selector-option" className="filter-control" onClick={this.open}>
                <ActionButton id="column-selector-button" iconProps={iconProps}>Column&nbsp;selector</ActionButton>

                <Panel
                    isOpen={this.state.isOpen}
                    isLightDismiss
                    isHiddenOnDismiss={true}
                    onDismiss={this.close}
                    headerText='Select columns'>
                    <table>
                        <tbody>
                            {this.state.rows}
                        </tbody>
                    </table>
                </Panel>
            </div>
        );
    }
}

export default withRouter(ColumnSelector);
