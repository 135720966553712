import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import FTOSearchInputPanel from './FTOSearchInputPanel';
import FTOSearchOutputPanel from './FTOSearchOutputPanel';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

class NewFTOSearchView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };

        this.inputPanelRef = React.createRef();
        this.outputPanelRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    performSearch() {
        var productDescription = $('#description-box').val();
        var patentsText = $('#patents-box').val();
        var patentsList = patentsText.split('\n').map(p => p.trim()).filter(p => p.length !== 0);
        var request = {
            productDescription: productDescription,
            priorArtReferenceNumbers: patentsList
        };

        fetch('/api/chat/v3/ftoanalysis',
            {
                method: 'post',
                headers:
                {
                    'Content-Type': 'application/json',
                    'API-KEY': this.props.params.apikey
                },
                body: JSON.stringify(request)
            }).then(response => {
            if (response.ok) {
                response.json().then(analysisResults => {
                    this.loadFile(
                        '/content/templates/ftoanalysis.docx',
                        function(error, content) {
                            if (error) {
                                throw error;
                            }
                            var zip = new PizZip(content);
                            var doc = new Docxtemplater(zip,
                                {
                                    paragraphLoop: true,
                                    linebreaks: true,
                                });
                            doc.setData(analysisResults);
                            try {
                                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                                doc.render();
                            } catch (error) {
                                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                                function replaceErrors(key, value) {
                                    if (value instanceof Error) {
                                        return Object.getOwnPropertyNames(value).reduce(function(
                                                error,
                                                key
                                            ) {
                                                error[key] = value[key];
                                                return error;
                                            },
                                            {});
                                    }
                                    return value;
                                }

                                console.log(JSON.stringify({ error: error }, replaceErrors));

                                if (error.properties && error.properties.errors instanceof Array) {
                                    const errorMessages = error.properties.errors
                                        .map(function(error) {
                                            return error.properties.explanation;
                                        })
                                        .join('\n');
                                    console.log('errorMessages', errorMessages);
                                    // errorMessages is a humanly readable message looking like this :
                                    // 'The tag beginning with "foobar" is unopened'
                                }
                                throw error;
                            }
                            var out = doc.getZip().generate({
                                type: 'blob',
                                mimeType:
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            }); //Output the document using Data-URI
                            saveAs(out, 'FTO Analysis.docx');
                        });

                });
            } else {
                alert('Failed to generate report');
                //response.text().then(t => { alert(t) });
            }
        });

        alert('Your report will be downloaded shortly.');
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{height: '100%'}}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1, minWidth: '30em'}}>
                        <FTOSearchInputPanel ref={this.inputPanelRef} doSearch={() => this.performSearch()} />
                    </div>
                    <div style={{ flexGrow: 2 }}>
                        <FTOSearchOutputPanel ref={this.outputPanelRef} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NewFTOSearchView);
