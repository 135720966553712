import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';
import FeatureResponsePanelEntry from './FeatureResponsePanelEntry';

class FeatureResponsePanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            features: [],
            truth: [],
            selectedPriorArtId: undefined,
            selectedCaseId: undefined
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadFeatures(caseId) {
        fetch('/api/trainingdata/cases/' + caseId + '/features',
            {
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            selectedCaseId: caseId,
                            features: resp
                        });
                    });
                });
            }
        });
    }

    loadPriorArt(priorArtId) {
        fetch('/api/trainingdata/priorart/' + priorArtId + '/featuretruth',
            {
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            selectedPriorArtId: priorArtId,
                            truth: resp
                        });
                    });
                });
            }
        });
    }

    addNewFeature() {
        var postBody = { text: '' };
        fetch('/api/trainingdata/cases/' + this.state.selectedCaseId + '/features/add',
            {
                method: 'post',
                body: JSON.stringify(postBody),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    this.loadFeatures(this.state.selectedCaseId);
                }
        });
    }

    getFeaturesFromClaims() {
        fetch('/api/trainingdata/cases/' + this.state.selectedCaseId + '/features/getdefault',
            {
                method: 'post',
                headers:
                {
                    'Content-Type': 'application/json',
                    'API-KEY': this.props.apikey
                }
            }).then(response => {
            if (response.ok) {
                this.loadFeatures(this.state.selectedCaseId);
            }
        });
    }

    render() {
        this.initEventHandlers();
        console.log('Rendering grid');

        var featureRows = this.state.features.map(f => {
            var reasoning = '';
            var groundTruth = false;
            var truthEntry = this.state.truth.find(t => t.featureId === f.id);
            if (truthEntry) {
                reasoning = truthEntry.reasoning;
                groundTruth = truthEntry.groundTruth;
            }

            //alert(groundTruth);
            var rowKey = this.state.selectedPriorArtId + '~' + f.id;

            return (
                <FeatureResponsePanelEntry key={rowKey} featureId={f.id} priorArtId={this.state.selectedPriorArtId} defaultFeatureText={f.text} defaultGroundTruth={groundTruth} defaultReasoning={reasoning} />
            );
        });
        return (
            <div style={{ height: '100%', padding: '0.5em', display: 'flex', flexDirection: 'column' }}>
                <table>
                    <thead style={{fontWeight: 'bold'}}>
                        <td style={{ width: '60%' }}>
                            Feature
                        </td>
                        <td style={{ width: '10%', maxWidth: '200px'}}>
                            Disclosed?
                        </td>
                        <td style={{ width: '30%' }}>
                            Reasoning
                        </td>
                    </thead>
                    <tbody>
                        {featureRows}
                    </tbody>
                </table>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '1em 1px' }}>
                    <div>
                        <PrimaryButton onClick={() => this.addNewFeature()}>Add new feature</PrimaryButton>
                    </div>
                    <div style={{flexGrow: 1}}/>
                    <div>
                        <PrimaryButton onClick={() => this.getFeaturesFromClaims()}>Extract features from claims</PrimaryButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeatureResponsePanel;
