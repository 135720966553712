import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class ExpiryDateRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    extendExpiry() {
        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");

        fetch('/api/admin/extendlicense?email=' + this.props.data.userEmail, { headers: headers, method: 'post' }).
            then((response) => {
                return response.text();
            }).then(respText => {
                this.props.node.setDataValue('expiry', respText);
            });
    }

    cancelExpiry() {
        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");

        fetch('/api/admin/cancellicense?email=' + this.props.data.userEmail, { headers: headers, method: 'post' }).
            then((response) => {
                return response.text();
            }).then(respText => {
                this.props.node.setDataValue('expiry', respText);
            });
    }

    render() {
        // var appNum = this.props.data['applicationNumber'];
        var colour = '#000000';
        var expiryDate = this.props.data.expiry;
        if (new Date(expiryDate) < new Date() && !this.props.data.autoExtend) {
            colour = '#ff0000';
        }
        expiryDate = expiryDate.split('T')[0];
        expiryDate = expiryDate.substr(8, 2) + '.' + expiryDate.substr(5, 2) + '.' + expiryDate.substr(0,4);

        var updateOption = undefined;
        // if (window.myEmail === 'iod@patentmaker.eu')
        updateOption = <a onClick={() => this.extendExpiry()} style={{ marginLeft: '0.5em', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} >Extend</a>;

        var cancelOption = undefined;
        cancelOption = <a onClick={() => this.cancelExpiry()} style={{ marginLeft: '0.5em', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} >Cancel</a>;

        return (
            <div>
                <span style={{ color: colour }}>
                    {expiryDate}
                </span>
                {updateOption}
                {cancelOption}
            </div>
        );
    }
}

export default ExpiryDateRenderer;
