import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import sharedIcon from './Icons/Shared.png';
import personalIcon from './Icons/Personal.png';
import groupIcon from './Icons/Group.png';

class IconTextRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        var icon;
        if (this.props.data.group === '')
            icon = sharedIcon;
        else if (this.props.data.group === window.teamsEmail)
            icon = personalIcon;
        else
            icon = groupIcon;

        return (
            <div>
                <img src={icon} style={{ height: 16, verticalAlign: 'middle' }} /><span style={{ verticalAlign: 'middle', marginLeft: '0.25em' }}>{this.props.data.groupLabel}</span>
            </div>
        );
    }
}

export default IconTextRenderer;
