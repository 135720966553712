import React, { Component } from 'react';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class GeneralFeedbackPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            generalFeedback: props.report.generalFeedback
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    loadCase(thisCase) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                thisCase: thisCase
            });
        });
    }

    onFeedbackInput(evt) {
        if (this.feedbackTimer) {
            clearTimeout(this.feedbackTimer);
        }

        var newFeedbackText = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                generalFeedback: newFeedbackText
            });
        });
        this.feedbackTimer = setTimeout(() => { this.saveFeedback(newFeedbackText) }, 2500);
    }

    saveFeedback(newFeedbackText) {
        fetch('/api/noveltyreport/' +
            this.props.report.id +
            '/generalfeedback',
            {
                method: 'post',
                body: JSON.stringify(newFeedbackText),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }

    render() {
        this.initEventHandlers();

        return (
            <div style={{ height: 'auto', padding: '0.5em', display: 'flex', flexDirection: 'column' }}>
                <span style={{ margin: 'auto 0', fontSize: 22, fontWeight: 'bold', textDecoration: 'underline' }}>Feedback</span>
                <p style={{fontStyle: 'italic'}}>Please provide any feedback you have on the quality of this AI-generated report.</p>
                <textarea value={this.state.generalFeedback} onInput={(e) => this.onFeedbackInput(e)} style={{maxWidth: '60em', minHeight: '15em'}} />
            </div>
        );
    }
}

export default GeneralFeedbackPanel;