import React, { Component } from 'react';
import { MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import AutoDisableButton from './AutoDisableButton';

class ClaimsSheetProcessorView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            promptOne: 'Please identify and quote verbatim the paragraph that outlines the problem, objective or aim of the invention in the following patent application. If you find a paragraph for the problem use it only if you do not, use the paragraph you found for the objective. If no paragraph numbers are given, assume each new line is a separate paragraph:\n\n{patent description}\n\n(Note: When answering, quote the identified paragraph directly. No need to use apostrophes. Also, do not include the paragraph number.)',
            promptTwo: 'Assume we already understand the problem the following patent application tries to solve but not the solution. Please identify and quote verbatim the paragraph that outlines the core solution that the following patent application identifies for the problem it tries to solve. If no paragraph numbers are given, assume each new line is a separate paragraph:\n\n{patent description}\n\n(Note: When answering, quote the identified paragraph directly. No need to use apostrophes. Also, do not include the paragraph number.)'
        };
        this.processButtonRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    handleInputFileChange(event) {
        var fileInput = document.getElementById('fileInput');
        //if we didnd't already have the "fileInput" var in scope, we could use "event.target" to get it
        if (fileInput.files.length >= 1) {
            //In this example, I'm putting the selected file's name in the title. You don't need to do this
            document.title = fileInput.files[0].name;
        }
        else {
            document.title = "FileReader Example";
        }
        var fr = new FileReader();
        fr.readAsDataURL(fileInput.files[0]);
        fr.onload = (event) => {
            //Both "event.target.result" and "fr.result" contain the file's contents (because "event.target" is === "fr")

            var base64Input = event.target.result;
            if (base64Input.indexOf('base64,') !== -1)
                base64Input = base64Input.substring(base64Input.indexOf('base64,') + 'base64,'.length);
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    base64Input: base64Input
                });
            });
            // OR
            //textArea.value = fr.result;
        }
    }

    process() {
        fetch('/api/claimssheetprocessor/processworkbook',
            {
                method: 'post',
                body: JSON.stringify({
                    problemPrompt: this.state.promptOne,
                    solutionPrompt: this.state.promptTwo,
                    inputSheet: this.state.base64Input
                }),
                headers:
                {
                    'Content-Type': 'application/json',
                    'API-KEY': this.props.params.apikey
                }
            }).then(response => {
                this.processButtonRef.current.enableButton();
                if (response.ok) {
                    response.text().then(resp => {
                        window.location.href = "/api/claimssheetprocessor/retrieveworkbook/" + resp;
                    });
            }
        });
    }

    onInputPromptOne(evt) {
        var newValue = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                promptOne: newValue
            });
        });
    }

    onInputPromptTwo(evt) {
        var newValue = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                promptTwo: newValue
            });
        });
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{ height: '100%', margin: '1em' }}>
                <div style={{ display: 'flex' }}>
                    <table style={{ flexGrow: 1, marginRight: '0.5em' }}>
                        <tbody>
                        <tr>
                            <td style={{ fontWeight: 'bold' }}>
                                Problem
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea value={this.state.promptOne} onInput={(e) => this.onInputPromptOne(e)} style={{ height: '16em', width: '100%' }} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table style={{ flexGrow: 1, marginRight: '0.5em' }}>
                        <tbody>
                        <tr>
                            <td style={{fontWeight: 'bold'}}>
                                Solution
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea value={this.state.promptTwo} onInput={(e) => this.onInputPromptTwo(e)} style={{ height: '16em', width: '100%' }} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{marginTop: '2em'}}>
                    <input id="fileInput" type="file" accept=".xlsx" onChange={(e) => { this.handleInputFileChange(e);}} />
                </div>
                <div style={{marginTop: '1em'}}>
                    <AutoDisableButton ref={this.processButtonRef} action={() => this.process()} label='Process' />
                </div>
            </div>
        );
    }
}

export default withRouter(ClaimsSheetProcessorView);
