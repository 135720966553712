import React, { Component } from 'react';
import { Checkbox } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class FeatureDisclosedField extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            aiOpinion: props.aiOpinion,
            judgedCorrect: props.judgedCorrect
        };
    }

    componentWillReceiveProps(props) {
        if (this.state.aiOpinion !== props.aiOpinion) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    aiOpinion: props.aiOpinion,
                    judgedCorrect: null
                });
            });
        }
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }


    saveValue(newValue) {
        fetch('/api/officeactionreport/featureanalysis/' + this.props.featureId + '/judgedcorrect',
            {
                method: 'post',
                body: JSON.stringify(newValue),
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
                console.log('Saved text: ' + newValue + ' to ' + this.props.saveUrl);
            } else {
                console.log('Something went wrong');
            }
        });
    }

    toggleUserOpinion() {
        var newJudgement = true;
        if (this.state.judgedCorrect === true)
            newJudgement = false;
        else if (this.state.judgedCorrect === false)
            newJudgement = null;
        this.saveValue(newJudgement);
        this.setState((prevState) => {
            return ({
                ...prevState,
                judgedCorrect: newJudgement
            });
        });
    }

    render() {
        this.initEventHandlers();
        var colour = '#444';
        var symbol = '?';
        var textShadow = '1px 0px';
        switch (this.state.judgedCorrect) {
            case true:
                if (this.state.aiOpinion) {
                    colour = '#090';
                    symbol = '✓';
                    textShadow = '1px 0px';
                } else {
                    colour = '#c00';
                    symbol = '✗';
                    textShadow = '1px 0px';
                }
                break;
            case false:
                if (this.state.aiOpinion) {
                    colour = '#c00';
                    symbol = '✗';
                    textShadow = '1px 0px';
                } else {
                    colour = '#090';
                    symbol = '✓';
                    textShadow = '1px 0px';
                }
                break;
            default:
                if (this.state.aiOpinion === true) {
                    symbol = '✓';
                } else if(this.state.aiOpinion === false) {
                    symbol = '✗';
                }
                break;
        }
        return (
                <div onClick={() => this.toggleUserOpinion()}>
                <div style={{ color: colour, fontSize: 24, fontWeight: 'bold', textShadow: textShadow, cursor: 'pointer'}}>{symbol}</div>
                </div>

        );
        
//        <Checkbox style={this.props.style} className={this.props.className} defaultChecked={this.state.value} onChange={(e) => this.onChange(e)} />
    }
}

export default FeatureDisclosedField;
