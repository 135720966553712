import React, { Component } from 'react';
import {Dropdown, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import PatentSurveillanceComponent from '../PatentSurveillanceComponent';
import TextField from './TextField';
import './ReportView.css';
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import rotateIcon from './Rotate.png';

class ReportView extends PatentSurveillanceComponent {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            showCommentsAndDrawings: undefined
    };

        this.fileInputRef = React.createRef();
        this.comboRef = React.createRef();
    }

    componentDidMount = () => {
        super.componentDidMount();

        fetch('/api/patentsurveillance/report/' + this.props.params.reportid + '/patents',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            patentList: resp
                        });
                    });
                });
            }
            });

        fetch('/api/patentsurveillance/report/' + this.props.params.reportid + '/name',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.text().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            reportName: resp
                        });
                    });
                });
            }
        });

        fetch('/api/patentsurveillance/report/' + this.props.params.reportid + '/users',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            reportUsers: resp
                        });
                    });
                });
            }
        });

        fetch('/api/patentsurveillance/userSettings',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            showCommentsAndDrawings: resp.showCommentsAndDrawings
                        });
                    });
                });
            }
        });
    }

    initEventHandlers() {
    }

    relevanceSelected(patentId, relevance) {
        if (this.state.userInfo.isSuperAdmin)
            return;

        var allPatents = this.state.patentList;
        var patent = allPatents.filter(p => p.id === patentId)[0];
        if (patent) {
            patent.relevance = relevance;

            fetch('/api/patentsurveillance/patent/' + patentId + '/relevance',
                {
                    method: 'post',
                    body: JSON.stringify(relevance),
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                    }
                }).then(response => {
                if (response.ok) {
                    console.log('Saved relevance: ' + relevance);

                    response.json().then(resp => {
                        var patent = this.state.patentList.filter(p => p.id === patentId)[0];
                        patent.inputText = '';
                        patent.discussion.push(resp);
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                patentList: this.state.patentList
                            });
                        });
                    });
                } else {
                    console.log('Something went wrong');
                }
            });


            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: allPatents
                });
            });
        }
    }

    relavenceSort() {
        var goingDown = true;
        for (var i = 0; i < this.state.patentList.length - 1; ++i) {
            if (this.state.patentList[i].relevance === 'M' && this.state.patentList[i + 1].relevance === 'H')
                goingDown = false;
            if (this.state.patentList[i].relevance === 'L' && this.state.patentList[i + 1].relevance === 'H')
                goingDown = false;
            if (this.state.patentList[i].relevance === 'L' && this.state.patentList[i + 1].relevance === 'M')
                goingDown = false;
            if (this.state.patentList[i].relevance === '' && this.state.patentList[i + 1].relevance !== '')
                goingDown = false;
        }

        if (goingDown) {
            // Sort ascending
            var sorted = this.state.patentList.sort((lhs, rhs) => {
                if (lhs.relevance === rhs.relevance) return 0;
                if (lhs.relevance === 'H') return 1;
                if (lhs.relevance === 'M' && rhs.relevance !== 'H') return 1;
                if (lhs.relevance === 'L' && (rhs.relevance === '' || !rhs.relevance)) return 1;
                return -1;
            });
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: sorted
                });
            });
        } else {
            // Sort descending
            var sorted = this.state.patentList.sort((lhs, rhs) => {
                if (rhs.relevance === lhs.relevance) return 0;
                if (rhs.relevance === 'H') return 1;
                if (rhs.relevance === 'M' && lhs.relevance !== 'H') return 1;
                if (rhs.relevance === 'L' && (lhs.relevance === '' || !lhs.relevance)) return 1;
                return -1;
            });
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: sorted
                });
            });
        }
    }

    applicantSort() {
        var goingDown = true;
        for (var i = 0; i < this.state.patentList.length - 1; ++i) {
            if (this.state.patentList[i].applicant < this.state.patentList[i + 1].applicant) {
                goingDown = false;
            }
        }

        if (goingDown) {
            // Sort ascending
            var sorted = this.state.patentList.sort((lhs, rhs) => {
                if (lhs.applicant === rhs.applicant) return 0;
                if (lhs.applicant > rhs.applicant) return 1;
                return -1;
            });
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: sorted
                });
            });
        } else {
            // Sort descending
            var sorted = this.state.patentList.sort((lhs, rhs) => {
                if (lhs.applicant === rhs.applicant) return 0;
                if (lhs.applicant < rhs.applicant) return 1;
                return -1;
            });
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: sorted
                });
            });
        }
    }

    openImage(imageUrl) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                zoomedImageUrl: imageUrl
            });
        });
    }

    downloadRefs(minRelevance) {
        window.location.href = '/api/patentsurveillance/report/' +
            this.props.params.reportid +
            '/downloadrefs?minRelevance=' +
            minRelevance;
    }

    deleteImage(patentId) {
        var patent = this.state.patentList.filter(p => p.id === patentId)[0];
        patent.imageDataUrl = undefined;
        this.setState((prevState) => {
            return ({
                ...prevState,
                patentList: this.state.patentList
            });
        });

        fetch('/api/patentsurveillance/patent/' + patentId + '/image',
            {
                method: 'post',
                body: JSON.stringify(null),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                console.log('Deleted image');
            } else {
                console.log('Something went wrong');
            }
        });
    }

    uploadImage(patentId) {
        this.setState((prevState) => {
            return ({
                ...prevState,
                imageUploadPatentId: patentId
            });
        });

        this.fileInputRef.current.click();
    }

    rotateImage(patentId) {
        fetch('/api/patentsurveillance/patent/' + patentId + '/image/rotate',
            {
                method: 'post',
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                console.log('Rotated image');
                response.text().then(resp => {
                    var patent = this.state.patentList.filter(p => p.id === patentId)[0];
                    patent.imageDataUrl = resp;
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            patentList: this.state.patentList
                        });
                    });
                });
            } else {
                console.log('Something went wrong');
            }
        });
    }

    handleInputFileChange(event) {
        var fileInput = event.currentTarget;

        var fr = new FileReader();
        fr.readAsDataURL(fileInput.files[0]);
        fr.onload = (event) => {
            var base64Input = event.target.result;
            var patent = this.state.patentList.filter(p => p.id === this.state.imageUploadPatentId)[0];
            patent.imageDataUrl = base64Input;
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: this.state.patentList
                });
            });

            fetch('/api/patentsurveillance/patent/' + this.state.imageUploadPatentId + '/image',
                {
                    method: 'post',
                    body: JSON.stringify(base64Input),
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                    }
                }).then(response => {
                if (response.ok) {
                    console.log('Saved image');
                } else {
                    console.log('Something went wrong');
                }
            });
        }
    }

    postToDiscussion(patentId) {
        var text = $('#discussioninput-' + patentId).val();
        fetch('/api/patentsurveillance/patent/' + patentId + '/discussion',
            {
                method: 'post',
                body: JSON.stringify(text),
                headers:
                {
                    'Content-Type': 'application/json',
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                console.log('Saved discussion');

                response.json().then(resp => {
                    var patent = this.state.patentList.filter(p => p.id === patentId)[0];
                    patent.inputText = '';
                    patent.discussion.push(resp);
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            patentList: this.state.patentList
                        });
                    });
                });
            } else {
                console.log('Something went wrong');
            }
        });
    }

    onDiscussionInput(evt, patentId) {
        var patent = this.state.patentList.filter(p => p.id === patentId)[0];
        if (patent) {
            patent.inputText = evt.target.value;
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    patentList: this.state.patentList
                });
            });
        }
    }

    filterChange(e, o) {
        console.log('Filter change: ' + o.text);
        this.setState((prevState) => {
            return ({
                ...prevState,
                applicantFilter: o.text
            });
        });
        //console.log($('#filtercombo'));
    }


    authenticatedRender() {
        this.initEventHandlers();

        const Item = ({ entity: { name, email } }) => <div><span>{name}</span><span style={{marginLeft: '1em', fontSize: '0.8em'}}>({email})</span></div>;

        var isSuperAdmin = this.state.userInfo.isSuperAdmin;
        var gridDiv = undefined;

        var gridCells = [];
        if (this.state.patentList && this.state.patentList.length && this.state.showCommentsAndDrawings !== undefined) {
            var showAppDate = this.state.patentList.some(p => p.applicationDate);
            var showDrawings = this.state.showCommentsAndDrawings && this.state.patentList.some(p => p.imageDataUrl);
            var showMagicComments = this.state.showCommentsAndDrawings && (this.state.patentList.some(p => p.problem) || this.state.patentList.some(p => p.solution));

            var gridTemplateColumns = '1.5fr';
            if (showAppDate)
                gridTemplateColumns += ' 5em';
            gridTemplateColumns += ' 5em';
            if (showMagicComments)
                gridTemplateColumns += ' 3fr';
            gridTemplateColumns += ' 2.5fr';
            if (showDrawings)
                gridTemplateColumns += ' 1.5fr';
            gridTemplateColumns += ' 6em 3fr';

            var relevanceControlCursor = isSuperAdmin ? undefined : 'pointer';
            var userTextAreaClass = isSuperAdmin ? undefined : 'activetextarea';
            var adminTextAreaClass = isSuperAdmin ? 'activetextarea' : undefined;

            var cellClass = 'patent-surveillance-grid-oddcell';
            var includePatents = this.state.patentList;
            if (this.state.applicantFilter && this.state.applicantFilter.length) {
                includePatents =
                    includePatents.filter(p => p.applicant.toLowerCase() === this.state.applicantFilter.toLowerCase());
            }

            includePatents.forEach((patent) => {
                var drawingImage = undefined;
                if (patent.imageDataUrl)
                    drawingImage = <img src={patent.imageDataUrl} style={{ maxWidth: '20em', cursor: 'pointer' }} onClick={() => this.openImage(patent.imageDataUrl)}/>;

                var lowRelevanceControl = patent.relevance === 'L' ?
                    <div style={{ outline: 'green solid 2px', background: '#bfb', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center', fontWeight: 'bold' }} onClick={() => this.relevanceSelected(patent.id, '')}>Low</div>
                    : <div style={{ outline: 'black solid 1px', background: '#ddd', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center' }} onClick={() => this.relevanceSelected(patent.id, 'L')}>Low</div>;

                var mediumRelevanceControl = patent.relevance === 'M' ?
                    <div style={{ outline: 'orange solid 2px', background: '#ffb', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center', fontWeight: 'bold' }} onClick={() => this.relevanceSelected(patent.id, '')}>Medium</div>
                    : <div style={{ outline: 'black solid 1px', background: '#ddd', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center' }} onClick={() => this.relevanceSelected(patent.id, 'M')}>Medium</div>;

                var highRelevanceControl = patent.relevance === 'H' ?
                    <div style={{ outline: 'red solid 2px', background: '#fbb', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center', fontWeight: 'bold' }} onClick={() => this.relevanceSelected(patent.id, '')}>High</div>
                    : <div style={{ outline: 'black solid 1px', background: '#ddd', cursor: relevanceControlCursor, margin: '0.2em', padding: '0.5em', textAlign: 'center' }} onClick={() => this.relevanceSelected(patent.id, 'H')}>High</div>;

                var relevanceControls =
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                        {highRelevanceControl}
                        {mediumRelevanceControl}
                        {lowRelevanceControl}
                    </div>;


                var publicationNumberDiv;
                if (patent.patentUrl) {
                    publicationNumberDiv = <div><a href={patent.patentUrl} target='_blank'>{patent.publicationNumber}</a></div>;
                } else {
                    publicationNumberDiv = <div>{patent.publicationNumber}</div>;
                }

                var reportHeader = undefined, reportName = undefined;

                if (patent.reportName) {
                    reportHeader = <div style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '1em' }}>Published</div>;
                    reportName = <div>{patent.reportName}</div>
                }
                var patentCell =
                    <div className={cellClass} style={{ position: 'relative' }}>
                        <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Applicant</div>
                        <div>{patent.applicant}</div>
                        <div style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '1em' }}>Publication Number</div>
                        {publicationNumberDiv}
                        <div style={{ textDecoration: 'underline', fontWeight: 'bold', marginTop: '1em' }}>Title</div>
                        <div>{patent.title}</div>
                        {reportHeader}{reportName}
                    </div>;
                gridCells.push(patentCell);
                /*
                gridCells.push(<div className={cellClass}>{patent.applicant}</div>);

                if (patent.patentUrl) {
                    gridCells.push(<div className={cellClass}><a href={patent.patentUrl} target='_blank'>{patent.publicationNumber}</a></div>);
                } else {
                    gridCells.push(<div className={cellClass}>{patent.publicationNumber}</div>);
                }

                gridCells.push(<div className={cellClass}>{patent.title}</div>);
                */


                if (showAppDate)
                    gridCells.push(<div className={cellClass}>{patent.applicationDateText}</div>);

                gridCells.push(<div className={cellClass}>{patent.oppositionDeadlineText}</div>);
                if (showMagicComments){
                    gridCells.push(
                        <div className={cellClass} style={{ position: 'relative' }}>
                            <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Problem</div>
                            <TextField initialValue={patent.problem} className={adminTextAreaClass} disabled={!isSuperAdmin} style={{ width: '100%', fieldSizing: 'content' }} saveUrl={'/api/patentsurveillance/patent/' + patent.id + '/problemtext'} />
                            <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Solution</div>
                            <TextField initialValue={patent.solution} className={adminTextAreaClass} disabled={!isSuperAdmin} style={{ width: '100%', fieldSizing: 'content' }} saveUrl={'/api/patentsurveillance/patent/' + patent.id + '/solutiontext'} />
                        </div>);
                }

                gridCells.push(<div className={cellClass}>{patent.firstClaimText}</div>);

                if (showDrawings){
                    var drawingCell = undefined;
                    if (isSuperAdmin) {
                        if (drawingImage) {
                            drawingCell = <div className={cellClass} style={{ display: 'flex' }}>
                                <div>{drawingImage}</div>
                                <div onClick={() => this.rotateImage(patent.id)} style={{ marginLeft: '0.3em', cursor: 'pointer' }}><img src={rotateIcon} style={{ width: '1.5em'}}/></div>
                                <div onClick={() => this.deleteImage(patent.id)} style={{ color: 'red', fontSize: '1.5em', marginTop: '-0.2em', marginLeft: '0.3em', fontWeight: 'bold', cursor: 'pointer' }}>X</div>
                            </div>;
                        } else {
                            drawingCell = <div className={cellClass} style={{ display: 'flex' }}><div style={{ flexGrow: 1 }} /><div onClick={() => this.uploadImage(patent.id)} style={{ color: 'green', fontSize: '2em', marginTop: '-0.2em', marginLeft: '0.3em', fontWeight: 'bold', cursor: 'pointer' }}>+</div></div>;
                        }
                    } else {
                        drawingCell = <div className={cellClass}>{drawingImage}</div>;
                    }

                    gridCells.push(drawingCell);
                }

                gridCells.push(<div className={cellClass}>{relevanceControls}</div>);

                /*
                gridCells.push(<div className={cellClass}><TextField className={userTextAreaClass} disabled={isSuperAdmin} initialValue={patent.userComments} style={{ width: '100%', minHeight: '100%', fieldSizing: 'content' }} saveUrl={'/api/patentsurveillance/patent/' + patent.id + '/usercomments'} /></div>);
                */

                var chat = [];
                patent.discussion.forEach(d => {
                    var className = d.userId === this.state.userInfo.id ? 'chat-message chat-message-mine' : 'chat-message chat-message-notmine';
                    if (d.message === "Relevance set to 'High'.")
                        className += ' chat-message-high';
                    if (d.message === "Relevance set to 'Medium'.")
                        className += ' chat-message-medium';
                    if (d.message === "Relevance set to 'Low'.")
                        className += ' chat-message-low';
                    else
                        className += ' chat-message-regular';
                    chat.push(<div className={className}><div className='chat-header'><div>{d.userName}</div><div style={{flexGrow: 1}}/><div>{d.timestampText}</div></div><div className='chat-body'>{d.message}</div></div>);
                });
                gridCells.push(<div className={cellClass}><div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}><div>{chat}</div><div style={{ flexGrow: 1 }} /><div style={{ display: 'flex' }}>
                    <ReactTextareaAutocomplete
                        id={'discussioninput-' + patent.id}
                        value={patent.inputText}
                        onChange={(e) => this.onDiscussionInput(e, patent.id)}
                        minChar={0}
                        style={{ margin: '0 1em -0.5em 0', flexGrow: 1, border: 'gray solid 1px', background: 'white', height: '6em', fontSize: '0.8em' }}
                        itemStyle={{ fontSize: '0.8em' }}
                        loadingComponent={() => <span>Loading</span>}
                        trigger={{
                            "@": {
                                dataProvider: token => {
                                    return this.state.reportUsers.filter(n => n.name.toLowerCase()
                                        .indexOf(token.toLowerCase()) === 0);
                                },
                                component: Item,
                                output: (item, trigger) => `@${item.name}`
                            }
                        }}/>
                    <DefaultButton style={{marginLeft: '0.5em'}} onClick={() => this.postToDiscussion(patent.id)}>Send</DefaultButton></div></div></div>);

                cellClass = (cellClass === 'patent-surveillance-grid-oddcell') ? 'patent-surveillance-grid-evencell' : 'patent-surveillance-grid-oddcell';
            });

            var appDateHeader = undefined, commentsHeader = undefined, drawingsHeader = undefined;
            if (showAppDate)
                appDateHeader = <div className='patent-surveillance-grid-header'>Application Date</div>;

            if (showMagicComments) {
                commentsHeader = <div className='patent-surveillance-grid-header'>Comments</div>;
            }

            if (showDrawings) {
                drawingsHeader = <div className='patent-surveillance-grid-header'>Drawings</div>;
            }

            gridDiv = <div className='patent-surveillance-grid' style={{ gridTemplateColumns: gridTemplateColumns }}>
                <div className='patent-surveillance-grid-header' style={{ cursor: 'pointer' }} onClick={() => this
                    .applicantSort()}>Patent</div>
                {appDateHeader}
                <div className='patent-surveillance-grid-header'>Opposition Deadline</div>
                {commentsHeader}
                <div className='patent-surveillance-grid-header'>First Claim</div>
                {drawingsHeader}
                <div className='patent-surveillance-grid-header' style={{ cursor: 'pointer' }} onClick={() => this
                    .relavenceSort()}>Relevance</div>
                <div className='patent-surveillance-grid-header'>Chat</div>
                {gridCells}
            </div>;
        }

        var zoomedImage = undefined;
        if (this.state.zoomedImageUrl) {
            zoomedImage =
                <div style={{ background: 'rgba(0, 0, 0, 0.75)', width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, display: 'flex' }} onClick={() => this.openImage()}>
                    <img src={this.state.zoomedImageUrl} style={{ maxWidth: '90vw', maxHeight: '90vh', background: '#fff', margin: 'auto', border: '1em white solid' }} />
                </div>;
        }

        if (!gridDiv)
            return <div />;

        var applicantOptions = [{ key: undefined, text: '' }];
        if (this.state.patentList) {
            var allApplicants = this.state.patentList.map(p => p.applicant);
            var unique = allApplicants.filter((value, index, allApplicants) => allApplicants.indexOf(value) === index).sort();
            unique.forEach(a => {
                applicantOptions.push({ key: a, text: a });
            });
        }
        return (
            <div style={{ height: '100%', padding: '1em', overflowY: 'auto' }}>
                <h2>{this.state.reportName}</h2>
                <div style={{ display: 'flex' }}><div style={{margin: 'auto 0'}}>Filter by applicant:</div><Dropdown id='filtercombo' options={applicantOptions} onChange={(e, o) => this.filterChange(e, o)} style={{ marginLeft: '0.5em', width: '30em'}}/></div>
                {gridDiv}
                {zoomedImage}
                <div style={{ display: 'grid', marginTop: '1em', gap: '1em', gridTemplateColumns: '1fr auto 9em 9em 9em' }}>
                    <div />
                    <div style={{ margin: 'auto' }}>Download publication numbers:</div>
                    <DefaultButton id='pubNumButtonH' onClick={() => this.downloadRefs('high')}>High only</DefaultButton>
                    <DefaultButton id='pubNumButtonHM' onClick={() => this.downloadRefs('medium')}>High & Medium</DefaultButton>
                    <DefaultButton id='pubNumButtonHML' onClick={() => this.downloadRefs('all')}>All</DefaultButton>
                </div>
                <div style={{ display: 'none' }}>
                    <input ref={this.fileInputRef} type="file" accept=".png,.jpg" onChange={(e) => { this.handleInputFileChange(e); }} />
                </div>
            </div>
        );
    }
}

export default withRouter(ReportView);
