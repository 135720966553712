import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class GPTModelRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    click() {
        if (this.props.data['featuresAvailable'].some(f => f === this.props.feature)) {
            this.props.data['featuresAvailable'] = this.props.data['featuresAvailable'].filter(f => f !== this.props.feature);
        } else {
            this.props.data['featuresAvailable'].push(this.props.feature);
        }

        var headers = {};
        headers['Authorization'] = 'Bearer ' + localStorage.getItem("bearerToken");
        fetch('/api/admin/featureaccess?email=' +
            this.props.data.userEmail +
            '&feature=' + this.props.feature + 
            '&enabled=' +
            this.props.data['featuresAvailable'].some(f => f === this.props.feature),
            { headers: headers, method: 'post' }).then((response) => {
            return response.text();
        }).then(respText => {
        });
    }

    render() {
        if (this.props.data['featuresAvailable'].some(f => f === 'CHATGPT')) {
            return (
                <input type='select'>
                </input>
            );
        } else {
            return (<div/>);
        }
    }
}

export default GPTModelRenderer;
