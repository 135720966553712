import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { Navigate, useLocation } from 'react-router-dom';
import PatentSurveillanceComponent from '../PatentSurveillanceComponent';
import ReportListMonth from './ReportListMonth';
import './ReportList.css';

class ReportListView extends PatentSurveillanceComponent {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount = () => {
        super.componentDidMount();
        window.addEventListener('clientchange',
            (evt) => {
                console.log('clientchange triggered with new client id ' + evt.detail);
                this.state.userInfo.clientId = evt.detail;
                this.setState((prevState => {
                    return ({
                        ...prevState,
                        userInfo: this.state.userInfo
                    });
                }));

                this.refreshReports();
            });

        console.log('ReportListView mounted');
        this.refreshReports();
    }

    refreshReports() {
        console.log('Refresh reports');
        fetch('/api/patentsurveillance/reportlist',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            reportList: resp
                        });
                    });
                });
            }
        });

        fetch('/api/patentsurveillance/combinedreportlist',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            combinedReportList: resp
                        });
                    });
                });
            }
        });
    }

    initEventHandlers() {
    }

    openCombinedReport(name) {
        var reportUrl = '/patentsurveillance/report/' + name;
        this.props.navigate(reportUrl);
    }

    authenticatedRender() {
        this.initEventHandlers();

        var months = [];
        if (this.state.reportList) {
            this.state.reportList.forEach(month => {
                months.push(<ReportListMonth key={month.key} month={month} isSuperAdmin={this.state.userInfo.isSuperAdmin}/>);
            });
        }

        var promptEditor = undefined;
        /*
        if (this.state.userInfo.isSuperAdmin)
            promptEditor = <PromptEditor />;
        */
        var combinedLinks = [];
        if (this.state.combinedReportList) {
            this.state.combinedReportList.forEach(combinedReport => {
                var combinedReportKey = 'combinedReport~' + combinedReport;
                combinedLinks.push(<div key={combinedReportKey}><DefaultButton onClick={() => this.openCombinedReport(combinedReport)}>All {combinedReport}</DefaultButton></div>);
            });
        }

        return (
            <div style={{height: '100%', padding: '1em', overflowY: 'auto'}}>
                <div>
                    Welcome, {this.state.userInfo.name}
                </div>
                <div className='combinedLinkList'>
                    {combinedLinks}
                </div>
                <div>
                    {months}
                </div>
                {promptEditor}
            </div>
        );
    }
}

export default withRouter(ReportListView);
