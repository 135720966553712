import React, { Component } from 'react';
import { PrimaryButton } from '@fluentui/react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class AutoDisableButton extends Component {

    constructor(props) {
        super(props);
        var defaultDisabled = false;
        if (props.defaultDisabled)
            defaultDisabled = props.defaultDisabled;
        this.state = { disabled: defaultDisabled };
    }

    buttonClicked() {
        this.setState({ disabled: true });
        this.props.action();
    }

    disableButton() {
        this.setState({ disabled: true });
    }

    enableButton() {
        this.setState({ disabled: false });
    }

    render() {
        return (
            <PrimaryButton text={this.props.label} disabled={this.state.disabled} onClick={() => this.buttonClicked()} />
        );
    }
}

export default AutoDisableButton;
