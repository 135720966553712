import React, { Component } from 'react';
import {PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';

class TextDocumentEntry extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            id: props.document.id,
            name: props.document.name,
            description: props.document.description,
            text: props.document.text
        };
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    onNameInput(evt) {
        if (this.nameTimer) {
            clearTimeout(this.nameTimer);
        }

        var newName = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                name: newName
            });
        });
        this.nameTimer = setTimeout(() => { this.saveName(newName) }, 2500);
    }

    saveName(newName) {
        fetch('/api/documentlibrary/' + this.props.document.id + '/name',
            {
                method: 'post',
                body: JSON.stringify(newName),
                headers:
                {
                    'API-KEY': this.props.params.apikey,
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }
    onDescriptionInput(evt) {
        if (this.descriptionTimer) {
            clearTimeout(this.descriptionTimer);
        }

        var newDescription = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                description: newDescription
            });
        });
        this.descriptionTimer = setTimeout(() => { this.saveDescription(newDescription) }, 2500);
    }

    saveDescription(newDescription) {
        fetch('/api/documentlibrary/' + this.props.document.id + '/description',
            {
                method: 'post',
                body: JSON.stringify(newDescription),
                headers:
                {
                    'API-KEY': this.props.params.apikey,
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }
    onTextInput(evt) {
        if (this.textTimer) {
            clearTimeout(this.textTimer);
        }

        var newText = evt.currentTarget.value;
        this.setState((prevState) => {
            return ({
                ...prevState,
                text: newText
            });
        });
        this.textTimer = setTimeout(() => { this.saveText(newText) }, 2500);
    }

    saveText(newText) {
        fetch('/api/documentlibrary/' + this.props.document.id + '/text',
            {
                method: 'post',
                body: JSON.stringify(newText),
                headers:
                {
                    'API-KEY': this.props.params.apikey,
                    'Content-Type': 'application/json'
                }
            }).then(response => {
            if (response.ok) {
            }
        });
    }


    render() {
        this.initEventHandlers();

        return (<tr>
                    <td style={{verticalAlign: 'top'}}>
                <input style={{ width: '100%' }} value={this.state.name} onInput={(e) => this.onNameInput(e)}></input>
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                <input style={{ width: '100%' }} value={this.state.description} onInput={(e) => this.onDescriptionInput(e)}></input>
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                        <textarea style={{ width: '100%', minHeight: '200px' }} value={this.state.text} onInput={(e) => this.onTextInput(e)}></textarea>
                    </td>
        </tr>);
    }
}

export default TextDocumentEntry;
