import React, { Component } from 'react';
import { MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import { PMHeader } from '../HeadersFooters/PatentMakerHeader';
import AmendmentsSection from './AmendmentsSection'
import ClaritySection from './ClaritySection'
import NoveltySection from './NoveltySection'
import InventiveStepSection from './InventiveStepSection'

class OfficeActionReport extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            status: 'loading',
            report: undefined
        };

        this.amendmentsSectionRef = React.createRef();
        this.claritySectionRef = React.createRef();
        this.noveltySectionRef = React.createRef();
        this.inventiveStepSectionRef = React.createRef();
    }

    componentDidMount = () => {
        this.openReport();
    }

    initEventHandlers() {
    }

    openReport() {
        fetch('/api/officeactionreport/' + this.props.params.reportkey,
            {
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        if (resp.externalReport) {
                            var externalUrl = resp.externalReportBaseUrl + '/app/officeactionreport/' + this.props.params.reportkey;
                            window.location.replace(externalUrl);
                            return;
                        }

                        if (resp.status == 'E') {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'error'
                                });
                            });
                        } else if (resp.status == 'P') {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'processing'
                                });
                            });

                        } else {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'ready',
                                    report: resp
                                });
                            });

                            var age = Date.now() - Date.parse(resp.timestamp + 'Z');
                            var timeUp = age > 60 * 60 * 1000;
                            if (timeUp) {
                                if (resp.amendmentsStatus === 'P')
                                    resp.amendmentsStatus = 'E';
                                if (resp.clarityStatus === 'P')
                                    resp.clarityStatus = 'E';
                                if (resp.noveltyStatus === 'P')
                                    resp.noveltyStatus = 'E';
                                if (resp.inventiveStepStatus === 'P')
                                    resp.inventiveStepStatus = 'E';
                            }
                            this.pushSections(resp);

                            // Stop polling once the report is an hour old
                            if(!timeUp)
                                setTimeout(() => this.openReport(), 5000);
                        }
                    });
                } else {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            status: 'error'
                        });
                    });
                }
            });
    }

    pushSections(report) {
        if (report) {
            if (this.amendmentsSectionRef.current) {
                this.amendmentsSectionRef.current.loadSection(report.amendmentsStatus,
                    report.amendmentsLastChangedDate);
            }

            if (this.claritySectionRef.current) {
                this.claritySectionRef.current.loadSection(report.clarityStatus,
                    report.clarityLastChangedDate);
            }

            if (this.noveltySectionRef.current) {
                this.noveltySectionRef.current.loadSection(report.noveltyStatus,
                    report.noveltyLastChangedDate);
            }

            if (this.inventiveStepSectionRef.current) {
                this.inventiveStepSectionRef.current.loadSection(report.inventiveStepStatus,
                    report.inventiveStepLastChangedDate);
            }
        }
    }

    render() {
        this.initEventHandlers();

        if (this.state.status === 'error') {
            return (
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                    <div style={{ gridRow: 1 }} >
                        <PMHeader header='Office Action Report' />
                    </div>
                    <div style={{ gridRow: 2, margin: '1em' }} >
                        An error occurred while generating your report.
                    </div>
                </div>
            );
        } 
        else {
            var headerInfo = undefined;
            if (this.state.status === 'ready')
                headerInfo = (
                    <div style={{ margin: '1em' }}>
                        <h3><span>Case Number: {this.state.report.caseNumber}</span>
                            <span style={{ marginLeft: '2em' }}>Application Number: {this.state.report.applicationNumber}</span></h3>
                    </div>
                    );
            return (
                <div style={{ height: '100%' }}>
                    <div>
                        <PMHeader header='Office Action Report' />
                    </div>

                    {headerInfo}

                    <div style={{ margin: '1em' }}>
                        <AmendmentsSection ref={this.amendmentsSectionRef} reportkey={this.props.params.reportkey} />
                    </div>
                    <div style={{ margin: '2em 1em 1em 1em' }}>
                        <ClaritySection ref={this.claritySectionRef} reportkey={this.props.params.reportkey} />
                    </div>
                    <div style={{ margin: '2em 1em 1em 1em' }}>
                        <NoveltySection ref={this.noveltySectionRef} reportkey={this.props.params.reportkey} />
                    </div>
                    <div style={{ margin: '2em 1em 1em 1em', paddingBottom: '1em' }}>
                        <InventiveStepSection ref={this.inventiveStepSectionRef} reportkey={this.props.params.reportkey} />
                    </div>

                </div>
            );
        } 
    }
}

export default withRouter(OfficeActionReport);
