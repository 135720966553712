import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import FTOSearchInputPanel from './FTOSearchInputPanel';
import FTOSearchOutputPanel from './FTOSearchOutputPanel';

class FTOSearchView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };

        this.inputPanelRef = React.createRef();
        this.outputPanelRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    workLoop = (patentNumbers, onComplete) => {
        if (patentNumbers.length === 0) {
            onComplete();
            return;
        }

        $.ajax({
            url: '/api/claims/patentclaims/' + patentNumbers[0],
            type: 'GET',
            headers: {
                'API-KEY': this.props.params.apikey
            },
            success: (result) => {
                console.log('Success: ' + result);
                this.outputPanelRef.current.addPatent({ patentNumber: result.publicationNumber, claims: [] });
                result.claims.forEach(c => {
                    var claimNum = undefined;
                    var claimText = c;
                    var numberMatch = c.match(/^(?<number>\s*\d+)\./);
                    if (numberMatch) {
                        claimNum = numberMatch.groups.number;
                        claimText = c.substring(claimNum.length + 1).trim();
                        claimNum = claimNum.trim();
                    }
                    this.outputPanelRef.current.addUpdateClaim(result.publicationNumber,
                        {
                            claimNumber: claimNum,
                            claimText: claimText
                        });
                });
                this.workLoop(patentNumbers.slice(1), onComplete);
            },
            error: (result) => {
                this.outputPanelRef.current.addPatent({ patentNumber: patentNumbers[0], status: 'error', message: 'Failed to fetch patent claims.' });
                this.workLoop(patentNumbers.slice(1), onComplete);
            }
        });
    }

    performSearch() {
        this.outputPanelRef.current.clearPatents();

        var patentList = $("#patents-box").val();
        var descText = $("#description-box").val().trim();

        var patentNumbers = [];
        patentList.split('\n').map(s => s.trim()).filter(s => s.length > 0).forEach(patentNumber => {
            patentNumbers.push(patentNumber);
        });

        this.workLoop(patentNumbers, () => {
            if(descText.length !== 0)
                this.outputPanelRef.current.callGPT(descText, this.props.params.apikey);
        });
    }

    generateClaimsSheet() {
        var inputPanel = this.inputPanelRef.current;
        var patentList = $("#patents-box").val();
        var patentNumbers = [];
        patentList.split('\n').map(s => s.trim()).filter(s => s.length > 0).forEach(patentNumber => {
            patentNumbers.push(patentNumber);
        });

        $.ajax({
            url: "/api/claims/patentclaims/claimssheet",
            type: "POST",
            headers: {
                'API-KEY': this.props.params.apikey
            },
            data: JSON.stringify(patentNumbers),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                window.location.href = "/api/claims/downloadexcel/" + result;
                inputPanel.enableExcelButton();
            }
        });
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{height: '100%'}}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1, minWidth: '30em'}}>
                        <FTOSearchInputPanel ref={this.inputPanelRef} doSearch={() => this.performSearch()} generateClaimsSheet={()=> this.generateClaimsSheet()} />
                    </div>
                    <div style={{ flexGrow: 2 }}>
                        <FTOSearchOutputPanel ref={this.outputPanelRef} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FTOSearchView);
