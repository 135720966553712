import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import NoveltyReportHeader from './NoveltyReportHeader';
import GeneralFeedbackPanel from './GeneralFeedbackPanel';
import ClaimEntryPanel from './ClaimEntryPanel';
import {PMHeader} from '../HeadersFooters/PatentMakerHeader';

class NoveltyReportView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            status: 'loading',
            report: undefined
        };

        this.caseHeaderRef = React.createRef();
        this.priorArtPanelRef = React.createRef();
        this.featureResponsePanelRef = React.createRef();

        this.openReport();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    openReport() {
        fetch('/api/noveltyreport/' + this.props.params.reportkey,
            {
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        if (resp.status == 'E') {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'error'
                                });
                            });
                        } else if (resp.status == 'P') {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'processing'
                                });
                            });

                            setTimeout(() => this.openReport(), 5000);
                        } else {
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    status: 'ready',
                                    report: resp
                                });
                            });
                        }

                        //this.caseHeaderRef.current.loadCase(resp);
                        //this.priorArtPanelRef.current.loadCase(resp.id);
                        //this.featureResponsePanelRef.current.loadFeatures(resp.id);
                    });
                } else {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            status: 'error'
                        });
                    });
                }
        });
    }

    loadPriorArt(priorArtId) {
        // Also reload the features, since they may have been modified in the previous prior art
        this.featureResponsePanelRef.current.loadFeatures(this.state.selectedCase.id);
        this.featureResponsePanelRef.current.loadPriorArt(priorArtId);
    }

    render() {
        this.initEventHandlers();

        if (this.state.status === 'ready') {
            var feedbackDisplay = this.state.report.offerFeedback ? '' : 'none';

            return (
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto auto 1fr auto' }}>
                    <div style={{ gridRow: 1 }} >
                        <PMHeader header='Novelty Report' />
                    </div>
                    <div style={{ gridRow: 2 }} >
                        <NoveltyReportHeader ref={this.caseHeaderRef} report={this.state.report} openCase={(caseId) => this.openCase(caseId)} />
                    </div>
                    <div style={{ gridRow: 3 }}>
                        <ClaimEntryPanel ref={this.priorArtPanelRef} claims={this.state.report.claims} feedbackDisplay={feedbackDisplay} />
                    </div>
                    <div style={{ gridRow: 4, display:feedbackDisplay }} >
                        <GeneralFeedbackPanel report={this.state.report} />
                    </div>
                </div>
            );
        } else if (this.state.status === 'error') {
            return (
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                    <div style={{ gridRow: 1 }} >
                        <PMHeader header='Novelty Report' />
                    </div>
                    <div style={{ gridRow: 2, margin: '1em' }} >
                        An error occurred while generating your report.
                    </div>
                </div>
            );
        } else if (this.state.status === 'loading') {
            return (
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                    <div style={{ gridRow: 1 }} >
                        <PMHeader header='Novelty Report' />
                    </div>
                    <div style={{ gridRow: 2, margin: '1em' }} >
                        Loading...
                    </div>
                </div>
            );
        } else if (this.state.status === 'processing') {
            return (
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                    <div style={{ gridRow: 1 }} >
                        <PMHeader header='Novelty Report' />
                    </div>
                    <div style={{ gridRow: 2, margin: '1em' }} >
                        Your report is being generated. Please wait...
                    </div>
                </div>
            );
        }

        return <div/>;
    }
}

export default withRouter(NoveltyReportView);
