import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import FilterControls from './FilterControls';

import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';
import ReportIdRenderer from './CellRenderers/ReportIdRenderer';
import ReportStatusRenderer from './CellRenderers/ReportStatusRenderer';
import PromptStatusRenderer from './CellRenderers/PromptStatusRenderer';


class OAReportsView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { reports: null, showPromptPopup: false };
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
        this.gridRef = React.createRef();
        this.columnDefs = [
            { field: 'id', sortable: true, width: 300, cellRenderer: ReportIdRenderer },
            { headerName: 'User Email', field: 'userEmail', sortable: true, width: 300 },
            { headerName: 'Date/Time', field: 'timestampText', sortable: true, width: 200 },
            { field: 'caseNumber', sortable: true, width: 150 },
            { headerName: 'App. Number', field: 'applicationNumber', sortable: true, width: 150 },
            { headerName: '# Prior Art', field: 'priorArtCount', sortable: true, width: 150 },
            { headerName: '# Claims', field: 'claimCount', sortable: true, width: 150 },
            { headerName: 'Status', cellRenderer: ReportStatusRenderer },
            {
                headerName: 'Prompts', cellRenderer: PromptStatusRenderer, cellRendererParams:
                {
                    openPromptPopup: (parentId) => this.openPromptPopup(parentId)
                }
            },
            {
                // Not sortable for now, since it's implemented as a text field
                headerName: 'Cost', field: 'gptCostText', sortable: false, width: 150
            }
        ];

        this.gridOptions = {
            columnDefs: this.columnDefs,
            onGridReady: (event) => {
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
                this.gridRef.current.api.resetRowHeights();
            },
            onFilterChanged: () => {
            },
            suppressRowVirtualisation: true
        };
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    filterSelected(group, timeWindow) {
        var gridApi = this.gridOptions.api;
        if (group === undefined || timeWindow === undefined)
            return;

        fetch('/api/admin/oareports/' + group + '/' + timeWindow,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(resp => {
                        gridApi.setRowData(resp);
                        gridApi.sizeColumnsToFit();
                    });
                }
            });
    }

    openPromptPopup(parentId) {
        fetch('/api/admin/actionprompts/' + parentId,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            showPromptPopup: true,
                            actionPrompts: resp
                        });
                    });
                });
            }
        });
    }

    closePromptPopup() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                showPromptPopup: false
            });
        });
    }

    render() {

        var promptDetailsPopup = undefined;
        if (this.state.showPromptPopup) {
            var rows = [];
            this.state.actionPrompts.forEach((prompt) => {
                var statusColour = 'black';
                if (prompt.statusText === 'Complete')
                    statusColour = 'green';
                if (prompt.statusText === 'Error')
                    statusColour = 'red';

                rows.push(
                    <tr>
                        <td style={{ padding: '0.25em 1.5em'}}>{prompt.description}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.promptTokens}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.completionTokens}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.timestampQueuedText}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.timestampSentText}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.timestampCompletedText}</td>
                        <td style={{ padding: '0.25em 1.5em' }}>{prompt.processingTimeText}</td>
                        <td style={{ padding: '0.25em 1.5em', color: statusColour }}>{prompt.statusText}</td>
                    </tr>);
            });
            promptDetailsPopup =
                <div style={{ background: 'rgba(0, 0, 0, 0.75)', width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0, display: 'flex' }} onClick={() => this.closePromptPopup()}>
                    <div style={{ background: 'white', maxHeight: '90vh', maxWidth: '90vw', overflowY: 'auto', padding: '1em', margin: 'auto' }}>
                        <table>
                            <thead>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Prompt Description</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Prompt Tokens</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Completion Tokens</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Time Received</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Time Sent</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Time Completed</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Processing Time</td>
                                <td style={{ padding: '0.25em 1.5em', fontWeight: 'bold' }}>Status</td>
                            </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    </div>
                </div>;
        }


        return (
            <div className='main-content' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <FilterControls ref={this.filterRef} onChange={(g, w) => this.filterSelected(g, w)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <AgGridReact className="ag-theme-alpine"
                             ref={this.gridRef}
                    gridOptions={this.gridOptions} />
                {promptDetailsPopup}
            </div>
        );
    }
}

export default withRouter(OAReportsView);
