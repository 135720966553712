import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../../WithRouter';
import { Navigate, useLocation } from 'react-router-dom';
import PatentSurveillanceComponent from '../PatentSurveillanceComponent';
import ChangePassword from './ChangePassword';
import MasterClientConfig from './MasterClientConfig';
import ClientUserConfig from './ClientUserConfig';

class SettingsView extends PatentSurveillanceComponent {
    static displayName = "";
    //static navigate = useNavigate();

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = () => {
        super.componentDidMount();

        window.addEventListener('clientchange',
            (evt) => {
                console.log('clientchange triggered with new client id ' + evt.detail);
                this.state.userInfo.clientId = evt.detail;
                this.setState((prevState => {
                    return ({
                        ...prevState,
                        userInfo: this.state.userInfo
                    });
                }));

                this.refreshSettings();
            });

        this.refreshSettings();
    }

    refreshSettings() {
        /*
        fetch('/api/patentsurveillance/settings',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': localStorage.getItem('patentsurveillance-jwt')
                }
            }).then(response => {
            if (response.ok) {
                response.json().then((resp) => {
                    this.setState((prevState) => {
                        return ({
                            ...prevState,
                            settings: resp
                        });
                    });
                });
            }
        });
        */
    }

    initEventHandlers() {
    }

    authenticatedRender() {
        this.initEventHandlers();

        var configSections = [];
        configSections.push(<ChangePassword />);
        if(this.state.userInfo.isSuperAdmin)
            configSections.push(<div style={{ marginTop: '2em' }}><MasterClientConfig clientId={this.state.userInfo.clientId} /></div>);
        if (this.state.userInfo.isClientAdmin)
            configSections.push(<div style={{ marginTop: '2em' }}><ClientUserConfig clientId={this.state.userInfo.clientId} /></div >);

        return (
            <div style={{ padding: '1.0em', overflowY: 'auto' }}>
                {configSections}
            </div>
        );
    }
}

export default withRouter(SettingsView);
