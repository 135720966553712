import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import { Line } from 'react-chartjs-2';
import FilterControls from './FilterControls';

import { CategoryScale, LinearScale, PointElement, Filler, LineElement, Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';

ChartJS.register(ArcElement, Tooltip, Legend, Filler, CategoryScale, LinearScale, PointElement, LineElement);

class AIActivityView extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = {aiChunks: null};
        this.debugUsername = 'iod@patentmaker.eu';
        this.filterRef = React.createRef();
    }


    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    filterSelected(group, timeWindow, model) {
        if (group === undefined || timeWindow === undefined || model === undefined)
            return;

        fetch('/api/admin/ai/' + group + '/' + timeWindow + '/' + model,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                }
            }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    this.setState({
                        aiChunks: resp
                    });
                });
            }
        });
    }

    render() {
        var options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    stacked: true
                }
            }
        };
        var chart = undefined;
        if (this.state.aiChunks) {
            var data = {
                labels: this.state.aiChunks.map(c => c.label),
                datasets: [
                    {
                        label: 'Error',
                        data: this.state.aiChunks.map(c => c.errorCount),
                        fill: true,
                        borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: 'rgb(255, 128, 128)',
                        tension: 0.15
                    },
                    {
                        label: 'Timeout',
                        data: this.state.aiChunks.map(c => c.timeoutCount),
                        fill: true,
                        borderColor: 'rgb(255, 120, 0)',
                        backgroundColor: 'rgb(255, 192, 128)',
                        tension: 0.15
                    }, {
                        label: 'Throttled',
                        data: this.state.aiChunks.map(c => c.throttleCount),
                        fill: true,
                        borderColor: 'rgb(255, 192, 0)',
                        backgroundColor: 'rgb(255, 255, 128)',
                        tension: 0.15
                    },
                    {
                        label: 'Complete',
                        data: this.state.aiChunks.map(c => c.successCount),
                        fill: true,
                        borderColor: 'rgb(0, 255, 0)',
                        backgroundColor: 'rgb(192, 255, 192)',
                        tension: 0.15
                    },
                ]
            };

            chart = <Line options={options} data={data} style={{ maxWidth: '100%', maxHeight: '100%', height: '100%!important' }} />;
        }

        return (
            <div className='main-content' style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{ display: 'flex', margin:'10px' }}>
                    <FilterControls ref={this.filterRef} onChange={(g, w, m) => this.filterSelected(g, w, m)} />
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <div style={{ background: 'white', flexGrow: 1, width: '100%', position: 'relative' }} >
                    <div style={{position:'absolute', top:0, right: 0, bottom: 0, left: 0}}>
                        {chart}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AIActivityView);
