import React from 'react';
import TeamsComponent from '../TeamsComponent';
import { Link, PrimaryButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './UPCContent.css';
import './ClientSelector.css';
import { withRouter } from '../WithRouter';
import SuggestedClientList from './SuggestedClientList';

class ClientSearch extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { myEmail: undefined, searchResults: [], message: undefined };
        this.suggestedClientListRef = React.createRef();
        this.debugUsername = 'donnell@boehmert.org'
        this.debugDisplayName = 'Ian O\'Donnell';
    }

    teamsReady(myEmail, myName) {
        $.ajax({
            url: '/api/upc/secretaryinfo?myEmail=' + myEmail,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            success: (secretaryInfo) => {
                var attorneyEmail;
                if (secretaryInfo?.attorneyEmail) {
                    $("#display-name").text('Welcome, ' + myName + ', working for ' + secretaryInfo.attorneyName);
                    attorneyEmail = secretaryInfo.attorneyEmail;
                } else {
                    $("#display-name").text('Welcome, ' + myName);
                    attorneyEmail = myEmail;
                }
                this.setState({ myEmail: attorneyEmail });
            }
        });
    }

    performSearch() {
        this.setState({ myEmail: this.state.myEmail, searchResults: [], message: 'Searching...' });
        var searchText = $("#search-text").val();
        fetch('/api/upc/clientnamesearch?searchString=' + searchText, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    if (resp.length !== 0) {
                        this.setState({ myEmail: this.state.myEmail, searchResults: resp, message: undefined });
                    } else {
                        this.setState({ myEmail: this.state.myEmail, searchResults: resp, message: 'No applicants found' });
                    }
                })
            }
        })
    }

    addClient(name) {
        fetch('/api/upc/clientid?clientName=' + name + '&createIfMissing=Y', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            }
        }).then(response => {
            if (response.ok) {
                response.text().then(resp => {
                    var updatedResults = [];
                    this.state.searchResults.forEach(result => {
                        var newResult = result;
                        if (newResult === '~' + name)
                            newResult = resp + '~' + name;

                        updatedResults.push(newResult);
                    });

                    this.setState({ myEmail: this.state.myEmail, searchResults: updatedResults });
                })
            }
        })
    }

    selectClient(clientId) {
        var nav = this.props.navigate;
        var clientUrl = "/upcoptout/client/" + clientId;
        nav(clientUrl);
    }

    render() {
        var clientLink = (<span>&nbsp;-&nbsp;<a href='/upcoptout?teams=y'>View clients</a></span>);
        var partnerLink = (<span>&nbsp;-&nbsp;<a href='/upcoptout/partners?teams=y'>View contacts</a></span>);

        var resultRows = [];
        if (this.state.message) {
            resultRows.push(<tr><td colSpan='2'><i>{this.state.message}</i></td></tr>);
        }
        if (this.state.searchResults.length !== 0) {
            resultRows.push(<tr><td colSpan='2'><u>Results</u></td></tr>);
        } 
        this.state.searchResults.forEach(result => {
            var split = result.split('~');
            if (split[0].length > 0) {
                resultRows.push(<tr><td>{split[1]}</td><td><Link style={{ marginLeft: '1em' }} onClick={() => this.selectClient(split[0])}>Open existing</Link></td></tr>);
            }
            else {
                resultRows.push(<tr><td>{split[1]}</td><td><Link style={{marginLeft:'1em'}} onClick={() => this.addClient(split[1])}>Add new</Link></td></tr>);
            }
        });
        return (
            <div className="client-selector" id="main-content">
                <div id='greeting'>
                    <span id="display-name" />
                    {clientLink}
                    {partnerLink}
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan='2'>Client Name:<input id='search-text' style={{ verticalAlign: 'middle' }} />
                                <PrimaryButton onClick={() => this.performSearch()}>Search</PrimaryButton></td>
                        </tr>
                        {resultRows}
                    </tbody>
                </table>

            </div>
        );
    }
}

export default withRouter(ClientSearch);
