import { Component } from 'react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import * as microsoftTeams from "@microsoft/teams-js";
import { Navigate, useLocation } from 'react-router-dom';


class PatentSurveillanceComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const token = localStorage.getItem('patentsurveillance-jwt');
        if (!token) {
            this.setState((prevState) => {
                return ({
                    ...prevState,
                    authenticated: false
                });
            });

            return;
        }

        // TODO: Validate token
        fetch('/api/patentsurveillance/auth/validate',
            {
                method: 'get',
                headers:
                {
                    'jwt-token': token
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then((resp) => {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                userInfo: resp,
                                authenticated: true
                            });
                    });
                });
            } else {
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        authenticated: false
                    });
                });
            }
        });
    }

    render() {
        if(this.state.authenticated)
            return this.authenticatedRender();

        if(this.state.authenticated === false)
            // Maybe we could save the current location, to return to it after logging in?
            return <Navigate to='/PatentSurveillance' replace />;

        return <div/>;
    }
}

export default PatentSurveillanceComponent;