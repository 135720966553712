import React, { Component } from 'react';
import './Header.css';
import './Boehmert.css';
import logo from './Images/Boehmert.png';

export class BBUPCHeader extends Component {
    static displayName = BBUPCHeader.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header>
                <div className="header header-boehmert">
                    <div>
                        Boehmert UPC Tool
                    </div>
                    <img className="logo" src={logo} />
                </div>
            </header>
        );
    }
}
