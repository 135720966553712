import React, { Component } from 'react';
import TeamsComponent from '../TeamsComponent';
import { Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import './TemplateList.css';
import { withRouter } from '../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import ResponseOptionsRenderer from './CellRenderers/ResponseOptionsRenderer';
import IconTextRenderer from './CellRenderers/IconTextRenderer';
import TemplateFilterControls from './TemplateFilterControls';
import CreateNewTemplateDialog from './CreateNewTemplateDialog';

class TemplateList extends TeamsComponent {

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.filterRef = React.createRef();
        this.debugUsername = 'iod@patentmaker.eu';
        this.debugDisplayName = 'Ian O\'Donnell';
    }

    componentDidMount() {
        super.componentDidMount();
    }

    teamsReady(email) {
        console.log('teamsReady() called for user ' + email);

        this.fetchDataWhenReady();
    }

    fetchDataWhenReady() {
        if (this.gridRef.current.api) {
            this.renderDataInTheTable(this.gridRef.current.api);
        } else {
            console.log('Waiting for grid...');
            window.setTimeout(() => { this.fetchDataWhenReady(); }, 1);
        }
    }

    renderDataInTheTable(api) {
        fetch('/api/templates', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            }
        }).then(response => {
            console.log(this);
            return response.json();
        }).then(data => {
            console.log(this);
            var anyPersonal = false;
            data.forEach(template => {
                if (template.group === '') {
                    template.groupLabel = 'Everybody';
                    template.groupFilter = 'Y';
                }
                else if (template.group === window.teamsEmail) {
                    template.groupLabel = window.teamsDisplayName;
                    template.groupFilter = 'Y';
                    anyPersonal = true;
                }
                else {
                    template.groupLabel = 'Group ' + template.group;
                    template.groupFilter = 'N';
                }
            });

            if (anyPersonal) {
                api.columnModel.applyColumnState({ state: [{ colId: 'groupLabel', hide: false }] });
            }

            api.setRowData(data);

            //$("#boehmert-only-checkbox").prop('checked', true).change();
            var groupFilter = api.getFilterInstance('groupFilter');
            groupFilter.setModel({
                filterType: 'text',
                type: 'equals',
                filter: 'Y'
            });
            api.onFilterChanged();

            this.filterRef.current.onChangePatentOffice(undefined, { key: 'EPO' });
            this.filterRef.current.prepareGroups();
            api.sizeColumnsToFit();
        });
    }

    initEventHandlers() {
    }
    
    initGrid() {
        // React reference
        this.gridRef = this.props.useRef;
        this.columnDefs = [
            { field: 'trigger', filter: 'agTextColumnFilter', sortable: true, width: 200, suppressSizeToFit: true, sort: 'asc' },
            { field: 'client', filter: 'agTextColumnFilter', sortable: true, width: 150, suppressSizeToFit: true },
            { field: 'extraInfo', headerName: 'Description', filter: 'agTextColumnFilter', sortable: true },
            { field: 'groupLabel', headerName: 'Availability', cellRenderer: IconTextRenderer, filter: 'agTextColumnFilter', sortable: true, width: 250, suppressSizeToFit: true },
            { field: 'templates', filter: 'agTextColumnFilter', cellRenderer: ResponseOptionsRenderer, autoHeight: true, width: 300, suppressSizeToFit: true },
            { field: 'office', filter: 'agTextColumnFilter', sortable: true, hide: true },
            { field: 'groupFilter', filter: 'agTextColumnFilter', hide: true}
        ];

        this.gridOptions = {
            suppressDragLeaveHidesColumns: true,
            columnDefs: this.columnDefs,
            onGridReady: (event) => {
                // this.renderDataInTheTable(event.api);
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
            },
            onFilterChanged: () => {
            }
        };

        this.gridOptions.components = {
        }

        this.initEventHandlers();
    }

    render() {
        this.initGrid();
        return (
            <div className="grid-container template-grid-container">
                <TemplateFilterControls ref={this.filterRef} gridRef={this.gridRef} />
                <div id="maingrid-container" className="ag-theme-alpine">
                    <AgGridReact
                        ref={this.gridRef}
                        gridOptions={this.gridOptions} />
                </div>
                <CreateNewTemplateDialog />
            </div>
        );
    }
}

export default withRouter(TemplateList);
