import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { withRouter } from '../WithRouter';
import ClaimsLookupInputPanel from './ClaimsLookupInputPanel';
import ClaimsLookupOutputPanel from './ClaimsLookupOutputPanel';

class ClaimsLookupView extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };

        this.inputPanelRef = React.createRef();
        this.outputPanelRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }


    performSearch() {
        this.outputPanelRef.current.clearPatents();

        var patentList = $("#patents-box").val();
        var descText = $("#description-box").val().trim();

        var patentNumbers = [];
        patentList.split('\n').map(s => s.trim()).filter(s => s.length > 0).forEach(patentNumber => {
            patentNumbers.push(patentNumber);
        });

        this.workLoop(patentNumbers, () => {
            if(descText.length !== 0)
                this.outputPanelRef.current.callGPT(descText, this.props.params.apikey);
        });
    }

    generateClaimsSheet() {
        var inputPanel = this.inputPanelRef.current;
        var outputPanel = this.outputPanelRef.current;
        var patentList = $("#patents-box").val();
        var patentNumbers = [];
        patentList.split('\n').map(s => s.trim()).filter(s => s.length > 0).forEach(patentNumber => {
            patentNumbers.push(patentNumber);
        });

        var checkbox = inputPanel.independentClaimsOnlyRef.current;
        var independentClaimsOnly = checkbox.classList.contains('is-checked');

        $.ajax({
            url: "/api/claims/patentclaims/claimssheet?independentClaimsOnly=" + (independentClaimsOnly ? 'y' : 'n'),
            type: "POST",
            headers: {
                'API-KEY': this.props.params.apikey
            },
            data: JSON.stringify(patentNumbers),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                window.location.href = "/api/claims/downloadexcel/" + result;
                outputPanel.enableExcelButton();
            },
            error: function (error) {
                alert("Failed to create claims spreadsheet");
                outputPanel.enableExcelButton();
            }
        });
    }

    lookupClaims(independentClaimsOnly) {
        var inputPanel = this.inputPanelRef.current;
        var outputPanel = this.outputPanelRef.current;
        var patentList = $("#patents-box").val();
        var patentNumbers = [];
        patentList.split('\n').map(s => s.trim()).filter(s => s.length > 0).forEach(patentNumber => {
            patentNumbers.push(patentNumber);
        });

        $.ajax({
            url: "/api/claims/patentclaims/bulkclaims?independentClaimsOnly=" + (independentClaimsOnly ? 'y' : 'n'),
            type: "POST",
            headers: {
                'API-KEY': this.props.params.apikey
            },
            data: JSON.stringify(patentNumbers),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (result) {
                inputPanel.enableClaimLookupButton();
                outputPanel.setClaims(result);
            }
        });
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{height: '100%'}}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1, minWidth: '30em'}}>
                        <ClaimsLookupInputPanel ref={this.inputPanelRef} doSearch={() => this.performSearch()} lookupClaims={(independentClaimsOnly) => this.lookupClaims(independentClaimsOnly)} />
                    </div>
                    <div style={{ flexGrow: 2 }}>
                        <ClaimsLookupOutputPanel ref={this.outputPanelRef} generateClaimsSheet={() => this.generateClaimsSheet()} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ClaimsLookupView);
