import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton, Checkbox } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import AutoDisableButton from './AutoDisableButton';

class ClaimsLookupInputPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = { mandateTimestamps: [] };
        this.claimLookupButtonRef = React.createRef();
        this.independentClaimsOnlyRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    enableClaimLookupButton() {
        this.claimLookupButtonRef.current.enableButton();
    }

    performLookup() {
        var checkbox = this.independentClaimsOnlyRef.current;
        var filter = checkbox.classList.contains('is-checked');
        this.props.lookupClaims(filter);
    }

    render() {
        this.initEventHandlers();


        return (
            <div style={{height: '100%', padding: '0.5em', display: 'flex', flexDirection: 'column'}}>
                <div style={{fontWeight: 'bold'}}>Patents</div>
                <div style={{ flexGrow: 1 }}><textarea id='patents-box' style={{ height: '100%', width: '100%', resize: 'none' }} /></div>
                <div style={{ display: 'flex', marginTop: '1em' }}>
                    <div style={{ margin: 'auto 1em auto 0' }}>
                        <Checkbox ref={this.independentClaimsOnlyRef} label='Independent claims only' defaultChecked={true} />
                    </div>
                    <div style={{ }} >
                        <AutoDisableButton ref={this.claimLookupButtonRef} action={() => this.performLookup()} label='Find Claims' />
                    </div>
                </div>
                <div style={{ flexGrow: 1 }}/>
            </div>
        );
    }
}

export default ClaimsLookupInputPanel;
