import React, { Component } from 'react';
import { Dropdown, DropdownMenuItemType } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import * as microsoftTeams from "@microsoft/teams-js";

import './FilterControls.css';


class FilterControls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientOptions: [],
            windowOptions: [],
            defaultClientOption: '',
            defaultWindowOption: ''
        };

        this.clientFilterRef = React.createRef();
        this.windowFilterRef = React.createRef();
    }

    componentDidMount() {
    }


    loadOptions() {
        fetch('/api/admin/groups', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    var optionList = [];
                    resp.forEach(g => {
                        optionList.push({
                            key: g,
                            text: g
                        });
                    });

                    var defaultClientOption = 'All GPT Users';
                    if (window.pmusername === 'hofmann')
                        defaultClientOption = 'Boehmert';

                    this.setState({
                        clientOptions: optionList,
                        windowOptions: this.state.windowOptions,
                        defaultClientOption: defaultClientOption,
                        defaultWindowOption: this.state.defaultWindowOption
                    }, () => {
                        fetch('/api/admin/windows', {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
                            }
                        }).then(response => {
                            if (response.ok) {
                                response.json().then(resp => {
                                    var optionList = [];
                                    resp.forEach(w => {
                                        optionList.push({
                                            key: w,
                                            text: w
                                        });
                                    });

                                    var defaultWindowOption = 'Last 30 days';
                                    if (window.pmusername === 'wei') {
                                        var lastMonth = defaultWindowOption;
                                        resp.forEach(w => {
                                            if (w.length === 8) {
                                                defaultWindowOption = lastMonth;
                                                lastMonth = w;
                                            }
                                        });
                                    }

                                    this.setState({
                                        clientOptions: this.state.clientOptions,
                                        windowOptions: optionList,
                                        defaultClientOption: this.state.defaultClientOption,
                                        defaultWindowOption: defaultWindowOption
                                    }, () => {
                                        this.selectedClient = this.state.defaultClientOption;
                                        this.selectedWindow = this.state.defaultWindowOption;
                                        this.onChange();
                                    });
                                })
                            }
                        });
                    });
                })
            }
        });
    }

    onChange() {
        this.props.onChange(this.selectedClient, this.selectedWindow);
    }

    onChangeClient(e, o) {
        this.selectedClient = o.key;
        this.onChange();
    }

    onChangeWindow(e, o) {
        this.selectedWindow = o.key;
        this.onChange();
    }

    render() {
        if (window.inTeams) {
        }

        return (
            <div id='filter-controls'>
                <span>Client:</span>
                <Dropdown ref={this.clientFilterRef} className='filter-dropdown' options={this.state.clientOptions} defaultSelectedKey={this.state.defaultClientOption} onChange={(e,o) => this.onChangeClient(e,o)} />
                <span>Time period:</span>
                <Dropdown ref={this.windowFilterRef} className='filter-dropdown' options={this.state.windowOptions} defaultSelectedKey={this.state.defaultWindowOption} onChange={(e, o) => this.onChangeWindow(e, o)} />
            </div>
        );
    }
}

export default FilterControls;
