import React, { Component } from 'react';

class ReportSectionBase extends Component {
    constructor(props, defaultState, sectionTitle, dataUrl, noSectionMessage, processingMessage) {
        super(props);
        this.sectionTitle = sectionTitle;
        this.dataUrl = dataUrl;
        this.noSectionMessage = noSectionMessage ?? 'There is no content for this section of the report.';
        this.processingMessage = processingMessage ?? 'This section of the report is being generated. Please wait...';
        this.state = {
            ...defaultState,
            keepLoading: true,
            status: 'loading',
            lastUpdateDate: '0000-00-00T00:00:00.000'
        };

        // this.loadData();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    dataReady() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                status: 'ready'
            });
        });
    }

    pleaseWait() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                status: 'processing'
            });
        });
    }

    emptySection() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                status: 'none'
            });
        });
    }

    stopPolling() {
        this.setState((prevState) => {
            return ({
                ...prevState,
                keepLoading: false
            });
        });
    }

    loadSection(currentStatus, lastUpdateDate) {
        switch(currentStatus) {
            case 'P':
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        status: 'processing'
                    });
                });
                break;
            case 'E':
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        status: 'error'
                    });
                });
                break;
            case 'R':
                if (lastUpdateDate > this.state.lastUpdateDate) {
                    this.loadData(lastUpdateDate);
                }
                break;
        }
    }

    loadData(lastUpdateDate) {
        /*
        if (!this.state.keepLoading)
            return;
        */
        fetch(this.dataUrl,
            {
            }).then(response => {
                if (response.ok) {
                    if (!response.headers.get("content-type")) {
                        this.setState((prevState) => {
                            return ({
                                ...prevState,
                                status: 'processing'
                            });
                        });
                    } else {
                        response.json().then(resp => {
                            this.dataLoaded(resp);
                            this.setState((prevState) => {
                                return ({
                                    ...prevState,
                                    lastUpdateDate: lastUpdateDate
                                });
                            });
                        });
                    }

                    // setTimeout(() => this.loadData(), 5000);
                } else {
                this.setState((prevState) => {
                    return ({
                        ...prevState,
                        status: 'error'
                    });
                });
            }
        });
    }


    render() {
        this.initEventHandlers();

        var body = undefined;
        if (this.state.status === 'error') {
            body = <div>An error occurred generating this section of the report.</div>;
        } else if (this.state.status === 'loading') {
            body = <div>Loading...</div>;
        } else if (this.state.status === 'processing') {
            body = <div>{this.processingMessage}</div>;
        } else if (this.state.status === 'ready') {
            body = this.renderData();
        } else if (this.state.status === 'none') {
            body = <div>{this.noSectionMessage}</div>;
        }

        var cornerDiv = <div />;
        if (this.state.status === 'processing') {
            cornerDiv = (
                <div className="lds-ring" style={{ verticalAlign: 'middle' }}>
                    <div></div><div></div><div></div><div></div>
                </div>
            );
        }

        var headerColour = '#2e3688';
        if (this.state.status === 'error') {
            headerColour = '#882323';
        } else if (this.state.status === 'none') {
            headerColour = '#888888';
        }

        return (
            <div style={{border: 'black 1px solid'}}>
                <div style={{ background: headerColour, padding: '0.5em',  borderBottom: 'black 1px solid', display: 'grid', gridTemplateColumns: 'auto 1fr auto' }} >
                    <div style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>{this.sectionTitle}</div>
                    <div />
                    {cornerDiv}
                </div>
                <div style={{ background: 'white', padding: '0.5em' }}>
                    {body}
                </div>
            </div>
        );
    }
}

export default ReportSectionBase;