import React, { Component } from 'react';
import {MessageBar, MessageBarType, MessageBarButton, Link, DefaultButton } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import FTOSearchPatentResult from './FTOSearchPatentResult';
import AutoDisableButton from './AutoDisableButton';

class ClaimsLookupOutputPanel extends Component {
    static displayName = "";

    constructor(props) {
        super(props);
        this.state = {
            claimResults: []
        };
        this.excelButtonRef = React.createRef();
    }

    componentDidMount = () => {
    }

    initEventHandlers() {
    }

    clearClaims() {
        this.setState({
            claimResults: []
        });
    }

    setClaims(claims) {
        this.setState({
            claimResults: claims
        });
    }

    enableExcelButton() {
        this.excelButtonRef.current.enableButton();
    }


    render() {
        this.initEventHandlers();

        var anyGood = false;
        var results = [];
        this.state.claimResults.forEach(r => {
            var resultDetail = <div />;

            if (r.claims) {
                var claims = [];
                r.claims.forEach(c => {
                    anyGood = true;
                    var rhs = <div />;

                    claims.push(
                        <div style={{ marginLeft: '2em', marginBottom: '1em' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{}}>
                                            {c}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '40%', verticalAlign: 'top', paddingRight: '1em' }}>{c
                                            .claimText}</td>
                                        <td style={{ width: '60%', verticalAlign: 'top', paddingLeft: '1em' }}>{rhs
                                        }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                });
                resultDetail = <div>{claims}</div>;
            } else {
                resultDetail = <div style={{ color: 'red' }}>Not found</div>;
            }

            results.push(
                <div style={{ marginBottom: '1em' }}>
                    <div style={{ fontWeight: 'bold' }}>{r.publicationNumber}</div>
                    {resultDetail}
                </div>
            );
        });

        if (anyGood) {
            results.push(
                <div>
                    <AutoDisableButton ref={this.excelButtonRef} action={() => this.props.generateClaimsSheet()} label='Download Excel Spreadsheet' />
                </div>
            );
        }

        return (
            <div style={{ padding: '0.5em', overflowY: 'auto' }}>
                {results}
            </div>
        );
    }
}

export default ClaimsLookupOutputPanel;
