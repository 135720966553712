import React from 'react';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';

import './UsageView.css';
import FilterControls from './FilterControls';
import { withRouter } from '../../WithRouter';
import { AgGridReact } from 'ag-grid-react';
import CaseCountRenderer from './CellRenderers/CaseCountRenderer';
import DayCountRenderer from './CellRenderers/DayCountRenderer';
import UsedCitrixRenderer from './CellRenderers/UsedCitrixRenderer';
import LastUsedRenderer from './CellRenderers/LastUsedRenderer';
import LocalVersionCellRenderer from './CellRenderers/LocalVersionCellRenderer';
import AddinVersionCellRenderer from './CellRenderers/AddinVersionCellRenderer';
import GPTSpendingCellRenderer from './CellRenderers/GPTSpendingCellRenderer';
import TeamsComponent from '../../TeamsComponent';
import { Link } from '@fluentui/react';
import GPTUsageDialog from './GPTUsageDialog';

class UsageView extends TeamsComponent {
    listLengthComparator = (listA, listB) => {
        if (listA.length === listB.length) return 0;
        if (listA.length < listB.length) return -1;
        return 1;
    };

    constructor(props) {
        super(props);
        this.state = { };
        this.debugUsername = 'iod@patentmaker.eu';

        this.filterRef = React.createRef();
        this.gridRef = React.createRef();

        this.columnDefs = [
            { headerName: 'User Name', field: 'userName', sortable: true, width: 300 },
            { field: 'localVersion', sortable: true, width: 150, cellRenderer: LocalVersionCellRenderer },
            { field: 'addinVersion', sortable: true, width: 150, cellRenderer: AddinVersionCellRenderer },
            { headerName: 'Used Citrix?', field: 'citrixUser', cellRenderer: UsedCitrixRenderer, sortable: true, width: 150 },
            { field: 'lastUsed', sortable: true, width: 150, cellRenderer: LastUsedRenderer },
            { field: 'days', cellRenderer: DayCountRenderer, sortable: true, width: 100, comparator: this.listLengthComparator },
            { field: 'cases', cellRenderer: CaseCountRenderer, sortable: true, width: 100, comparator: this.listLengthComparator },
            { headerName: 'GPT Estimate', field: 'gptSpending', sortable: true, cellRenderer: GPTSpendingCellRenderer, cellRendererParams:
                {
                    getWindowName: () => this.filterRef.current.selectedWindow
                }
            }
        ];

        this.gridOptions = {
            columnDefs: this.columnDefs,
            onGridReady: (event) => {
            },
            onGridSizeChanged: () => {
                this.gridRef.current.api.sizeColumnsToFit();
                this.gridRef.current.api.resetRowHeights();
            },
            onFilterChanged: () => {
            },
            suppressRowVirtualisation: true
        };
    }

    filterSelected(group, timeWindow) {
        var gridApi = this.gridOptions.api;
        if (group === undefined || timeWindow === undefined)
            return;

        gridApi.showLoadingOverlay();
        fetch('/api/admin/usage/' + group + '/' + timeWindow, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            }
        }).then(response => {
            if (response.ok) {
                response.json().then(resp => {
                    gridApi.setRowData(resp);
                    gridApi.sizeColumnsToFit();
                })
            }
        })
    }

    teamsReady(myEmail) {
        window.pmusername = myEmail.split('@')[0];
        this.filterRef.current.loadOptions();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    copyGrid() {
        var copyText = 'Username\tLocal version\tAddin version\tCitrix user?\tLast used\tDays\tCases\tGPT Estimate\r\n';
        this.gridOptions.api.forEachNodeAfterFilterAndSort(node => {
            var rowData = node.data;
            copyText += rowData.userName + '\t';
            copyText += (rowData.localVersion ?? '') + '\t';
            copyText += (rowData.addinVersion ?? '') + '\t';
            copyText += (rowData.citrixUser ? 'Y' : 'N') + '\t';
            copyText += rowData.lastUsed.replace('T', ' ') + '\t';
            copyText += rowData.days.length + '\t';
            copyText += rowData.cases.length + '\t';
            copyText += rowData.gptSpending ? Math.round(rowData.gptSpending * 100) / 100 : 0.0 + '\t';
            copyText += '\r\n';
        });
       this.copy(copyText);
    }

    copy(txt) {
        var cb = document.getElementById("cb");
        cb.value = txt;
        cb.style.display = 'block';
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
    }

    render() {
        return (
            <div className='main-content' style={{display:'flex', flexDirection:'column', margin:'2em'} }>
                <div id='display-name'></div>

                <div style={{display: 'flex'} }>
                    <FilterControls ref={this.filterRef} onChange={(g, w) => this.filterSelected(g, w)} />
                    <Link style={{marginLeft:'1em', marginBottom:'1em'}} onClick={() => this.copyGrid()}>Copy</Link>
                    <textarea id="cb" type="text" style={{ display: 'none' }}></textarea>
                </div>
                <AgGridReact className="ag-theme-alpine"
                        ref={this.gridRef}
                    gridOptions={this.gridOptions} />
                <GPTUsageDialog/>
            </div>
        );
    }
}

export default withRouter(UsageView);
