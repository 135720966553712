import React from 'react';
import TeamsComponent from '../TeamsComponent';
import { PrimaryButton, Link } from '@fluentui/react';
import $ from 'jquery';
import 'jquery-ui/dist/jquery-ui.min';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import './UPCContent.css';
import './ClientSelector.css';
import { withRouter } from '../WithRouter';
import SuggestedClientList from './SuggestedClientList';

class ClientSelector extends TeamsComponent {
    static displayName = ClientSelector.name;

    constructor(props) {
        super(props);
        this.state = { myEmail: undefined };
        this.suggestedClientListRef = React.createRef();
        //this.debugUsername = 'philipp@boehmert.org'
        //this.debugDisplayName = 'Matthias Philipp';
        this.debugUsername = 'donnell@boehmert.org'
        this.debugDisplayName = 'Ian O\'Donnell';
        //this.debugUsername = 'hofmann@boehmert.org';
        //this.debugDisplayName = 'Matthias Hofmann';
        //this.debugUsername = 'engelhard@boehmert.org';
    }


    openClient() {
        var clientName = $("#clientSearchView").val();
        this.openClientByName(clientName);
    }

    addClient() {
        var myEmail = this.state.myEmail;
        var clientName = $("#clientSearchAdd").val();
        $.ajax({
            url: "/api/upc/clientid?clientName=" + encodeURIComponent(clientName) + "&createIfMissing=N",
            type: "GET",
            contentType: "application/json; charset=utf-8",
            success: (clientId) => {
                $.ajax({
                    url: "/api/upc/attorneyClients?clientId=" + clientId + "&attorneyEmail=" + myEmail,
                    type: "POST",
                    contentType: "application/json; charset=utf-8",
                    success: () => {
                        this.getClientList(myEmail);
                        $("#clientSearchAdd").val('');
                    }
                });
            }
        });
    }

    openClientByName(clientName) {
        var nav = this.props.navigate;
        $.ajax({
            url: "/api/upc/clientid?clientName=" + encodeURIComponent(clientName) + "&createIfMissing=N",
            type: "GET",
            contentType: "application/json; charset=utf-8",
            success: function (result) {
                var clientUrl = "client/" + result;
                nav(clientUrl);
            }
        });
    }

    getClientList(attorneyEmail) {
        var clientListComponent = this.suggestedClientListRef.current;

        $.ajax({
            url: '/api/upc/attorneyclients?attorneyEmail=' + attorneyEmail,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            success: (clientList) => {
                clientListComponent.setClients(clientList, attorneyEmail);
            }
        });
    }

    teamsReady(myEmail, myName) {
        $.ajax({
            url: '/api/upc/secretaryinfo?myEmail=' + myEmail,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("bearerToken")
            },
            success: (secretaryInfo) => {
                var attorneyEmail;
                if (secretaryInfo?.attorneyEmail) {
                    $("#display-name").text('Welcome, ' + myName + ', working for ' + secretaryInfo.attorneyName);
                    attorneyEmail = secretaryInfo.attorneyEmail;
                } else {
                    $("#display-name").text('Welcome, ' + myName);
                    attorneyEmail = myEmail;
                }
                this.setState({ myEmail: attorneyEmail });
                this.getClientList(attorneyEmail);
            }
        });
    }

    componentDidMount() {
        super.componentDidMount();

        $("#clientSearchView").autocomplete({
            minLength: 2,
            source: function (request, response) {
                $.ajax({
                    dataType: 'json',
                    url: '/api/upc/clientfilter',
                    data: request,
                    headers: { Authorization: 'Bearer ' + localStorage.getItem("bearerToken") },
                    success: function (data, status, xhr) {
                        response(data);
                    }
                });
            }
        });

        $("#clientSearchAdd").autocomplete({
            minLength: 2,
            source: function (request, response) {
                $.ajax({
                    dataType: 'json',
                    url: '/api/upc/clientfilter',
                    data: request,
                    headers: { Authorization: 'Bearer ' + localStorage.getItem("bearerToken") },
                    success: function (data, status, xhr) {
                        response(data);
                    }
                });
            }
        });
    }

    render() {
        var partnerLink = (<span>&nbsp;-&nbsp;<a href='/upcoptout/partners?teams=y'>View contacts</a></span>);

        return (
            <div className="client-selector" id="main-content">
                <div id='greeting'>
                    <span id="display-name" />
                    {partnerLink}
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>Select client to view:</td>
                            <td>
                                <input id="clientSearchView" style={{ width: '300px', fontSize: '16px', padding: '5px' }} />
                            </td>
                            <td>
                                <PrimaryButton id="viewClientButton" onClick={() => this.openClient()}>View</PrimaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div id='suggested-client-list'>
                    <SuggestedClientList ref={this.suggestedClientListRef} navigate={this.props.navigate} openClientByName={(clientName) => this.openClientByName(clientName)} />
                </div>
                <table style={{marginTop:'2em'}}>
                    <tbody>
                        <tr>
                            <td>Select client to claim responsibility for:</td>
                            <td>
                                <input id="clientSearchAdd" style={{ width: '300px', fontSize: '16px', padding: '5px' }} />
                            </td>
                            <td>
                                <PrimaryButton id="addClientButton" onClick={() => this.addClient()}>Claim</PrimaryButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr/>
                <Link href='/upcoptout/addclient?teams=y'>Add new client to database</Link>

            </div>
        );
    }
}

export default withRouter(ClientSelector);
